import React from "react";

import styles from "./index.module.scss";

const Loading = () => {
  return (
    <div className={styles.Spinner} aria-label="Loading..." role="group"></div>
  );
};

export default Loading;
