import React, { useEffect, useLayoutEffect } from "react";
import { useField, FieldProps, FormValue, FormValues } from "informed";
import { add, getYear } from "date-fns";

/**
 * This is essentially a custom build of the Text component
 * to allow for some additional masking workarounds for dates.
 */
const TextDate = (props: FieldProps<FormValue, FormValues>) => {
  const { fieldState, fieldApi, render, ref, userProps } = useField(props);
  const value =
    !fieldState.value && fieldState.value !== 0 ? "" : `${fieldState.value}`;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, ...rest } = userProps;

  // Using a ref instead of state so current value can be accessed from event listener
  const inputModeRef = React.useRef("numeric");

  // Write inputMode change to DOM if it has changed
  const updateInputEl = () => {
    if (
      ref.current &&
      ref.current.getAttribute("inputmode") !== inputModeRef.current
    )
      ref.current.setAttribute("inputmode", inputModeRef.current);
  };

  const setInputMode = (mode: string) => {
    inputModeRef.current = mode;
    updateInputEl();
  };

  useLayoutEffect(() => {
    updateInputEl();
  });

  const cursorNotAtEnd = () =>
    ref.current?.selectionStart !== null &&
    ref.current?.selectionStart < ref.current?.value?.length;

  // Detect when the caret is placed within the text instead of at the
  // end, so we can temporarily disable masking, to prevent disorienting
  // changes and movement of values between D/M/Y separators.
  useEffect(() => {
    // Using selectionchange seems to be the only way to detect caret
    // position change in iOS Safari. It can only be listened for on the
    // body so we need to work outside of the React layer unfortunately.
    const selectionListener = () => {
      if (!inputModeRef.current) return;
      if (cursorNotAtEnd()) {
        setInputMode("text");
      } else {
        setInputMode("numeric");
      }
    };
    document.addEventListener("selectionchange", selectionListener, false);
    return () => {
      document.removeEventListener("selectionchange", selectionListener);
    };
  });

  // The goal of this is to clean up and format the input in a
  // predictable/non-destructive way. Major problems with the data
  // should be flagged by the validator instead of fixed here.
  const sanitiseDate = (date: string) => {
    const yearIndex = 2;
    const decadeOffset = 2;
    const thresholdYears = 20;
    const centuryThresholdYear = getYear(
      add(new Date(), { years: thresholdYears })
    );
    const centuryThresholdCentury = centuryThresholdYear
      .toString()
      .slice(0, decadeOffset);
    const centuryThreshold = centuryThresholdYear
      .toString()
      .slice(-decadeOffset);

    // If we have 8 digits then force format
    const digitsOnly = date.replace(/[^\d]/g, "");
    const fullLength = 8;
    if (digitsOnly.length === fullLength) {
      const dStop = 2;
      const mStop = 4;
      const dd = digitsOnly.slice(0, dStop);
      const mm = digitsOnly.slice(dStop, mStop);
      const yyyy = digitsOnly.slice(mStop, fullLength);
      return `${dd}/${mm}/${yyyy}`;
    }

    // Otherwise format each part
    return (
      date
        // Reduce to numbers and slashes
        .replace(/[^\d/]/g, "")
        .split("/")
        .filter((chars) => !!chars)
        .map((chars, i) => {
          // Pad DD and MM with zero
          if (i < yearIndex && chars !== "0") {
            return chars.padStart(yearIndex, "0");
          }
          // Prefix 19 or 20 to YY
          if (i === yearIndex && chars.length === yearIndex) {
            if (chars < centuryThreshold) {
              return `${centuryThresholdCentury}${chars}`;
            } else {
              return `${(parseInt(centuryThresholdCentury, 10) - 1)
                .toString()
                .slice(0, decadeOffset)}${chars}`;
            }
          }
          return chars;
        })
        .join("/")
    );
  };

  return render(
    <>
      <input
        {...rest}
        type="text"
        ref={ref}
        value={value}
        onChange={(e) => {
          // If the user is deleting or editing mid-text, skip
          // pre-formatting to allow for natural editing
          const deleting = e.target.value.length < value.length;
          if (deleting || cursorNotAtEnd()) {
            setValue(e.target.value);
          }
          // Allow the user to type an early slash and pad with a zero
          else if (
            e.target.value.length &&
            !cursorNotAtEnd() &&
            e.target.value.slice(-1) === "/"
          ) {
            const slashesInCompleteDate = 3;
            let formatted = sanitiseDate(e.target.value);
            // Add a trailing slash if year not entered yet
            if (formatted.replace(/[^/]/g, "").length < slashesInCompleteDate) {
              formatted = `${formatted}/`;
            }
            setValue(formatted);
          }
          // Add a slash as soon as possible, so that having a numeric keypad
          // (where they can't type a slash) is less of a UX concern.
          // Unneeded slashes will be removed by the formatter.
          else {
            setValue(`${e.target.value}/`);
          }

          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          setValue(sanitiseDate(value));
          setTouched(true);
          if (onBlur) {
            onBlur(e);
          }
        }}
      />
    </>
  );
};

export default TextDate;
