import React from "react";
import { useDropzone } from "react-dropzone";

import styles from "../index.module.scss";
import Camera, { IMAGE_FORMAT } from "~/components/Camera";
import FileInputCard from "../FileInputCard";
import { wrapFileSelect, getExtension } from "../utils";
import type { FileType, FileSelectFunction } from "../utils";

type Props = {
  title: string;
  onFileSelect: FileSelectFunction;
  initialFilename?: string;
  initialUploaded: boolean;
  initialError?: string;
  defaultFacingMode: "environment" | "user";
  accept?: string;
  preMessage?: string;
  disabledUpload: boolean;
  validExtensions?: string[];
};

const PhotoOrDocumentFileInput = ({
  title,
  defaultFacingMode,
  onFileSelect,
  initialFilename,
  initialUploaded,
  initialError,
  accept,
  preMessage,
  disabledUpload,
  validExtensions,
}: Props) => {
  const [filename, setFilename] = React.useState(initialFilename);
  const [uploaded, setUploaded] = React.useState(initialUploaded);
  const [uploadedPercentage, setUploadedPercentage] = React.useState(0);
  const [error, setError] = React.useState(initialError);
  const [openCamera, setOpenCamera] = React.useState(false);
  const inputRef = React.createRef<HTMLInputElement>();
  const invalidFileString = "Not a valid image file.";

  const clickHandler = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const uploadFile = (file: FileType) =>
    wrapFileSelect(
      onFileSelect,
      file,
      setFilename,
      setUploaded,
      setUploadedPercentage,
      setError
    );

  const uploadHandler = (file: File) => {
    // !TODO: We are using base64 to upload the files ATM to make for an easier
    //   implementation at this point, before we have the upload API ready with a
    //   final implementation using azure. It should be changed to use Multipart stream.
    const reader = new FileReader();

    reader.onload = () => {
      const base64Contents = reader.result as string;
      uploadFile({
        base64Contents,
        fileName: file.name,
        mimeType: file.type,
        size: file.size,
      });
    };

    reader.readAsDataURL(file);
  };

  const inputHandler = (files: File[] | React.FormEvent<HTMLInputElement>) => {
    if (Array.isArray(files)) {
      const file = files[0];
      uploadHandler(file);
    } else if (inputRef.current && inputRef.current.value) {
      const files = inputRef.current.files;

      if (files) {
        const file = files[0];

        if (
          validExtensions &&
          !validExtensions.includes(`.${file.name.split(".").pop()}`)
        ) {
          setError(invalidFileString);
          return;
        } else if (error === invalidFileString) {
          setError(undefined);
        }

        uploadHandler(file);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: inputHandler });
  const overlayPresent =
    title === "Driver's License back" || title === "Driver's License front"
      ? true
      : false;

  return (
    <>
      <div {...getRootProps()}>
        <input
          {...getInputProps()}
          type="file"
          name={title}
          accept={accept}
          className={styles.Input}
          ref={inputRef}
          onChange={inputHandler}
          multiple={false}
        />

        <FileInputCard
          title={title}
          description="Upload file or open camera"
          filename={filename}
          error={error}
          onSelectFile={clickHandler}
          onTakePhoto={() => {
            if (error === invalidFileString) {
              setError(undefined);
            }

            setOpenCamera(true);
          }}
          uploaded={uploaded}
          uploadedPercentage={uploadedPercentage}
          uploadDisabled={disabledUpload}
        />
      </div>

      {openCamera && (
        <Camera
          title={title}
          overlay={overlayPresent}
          preMessage={preMessage}
          defaultFacingMode={defaultFacingMode}
          onClose={() => setOpenCamera(false)}
          onSave={({ picture, size }) => {
            setOpenCamera(false);

            if (typeof picture === "string") {
              uploadFile({
                base64Contents: picture,
                fileName: `${Math.random()}${getExtension(IMAGE_FORMAT)}`,
                mimeType: IMAGE_FORMAT,
                size,
              });
            } else {
              setError("Could not save photo. Please try again.");
            }
          }}
        />
      )}
    </>
  );
};

export default PhotoOrDocumentFileInput;
