import React, { useContext, useLayoutEffect, useState } from "react";
import cx from "classnames";
import { FormValue } from "informed";

import { H3 } from "~/components/Typography";
import SearchList from "~/components/SearchList";
import Loading from "~/components/Loading";
import { MotorContext } from "~/contexts/MotorProvider";

import { useHistory } from "react-router-dom";

import { sortTrims, TrimOptionType } from "~/helpers/sortVehicles";
import styles from "./index.module.scss";
import pushWithParams from "~/helpers/pushWithParams";

export type LocationState = {
  prevLocation: string;
};

type Props = {
  isSportsCar: boolean;
  trimsData: TrimOptionType[];
  nextPath?: string;
  loading: boolean;
};

export default function VehicleStyle(props: Props) {
  const { trimsData, nextPath, loading } = props;
  const motorCtx = useContext(MotorContext);
  const history = useHistory();

  const [simplifiedModelData, setSimplifiedModelData] = useState<
    string[] | null
  >(null);

  useLayoutEffect(() => {
    if (trimsData && trimsData.length > 0) {
      const sportsCarsTrims = trimsData
        .reduce((acc: string[], trim) => {
          return [...acc, trim.trimName];
        }, [])
        .sort(sortTrims);
      sportsCarsTrims.push("Other");
      setSimplifiedModelData(sportsCarsTrims);
    }
  }, [trimsData]);

  const handleOnChange = async (value: FormValue) => {
    try {
      await motorCtx.vehicleInfo.setItem("style", value as string);
      await motorCtx.vehicleInfo.setItem("isSportsCar", value !== "Other");
      if (nextPath) {
        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3>What style is your {motorCtx?.vehicleInfo?.data?.model}?</H3>
      </legend>
      <div className={cx(styles.Spinner, { [styles.SpinnerVisible]: loading })}>
        <Loading />
      </div>
      <div
        className={cx(styles.ModelList, {
          [styles.ModelListVisible]: !loading,
        })}
      >
        {simplifiedModelData && (
          <SearchList
            loading={loading}
            list={simplifiedModelData}
            field="style"
            onChange={handleOnChange}
            excludeFromFilter={["Other"]}
            initialValue={motorCtx.vehicleInfo.data.style}
          />
        )}
      </div>
    </fieldset>
  );
}
