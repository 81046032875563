import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import { getSvgWrapperDefaultValues } from "~/components/Icon/index.utils";

const logoWidth = 18;
const logoHeight = 18;
const CheckSquare = ({
  className = "check-icon",
  width = logoWidth,
  height = logoHeight,
  backgroundColor = "#23453a",
  fill = "white",
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
    fill="none"
  >
    <rect
      x="1.5"
      y="1.5"
      width="15"
      height="15"
      rx="3.5"
      fill={backgroundColor}
      stroke={backgroundColor}
      strokeWidth="3"
    />
    <path
      d="M14 6L7.4375 12.7812L4 9.34375"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckSquare;
