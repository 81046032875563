import React, { ReactNode } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

import { Text } from "~/components/Typography";
import Icon from "~/components/Icon";

export type Props = {
  mainText: string | React.ReactNode;
  children?: ReactNode;
  className?: string;
  noMaxWidth?: boolean;
};

const Note = ({ className, mainText, children, noMaxWidth }: Props) => (
  <div
    className={classnames(styles.NoteWrapper, className, {
      [styles.NoMaxWidth]: noMaxWidth,
    })}
  >
    <div className={styles.NoteLeftContent}>
      <Icon name="Exclamation" className={styles.NoteIcon} />
    </div>
    <div className={styles.NoteContent}>
      {typeof mainText === "string" ? <Text>{mainText}</Text> : mainText}

      {children}
    </div>
  </div>
);
export default Note;
