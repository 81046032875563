import React from "react";
import { useHistory } from "react-router-dom";
import Header from "~/components/Header";
import StepTitle from "~/components/StepTitle";

import ProgressBar from "~/components/ProgressBar";

import MotorProvider from "~/contexts/MotorProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useProfileName } from "~/helpers/useProfileName";

export const barbadosPopularMakes: string[] = [
  "Toyota",
  "Suzuki",
  "Nissan",
  "Mitsubishi",
  "Hyundai",
  "Isuzu",
  "Kia",
  "Mazda",
  "Honda",
  "Ford",
];

const GuyanaMotor = () => {
  const history = useHistory();
  const { currentStep, totalOfSteps, title } = useHeaderContext();
  const profileName = useProfileName();

  return (
    <MotorProvider>
      <Header
        title={
          <StepTitle
            title={title}
            totalOfSteps={totalOfSteps}
            currentStep={currentStep}
          />
        }
        onPrev={() => history.goBack()}
        onClose={() => null}
        userInitial={profileName}
      />
      <ProgressBar stepsLength={totalOfSteps || 0} step={currentStep || 0} />
    </MotorProvider>
  );
};
export default GuyanaMotor;
