import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 465;
const logoHeight = 113;

const AlmiCombinedVertical = ({
  className = "almi-combined",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <h1 aria-label="almi">
    <svg
      {...getSvgWrapperDefaultValues({ className, width, height })}
      width="48"
      height="52"
      viewBox="0 0 48 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="almi"
    >
      <path
        d="M12.1237 50.3315V51.0037C11.3472 51.0773 8.14984 51.2397 7.8215 51.1599C7.58341 51.1015 7.43368 50.8717 7.31191 50.6698C7.28126 50.6196 6.95749 49.8702 6.97102 49.8621C5.68304 50.7596 4.39506 51.43 3.03048 51.43C1.13639 51.43 0 50.31 0 48.7376C0 47.0944 1.13639 46.1233 3.03048 45.3766C3.40928 45.2267 5.38002 44.6299 6.74372 44.3311V43.8922C6.74372 41.5022 5.98607 40.6802 5.30062 40.6802C4.92182 40.6802 4.54302 40.9045 3.85752 42.1745C3.40658 42.9956 3.09899 43.5206 2.72019 44.2655C2.64443 44.4899 2.41717 44.5644 2.18986 44.5644C1.9626 44.5644 1.81106 44.4899 1.65952 44.2655L0.303029 41.9528C2.27284 40.5339 4.31934 39.7872 6.21338 39.7872C8.86506 39.7872 10.9873 40.8327 10.9873 43.5215V49.049C10.9873 49.7212 11.2904 50.1071 11.8207 50.257L12.1237 50.3315ZM5.45574 49.8612C5.83458 49.8612 6.28915 49.6369 6.74372 49.2635V45.006L5.98607 45.3048C4.69544 45.8271 4.31934 47.0226 4.31934 48.0681C4.31934 49.1136 4.69544 49.8612 5.45574 49.8612Z"
        fill={fill}
      />
      <path
        d="M13.1733 50.2839C13.7036 50.134 13.9246 49.8083 13.9246 49.2115C14.0003 45.1029 13.9246 40.0986 13.9246 36.5133C13.9246 35.99 13.7731 35.6158 13.167 35.5422L12.2569 35.3178V34.6456C14.0003 34.3477 15.8187 33.9734 17.4854 33.6001H18.1673C18.1673 38.9777 18.1673 44.0573 18.243 49.216C18.243 49.8128 18.4703 50.1134 18.9258 50.2615L19.2289 50.336V51.0082H13.0154V50.3315L13.1733 50.2839Z"
        fill={fill}
      />
      <path
        d="M40.9783 50.3312V51.0034H34.6892V50.3312L34.9923 50.2567C35.4435 50.1069 35.75 49.808 35.75 49.2112V43.3849C35.75 42.1149 35.2194 41.4427 34.2346 41.4427C33.7035 41.4427 33.1729 41.6662 32.7187 41.9651C32.7943 42.2639 32.7943 42.4883 32.7943 42.8625V49.2121C32.7943 49.8089 33.0213 50.1095 33.4759 50.2576L33.779 50.3321V51.0043H27.4899V50.3312L27.793 50.2567C28.2442 50.1069 28.4757 49.808 28.4757 49.2112C28.5517 47.1937 28.4757 45.2516 28.4757 43.3849C28.4757 42.1149 27.9455 41.4427 26.9598 41.4427C26.5086 41.4427 25.975 41.6662 25.5165 41.8914C25.5165 44.3568 25.441 46.8212 25.5165 49.2121C25.5165 49.8089 25.744 50.1095 26.1987 50.2576L26.5017 50.3321V51.0043H20.2161V50.3312L20.4431 50.2567C20.9738 50.0324 21.2008 49.808 21.2008 49.2112C21.2768 46.9675 21.2768 44.5049 21.2008 42.1894C21.2008 41.5917 20.8978 41.292 20.3676 41.1439L19.9885 40.994V40.3218C21.4318 40.0983 22.7927 39.8731 24.3836 39.5751H25.0658L25.3689 41.0694L25.899 40.7705C26.8082 40.2473 27.9455 39.5751 29.4609 39.5751C30.9037 39.5751 31.9611 40.1728 32.4912 41.2183L33.0973 40.845C33.9315 40.2473 35.1438 39.5751 36.5832 39.5751C38.6287 39.5751 39.9936 40.9213 39.9936 42.8616V49.2112C39.9936 49.808 40.2206 50.1086 40.7513 50.2567L40.9783 50.3312Z"
        fill={fill}
      />
      <path
        d="M42.7716 49.2855V42.2638C42.7716 41.5916 42.191 41.1437 42.0394 41.0684V40.3962C43.5548 40.1727 44.6656 39.9474 46.3325 39.575H47.0152C47.0152 43.0105 46.9397 46.2225 47.0152 49.211C47.0152 49.8078 47.2427 50.1085 47.7729 50.2566L47.9999 50.3311V51.0033H41.7869V50.3311C41.7869 50.3311 42.7716 50.174 42.7716 49.2855ZM42.4685 36.1422C42.4685 35.2447 43.2262 33.8985 44.8932 33.8985C46.2575 33.8985 47.3183 34.7206 47.3183 36.065C47.3183 37.3349 46.485 38.3804 44.8932 38.3804C43.2262 38.3796 42.4685 37.1841 42.4685 36.1422Z"
        fill={fill}
      />
      <path
        d="M23.9312 5.14019C23.9312 7.91929 21.68 10.1553 18.9179 10.1553C16.1554 10.1553 13.9045 7.91903 13.9045 5.15656C13.9045 2.39355 16.1559 0.141388 18.9179 0.141388C21.6805 0.141388 23.9312 2.36159 23.9312 5.14019Z"
        fill={fill}
      />
      <path
        d="M25.8057 13.5361V0.125695C31.6384 0.190594 36.3558 4.7917 36.3558 10.464V23.8744C30.5075 23.8097 25.8057 19.2087 25.8057 13.5361Z"
        fill={fill}
      />
      <path
        d="M23.6686 12.6288C23.7347 12.6353 23.7888 12.6413 23.83 12.6461C23.8349 12.6873 23.841 12.7415 23.8478 12.8076C23.8647 12.9739 23.8855 13.2155 23.9007 13.5155C23.9312 14.1157 23.9393 14.9484 23.85 15.8781C23.671 17.7419 23.1027 19.9739 21.5623 21.5148C20.0219 23.0557 17.7908 23.6242 15.9277 23.8033C14.9984 23.8926 14.166 23.8845 13.566 23.854C13.2662 23.8388 13.0246 23.818 12.8584 23.801C12.7922 23.7943 12.738 23.7882 12.6968 23.7832C12.6918 23.742 12.6857 23.6877 12.679 23.6215C12.662 23.4552 12.6412 23.2136 12.626 22.9136C12.5955 22.3134 12.5874 21.4807 12.6767 20.5511C12.8557 18.6873 13.424 16.4554 14.9643 14.9145C16.5047 13.3816 18.7359 12.8131 20.5991 12.632C21.5285 12.5416 22.3609 12.5482 22.9609 12.5774C23.2608 12.592 23.5024 12.6122 23.6686 12.6288Z"
        fill={fill}
      />
    </svg>
  </h1>
);

export default AlmiCombinedVertical;
