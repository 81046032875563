export type FileType = {
  base64Contents: string;
  fileName: string;
  mimeType: string;
  size: number;
};

export type FileSelectFunction = (file: FileType) => Promise<void>;

const MAX_FILE_SIZE = 13000000; // 13MB is the maximum size allowed

export const getExtension = (imageFormat: string) => {
  switch (imageFormat) {
    case "image/jpeg":
      return ".jpg";
    default:
      return "";
  }
};

export const wrapFileSelect = async (
  onFileSelect: FileSelectFunction,
  file: FileType,
  setFilename: (filename: string) => void,
  setUploaded: (uploaded: boolean) => void,
  setUploadedPercentage: (uploadedPercentage: number) => void,
  setError: (error: string) => void
) => {
  const ANIMATION_COMPLETE = 100;
  const ANIMATION_MAX = 90;
  const ANIMATION_STEP = 10;
  const ANIMATION_INTERVAL_MS = 500;

  let uploadedPercentage = 0;

  const interval = setInterval(() => {
    if (uploadedPercentage < ANIMATION_MAX)
      setUploadedPercentage(
        Math.min(ANIMATION_MAX, (uploadedPercentage += ANIMATION_STEP))
      );
  }, ANIMATION_INTERVAL_MS);

  try {
    if (file.size > MAX_FILE_SIZE)
      throw new Error("The maximum upload size is 13 MB.");

    setUploaded(false);
    setUploadedPercentage(0);
    await onFileSelect(file);
    setUploaded(true);
    setFilename(file.fileName);
    setUploadedPercentage(ANIMATION_COMPLETE);
  } catch (err) {
    setError(err as string);
    setUploaded(false);
  } finally {
    clearInterval(interval);
    setUploadedPercentage(0);
  }
};
