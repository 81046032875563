import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;
const Eye = ({
  className = "eye-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle cx="15" cy="15" r="15" fill="#FDEDBF" />
    <g clipPath="url(#eye-clip0)">
      <path
        d="M7.66666 15C7.66666 15 10.3333 9.66667 15 9.66667C19.6667 9.66667 22.3333 15 22.3333 15C22.3333 15 19.6667 20.3333 15 20.3333C10.3333 20.3333 7.66666 15 7.66666 15Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 17C16.1046 17 17 16.1046 17 15C17 13.8954 16.1046 13 15 13C13.8954 13 13 13.8954 13 15C13 16.1046 13.8954 17 15 17Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="eye-clip0">
        <rect width="16" height="16" fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);

export default Eye;
