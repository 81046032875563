import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import Note from "~/components/Note";
import { H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { InsuranceType } from "../../../../../types";
import styles from "./style.module.scss";

type Props = {
  insuranceType: InsuranceType;
  nextPath?: string;
};

export default function ChecklistSubmitted({ insuranceType, nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: -1,
      totalOfSteps: 0,
      title: "Onboarding Complete",
    });
  });

  const onContinueClick = () => {
    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <>
      <H3 className={styles.ContentHeader}>
        Thanks! Your information for Policy {checklistCtx.policyId} has been
        submitted.
      </H3>

      <Note
        mainText={
          <>
            <Text>
              We will review your information and issue your annual Insurance
              Certificate shortly. Please note you are covered for the first 30
              days by the Cover Note.
            </Text>

            {insuranceType === "home" ? (
              <Text>
                If you need documentation for your mortgage lender before then,
                send us an email.
              </Text>
            ) : undefined}
          </>
        }
      />

      <SubmitButton className={styles.ContinueButton} onClick={onContinueClick}>
        Back to portal
      </SubmitButton>
    </>
  );
}
