import React from "react";
import { TextSmall, Bold } from "~/components/Typography";
import Button from "~/components/Button";
import { useAuth0Context } from "~/contexts/Auth0Context";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";

type PropsType = {
  wrapperStyle?: string;
  textStyle?: string;
  auth0Redirect?: string; //Page after you login to auth0. Defaults to last page.
  onLoginClick?: () => void;
};

const AlreadyHaveAccount = ({
  wrapperStyle,
  textStyle,
  auth0Redirect,
  onLoginClick,
}: PropsType) => {
  const auth0 = useAuth0Context();
  const history = useHistory();

  const redirect = auth0Redirect || history.location.pathname;
  return (
    <div className={wrapperStyle || styles.DefaultWrapper}>
      <TextSmall className={textStyle || styles.DefaultText}>
        Already have an account?
      </TextSmall>
      <br />
      <Button
        type="button"
        text
        variant="textSmall"
        onClick={onLoginClick ? onLoginClick : auth0.login(redirect)}
      >
        <Bold> Log In </Bold>
      </Button>
    </div>
  );
};

export default AlreadyHaveAccount;
