import React, { ReactNode } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

type Props = {
  children: ReactNode;
  containerClassName?: string;
  contentClassName?: string;
  renderBeforeGridContent?: ReactNode;
};

export default function GridWrapper(props: Props) {
  const {
    children,
    containerClassName,
    contentClassName,
    renderBeforeGridContent,
    ...rest
  } = props;
  return (
    <div>
      <div
        className={classnames(styles.GridWrapper, containerClassName)}
        {...rest}
      >
        {renderBeforeGridContent}
        <div className={classnames(styles.GridContent, contentClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
}
