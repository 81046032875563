export type TrimOptionType = { isSportsCar: boolean; trimName: string };
export type MakeModelType = {
  make: string;
  logo: string;
  models: Array<{
    modelName: string;
    isSportsCar: boolean;
    trimOptions: TrimOptionType[];
  }>;
};
export type MakeModelsReduced = {
  popular: MakeModelType[];
  rest: MakeModelType[];
};
export type ModelType = {
  modelName: string;
  countryOfOrigin: string;
  classification: string;
  isSportsCar: boolean;
  trimOptions: TrimOptionType[];
};

export const sortMakes = (a: MakeModelType, b: MakeModelType) =>
  a.make.toUpperCase().localeCompare(b.make.toUpperCase());

export const sortModels = (a: ModelType, b: ModelType) =>
  a.modelName.toUpperCase().localeCompare(b.modelName.toUpperCase());

export const sortTrims = (a: string, b: string) =>
  a.toUpperCase().localeCompare(b.toUpperCase());
