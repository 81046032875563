import React from "react";
import classnames from "classnames";
import MotorImg from "~/assets/images/illustration-motor.png";
import TruckImg from "~/assets/images/illustration-truck.png";
import HomeImg from "~/assets/images/illustration-home.png";
import NissanImg from "~/assets/images/nissan-logo.svg";
import MazdaImg from "~/assets/images/mazda-logo.svg";
import SubaruImg from "~/assets/images/subaru-logo.svg";
import SuzukiImg from "~/assets/images/suzuki-logo.svg";
import ToyotaImg from "~/assets/images/toyota-logo.svg";
import styles from "./index.module.scss";

type PropsType = {
  type: "motor" | "car" | "truck" | "home";
  vehicleMake?: string;
  variant?: "quoteCard" | "small";
};

const getMakeLogo = (make: string | undefined) => {
  switch (make?.toLowerCase()) {
    case "mazda":
      return MazdaImg;
    case "nissan":
      return NissanImg;
    case "subaru":
      return SubaruImg;
    case "suzuki":
      return SuzukiImg;
    case "toyota":
      return ToyotaImg;
    default:
      return null;
  }
};

const getTypeOfInsuranceImg = (typeOfInsurance: string) => {
  switch (typeOfInsurance) {
    case "motor":
    case "car":
      return MotorImg;
    case "truck":
      return TruckImg;
    default:
      return HomeImg;
  }
};

const TypeImage = ({ type, vehicleMake, variant }: PropsType) => {
  const makeLogo = getMakeLogo(vehicleMake);
  return (
    <div
      className={classnames(styles.Container, {
        [styles.Small]: variant === "small",
        [styles.QuoteCard]: variant === "quoteCard",
      })}
    >
      <img
        className={styles.TypeOfInsuranceImg}
        src={getTypeOfInsuranceImg(type)}
        alt={`Illustration of a ${type}`}
      />
      {makeLogo && (
        <div className={styles.MakeLogo}>
          <img
            className={styles.MakeLogoImg}
            src={makeLogo}
            alt={`${vehicleMake} Logo`}
          />
        </div>
      )}
    </div>
  );
};

export default TypeImage;
