import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import TextInput from "~/components/TextInput";
import SubmitButton from "~/components/SubmitButton";
import { H3, Bold, TextSmall } from "~/components/Typography";
import {
  cleanInputDate,
  validateCoverageStartDate,
} from "~/helpers/validators";

import styles from "./index.module.scss";
import {
  formatIsoDate,
  formatUkDate,
  getCoverageStartMaxDate,
  localDateFromIso,
  todaysDateInAst,
} from "~/helpers/dates";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import pushWithParams from "~/helpers/pushWithParams";
// import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { parse } from "date-fns";

export default function CoverageStart({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const { value, error } = useCustomFieldState<string>("coverageStart");
  // const heapCtx = useHeapContext();

  //We don't need to re-render this.
  const [maxDate] = React.useState<moment.Moment>(
    moment(localDateFromIso(getCoverageStartMaxDate()))
  );

  const coverageStart = homeCtx.coverageStart;
  const savedValue = coverageStart ? formatIsoDate(coverageStart) : undefined;

  useUpdateAssistant({
    text: (
      <TextSmall>
        Your coverage can start today, or any day until{" "}
        <Bold>{maxDate.format("MMM D, YYYY")}</Bold>.
      </TextSmall>
    ),
  });

  const handleOnClick = () => {
    try {
      if (value && !error && typeof value === "string") {
        const formattedDate = formatUkDate(cleanInputDate(value));
        homeCtx.setState({ coverageStart: formattedDate });

        // TODO: Once Heap for home is implement, this can be added
        // heapCtx.track(HeapEventName.HOME_COVERAGE_DATE, {
        //   "Coverage Date": formattedDate,
        // });
      }

      if (nextPath && !error) pushWithParams(history, nextPath);
    } catch (error) {
      console.warn(error);
    }
  };

  const label = "When do you want your coverage to start?";

  return (
    <>
      <H3 className={styles.Question} component="h1">
        {label}
      </H3>
      <div className={styles.TextInputWrapper}>
        <TextInput
          label={label}
          hideLabelFromView
          field="coverageStart"
          placeholder="DD / MM / YYYY"
          type="date"
          maintainCursor
          validate={validateCoverageStartDate}
          validateOnMount
          initialValue={value && typeof value === "string" ? value : savedValue}
          validateOnBlur
          validateOnChange
          keepState
          autoFocus
          date={parse(value, "dd/MM/yyyy", new Date())}
          minimumDate={localDateFromIso(todaysDateInAst())}
          maximumDate={maxDate.toDate()}
        />
      </div>
      <div className={styles.ButtonWrapper}>
        <SubmitButton
          onClick={handleOnClick}
          disabled={!value || !!error}
          className={styles.ButtonNext}
        >
          Next
        </SubmitButton>
      </div>
    </>
  );
}
