import React, { useEffect, useState } from "react";

import classNames from "classnames";
import styles from "./index.module.scss";

import { H3 } from "~/components/Typography";

import TileCardVertical from "~/components/TileCardVertical";

import HomeImage from "~/assets/images/home-illustration.png";
import MotorImage from "~/assets/images/car-illustration.png";

import GridWrapper from "~/components/GridWrapper";
import { Form, Radio, RadioGroup } from "informed";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { ENABLE_HOME_FLOW } from "~/helpers/constants";

export default function SelectClaimType() {
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();

  const [iframetype, setIframetype] = useState("");

  useEffect(() => {
    motorCtx.vehicleInfo.removeStore();
    motorCtx.savingsInfo.removeStore();
    motorCtx.policyInfo.removeStore();
    homeCtx.removeStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const motorIframe = (
    <iframe
      className={styles.Iframe}
      title="FAC-Payment"
      src={`https://form.typeform.com/to/PCYl2ZHj?typeform-medium=embed-snippet`}
    ></iframe>
  );

  const homeIframe = (
    <iframe
      className={styles.Iframe}
      title="FAC-Payment"
      src={`https://form.typeform.com/to/EbmLawgz?typeform-medium=embed-snippet`}
    ></iframe>
  );

  const handleIframe = <>{iframetype === "motor" ? motorIframe : homeIframe}</>;

  const SelectClaim = (
    <fieldset>
      <legend>
        <H3 component="h1">What type of claim would you like to make?</H3>
      </legend>
      <div className={styles.Cards}>
        <RadioGroup field="claimType" keepState>
          <TileCardVertical
            title="Motor"
            className={classNames(styles.TitleCardWrapper)}
            image={
              <img
                src={MotorImage}
                alt="Illustration of a car"
                className={classNames(styles.MotorImage)}
                aria-hidden
              />
            }
            inputId="Motor"
          >
            <Radio
              id="Motor"
              field="claimType"
              value="motor"
              onClick={() => setIframetype("motor")}
            />
          </TileCardVertical>
          <TileCardVertical
            title="Home"
            className={
              !ENABLE_HOME_FLOW
                ? styles.TitleCardWrapperHome
                : classNames(styles.TitleCardWrapper)
            }
            description={!ENABLE_HOME_FLOW ? "Coming soon" : undefined}
            image={
              <img
                src={HomeImage}
                alt="Illustration of a house"
                className={styles.HomeImage}
                aria-hidden
              />
            }
            inputId="Home"
            disabled={!ENABLE_HOME_FLOW}
          >
            {ENABLE_HOME_FLOW && (
              <Radio
                id="Home"
                field="claimType"
                value="home"
                onClick={() => setIframetype("home")}
              />
            )}
          </TileCardVertical>
        </RadioGroup>
      </div>
    </fieldset>
  );
  return (
    <Form>
      <GridWrapper>
        <div className={styles.Wrapper}>
          {iframetype ? handleIframe : SelectClaim}
        </div>
      </GridWrapper>
    </Form>
  );
}
