import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";

import { useCameraContext } from "~/contexts/CameraProvider";
import Button from "~/components/Button";
import ButtonIcon from "~/components/ButtonIcon";
import { dataBase64ToBlob } from "~/helpers/fileConvertion";
import Icon from "../Icon";

import styles from "./index.module.scss";
import { isCameraPermitted } from "./camera.utils";
import EnableCameraDialog from "~/components/EnableCameraDialog";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import { Text, H3 } from "~/components/Typography";

export const IMAGE_FORMAT = "image/jpeg";

const CAMERA_ERROR =
  "Camera use was denied. Please make sure you have provided camera permissions to your browser.";

const CAMERA_IOS_GET_USER_MEDIA_ERROR =
  "The camera is not supported in this browser, please log into almi.bb through Safari to continue.";

type Props = {
  onClose?: () => void;
  onSave?: (params: { picture?: string | null; size: number }) => void;
  defaultFacingMode?: "environment" | "user";
  preMessage?: string;
  overlay?: boolean;
  title?: string;
};
export default function Camera(props: Props) {
  const cameraCtx = useCameraContext();

  const {
    onClose,
    onSave,
    defaultFacingMode = "environment",
    preMessage,
    overlay,
    title,
  } = props;
  const webcamRef = React.useRef<Webcam>(null);

  const [picture, setPicture] = useState<string | null>(null);
  const [facingMode, setFacingMode] = useState<"environment" | "user">(
    defaultFacingMode
  );

  const [showCamera, setShowCamera] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleShowCamera = () => {
    if (preMessage) setShowMessage(true);
    else setShowCamera(true);
  };

  useEffect(() => {
    (async () => {
      const permission = await (async () => {
        try {
          return await isCameraPermitted();
        } catch {
          return cameraCtx.permission;
        }
      })();
      if (permission === "granted") {
        setShowPrompt(false);
        handleShowCamera();
        setError(undefined);
      } else if (permission === "prompt") {
        setShowPrompt(true);
        setShowCamera(false);
        setError(undefined);
      } else if (permission === "denied") {
        setShowPrompt(true);
        setError(CAMERA_ERROR);
        setShowCamera(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmShowCamera = () => {
    cameraCtx.setState({ permission: "granted" });
    setShowPrompt(false);
    handleShowCamera();
  };

  const onConfirmShowMessage = () => {
    setShowMessage(false);
    setShowCamera(true);
  };

  useEffect(() => {
    if (webcamRef?.current?.video) {
      const videoRef = webcamRef.current.video;
      videoRef.setAttribute("autoplay", "");
      videoRef.setAttribute("muted", "");
      videoRef.setAttribute("playsinline", "");
    }
  }, [webcamRef]);

  const handleOnTakePicture = React.useCallback(() => {
    if (webcamRef?.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setPicture(imageSrc);
    }
  }, [webcamRef]);

  const handleOnStopCamera = () => {};
  const handleOnClose = (e?: MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (typeof onClose === "function") {
      onClose();
    }
    setPicture(null);
    handleOnStopCamera();
  };

  const renderPicture = () => (
    <div className={styles.ImageResultWrapper} data-has-picture={!!picture}>
      {picture && (
        <img src={picture} className={styles.ImageResult} alt="result" />
      )}
    </div>
  );

  const handleOnSave = () => {
    if (typeof onSave === "function" && picture) {
      const data = dataBase64ToBlob(picture);
      onSave({ picture, size: data.size });
    }
    handleOnStopCamera();
  };

  const handleOnRetake = () => {
    setPicture(null);
  };
  const handleOnSwitchCamera = () => {
    if (facingMode === "environment") setFacingMode("user");
    else setFacingMode("environment");
  };

  const errorHandler = (err: string | DOMException) => {
    if (typeof err === "string") {
      if (err === "getUserMedia not supported") {
        setError("This device does not have a camera");
      } else {
        setError(err);
      }
    } else {
      // err is DOMException
      setShowPrompt(true);
      setShowCamera(false);
      if (err.name === "NotAllowedError") {
        // We don't know for sure if the camera is disabled because we aren't
        // querying the browser. Therefore, we cannot set permission to "denied"
        cameraCtx.setState({ permission: "prompt" });
        setError(CAMERA_ERROR);
      } else if (
        err.name === "Error" &&
        err.message === "getUserMedia is not implemented in this browser" &&
        isMobile &&
        isIOS &&
        !isSafari
      ) {
        setError(CAMERA_IOS_GET_USER_MEDIA_ERROR);
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <>
      <div className={styles.CameraWrapper}>
        {!picture && (
          <Button
            className={styles.FlipCamera}
            onClick={handleOnSwitchCamera}
            size="xsmall"
          >
            <Icon name="Flip" className={styles.iconFlip} />
            Flip camera
          </Button>
        )}
        <ButtonIcon
          className={styles.CloseButton}
          iconName="Close"
          onClick={handleOnClose}
        />
        <div className={styles.WebCamWrapper}>
          {showPrompt && (
            <div className={styles.EnableCameraDialogWrapper}>
              <EnableCameraDialog
                message="Please provide camera permissions to your browser."
                error={error}
                onConfirm={onConfirmShowCamera}
                onCancel={() => {
                  setShowPrompt(false);
                  handleOnClose();
                }}
                onConfirmButton="Enable camera"
                onCancelButton={error ? "Close" : "Back"}
              />
            </div>
          )}
          {showMessage && (
            <div className={styles.EnableCameraDialogWrapper}>
              <EnableCameraDialog
                message={preMessage}
                error={error}
                onConfirm={onConfirmShowMessage}
                onCancel={() => {}}
                onConfirmButton="Got it"
              />
            </div>
          )}
          {showCamera && (
            <Webcam
              ref={webcamRef}
              audio={false}
              width={window.document.body.clientWidth}
              screenshotFormat={IMAGE_FORMAT}
              minScreenshotWidth={1920}
              mirrored={facingMode === "user"}
              videoConstraints={{
                facingMode,
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                frameRate: { ideal: 30, min: 1 },
              }}
              screenshotQuality={0.8}
              onUserMediaError={errorHandler}
            />
          )}
          {overlay && (
            <>
              <div className={styles.SvgWrapper}>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles.Overlay}
                >
                  <mask id="myMask">
                    <rect width="100%" height="100%" fill="white" />
                    <rect
                      x="0"
                      y="0"
                      rx="24"
                      ry="24"
                      width="100%"
                      height="100%"
                      fill="black"
                    />
                  </mask>
                  <rect
                    width="100%"
                    height="100%"
                    mask="url(#myMask)"
                    className={styles.Translucent}
                  />
                </svg>
              </div>
              <div className={styles.IdInstructions}>
                <H3 className={styles.IdText}>{title}</H3>
                <Text className={styles.IdText}>
                  Fit your ID within the frame.
                </Text>
              </div>
            </>
          )}
        </div>
        <div className={styles.ContentAction}>
          {!!picture && (
            <div className={styles.Actions}>
              <Button onClick={handleOnSave}>Save</Button>
              <Button onClick={handleOnRetake} variant="tertiary">
                Retake
              </Button>
            </div>
          )}
          {!picture && (
            <button
              className={styles.RoundedButton}
              are-label="TakePicture"
              type="button"
              onClick={handleOnTakePicture}
            />
          )}
        </div>
        {renderPicture()}
      </div>
    </>
  );
}
