import React from "react";
import { usePortalHeaderContext } from "~/contexts/PortalHeaderProvider";
import { AlmiCombined } from "../Icon/icons";
import AlmiCombinedVertical from "../Icon/icons/AlmiCombinedVertical";
import { H4, TextSmall } from "../Typography";
import SideBarNavigation from "../SideBarNavigation";
import Footer from "~/components/PortalFooter";
import getTimeOfDay from "~/helpers/getTimeOfDay";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";
import { PAYMENT_UPDATE_RESULT_URL } from "~/routes/index.constant";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const [hideLayout, setHideLayout] = React.useState<boolean>(false);
  const history = useHistory();
  const portalHeaderContext = usePortalHeaderContext();

  React.useEffect(() => {
    if (history.location.pathname === PAYMENT_UPDATE_RESULT_URL) {
      setHideLayout(true);
    } else {
      setHideLayout(false);
    }
  }, [history.location.pathname]);

  return !hideLayout ? (
    <main role="main" className={styles.Layout}>
      <div className={styles.Grid}>
        <div className={styles.LeftBar}>
          <AlmiCombined className={styles.ALmiCombinedIcon} />
          <AlmiCombinedVertical className={styles.ALmiCombinedVerticalIcon} />
        </div>
        <SideBarNavigation
          className={styles.SideBarNavigation}
          iconLetter={
            portalHeaderContext.chosenName
              ? portalHeaderContext.chosenName[0]
              : undefined
          }
        />
        <div className={styles.MainContentWrapper}>
          <div className={styles.Header}>
            <TextSmall>Welcome back!</TextSmall>
            <H4 fontWeight="regular" component="h2">{`Good ${getTimeOfDay(
              new Date()
            )}${
              portalHeaderContext.chosenName
                ? `, ${portalHeaderContext.chosenName}`
                : ""
            }`}</H4>
          </div>

          <div className={styles.Scroll}>
            <div className={styles.MainContent}>{children}</div>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  ) : (
    <main role="main" className={styles.Layout}>
      <div className={styles.Grid}>
        <div className={styles.MainContentWrapper}>
          <div className={styles.Scroll}>{children}</div>
        </div>
      </div>
    </main>
  );
};

export default Layout;
