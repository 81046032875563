import React from "react";
import SectionHeader from "~/components/SectionHeader";
import ClaimCard from "~/components/ClaimCard/ClaimCardClosed";
import { ClaimClosedType } from "../../types";

import styles from "./index.module.scss";

type PropsType = {
  heading: string;
  smallHeading?: boolean;
  claims: ClaimClosedType[];
};

const ClaimClosedGroup = ({
  claims,
  heading,
  smallHeading = false,
}: PropsType) => {
  return (
    <>
      <SectionHeader
        title={heading}
        count={claims.length}
        small={smallHeading}
      />
      {claims.map((claim) => (
        <div key={claim.externalId} className={styles.Card}>
          <ClaimCard claim={claim} />
        </div>
      ))}
    </>
  );
};

export default ClaimClosedGroup;
