import React from "react";
import { useParams } from "react-router-dom";
import { CountryCode, InsuranceType } from "../types";
import BarbadosHome from "./BarbadosHome";

import NotFound from "~/components/NotFound";
import BarbadosMotor from "./BarbadosMotor";
import GuyanaHome from "./GuyanaHome";
import GuyanaMotor from "./GuyanaMotor";
import { ENABLE_HOME_FLOW } from "~/helpers/constants";

export default function FlowRouter() {
  const {
    countryCode,
    insuranceType,
  }: {
    countryCode: CountryCode;
    insuranceType: InsuranceType;
  } = useParams();

  if (countryCode.toLowerCase() === "brb") {
    if (insuranceType === "home" && ENABLE_HOME_FLOW) {
      return <BarbadosHome />;
    }

    if (insuranceType === "motor") {
      return <BarbadosMotor />;
    }
  }

  if (countryCode.toLowerCase() === "guy") {
    if (insuranceType === "home" && ENABLE_HOME_FLOW) {
      return <GuyanaHome />;
    }

    if (insuranceType === "motor") {
      return <GuyanaMotor />;
    }
  }

  return <NotFound />;
}
