import React, { useEffect } from "react";
import gql from "graphql-tag";
import { ZendeskAPI } from "react-zendesk";
import Loading from "~/components/Loading";
import PortalNotificationCard from "~/components/PortalNotificationCard";
import ClaimGroup from "~/components/ClaimGroup";
import ClaimClosedGroup from "~/components/ClaimClosedGroup";
import PortalAssistantCard from "~/components/PortalAssistantCard";
import styles from "./index.module.scss";
import PolicyGroup from "~/components/PolicyGroup";
import PortalEmpty from "~/components/PortalEmpty";
import QuoteGroup from "~/components/QuoteGroup";
import { usePortalHeaderContext } from "~/contexts/PortalHeaderProvider";
import { PortalPolicyType } from "../../../types";
import { add, format } from "date-fns";
import { POLICY_STATUS } from "~/helpers/constants";
import { useQueryThrowError } from "~/helpers/queryWithErrorHandling";
import { useHeapContext } from "~/contexts/HeapProvider";

const GET_PORTAL_INFO = gql`
  {
    getPortalInfo {
      individualProfile {
        id
        email
        name
        phoneNumber
        dateOfBirth
        address
        city
        province
        postalCode
      }
      quotes {
        id
        insuredObject
        expirationDate
        status
        typeOfCover
        vehicle {
          make
          type
        }
        premium {
          schedule
          amount
        }
      }
      claims {
        externalId
        insuredObject
        vehicle {
          make
          registration
        }
        home {
          contentsValue
          allRiskValue
        }
        policyId
        typeOfCover
        status
        claimant
        dateOfLoss
        locationOfLoss
        dateReported
        claimNarrative
      }
      closedClaims {
        externalId
        insuredObject
        vehicle {
          make
          registration
        }
        policyId
        typeOfCover
        status
        claimant
        dateOfLoss
        locationOfLoss
        dateReported
        claimNarrative
        dateSettled
      }
      policies {
        externalId
        insuredObject
        vehicle {
          make
          registration
        }
        status
        payment {
          insuredObject
          amount
          dueDate
          status
          finalCardNumbers
          resultTime
        }
        policyObject
        typeOfCover
        coverageStart
        coverageEnd
        createdAt
        holder {
          id
          name
        }
        coverage {
          peril
          coveredValue
          excessValue
          isVariableExcess
          variantName
        }
        createdAt
        documents {
          documentType
          url
        }
        premium {
          schedule
          amount
          totalValue
          nextPaymentDate
          finalPaymentDate
        }
      }
    }
  }
`;

const Dashboard = () => {
  const portalHeaderContext = usePortalHeaderContext();
  const heapCtx = useHeapContext();

  const { loading, data } = useQueryThrowError(GET_PORTAL_INFO, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      portalHeaderContext.setChosenName(
        data.getPortalInfo.individualProfile.name
      );

      heapCtx.identify(data.getPortalInfo.individualProfile.id);
    }
  }, [data, heapCtx, portalHeaderContext]);

  useEffect(() => {
    ZendeskAPI("webWidget", "show");

    return () => {
      ZendeskAPI("webWidget", "hide");
    };
  }, []);

  if (loading) {
    // To make sure Zendesk shows up on page refresh
    ZendeskAPI("webWidget", "show");

    return (
      <div className={styles.Loading}>
        <Loading />
      </div>
    );
  }

  if (data && data.getPortalInfo) {
    const { policies, quotes, claims, closedClaims } = data.getPortalInfo;

    const isExisting = policies && policies.length > 0;

    if (!isExisting && quotes && quotes.length > 0) {
      return (
        <section className={styles.Section}>
          <QuoteGroup heading="Quotes" quotes={quotes} />
        </section>
      );
    }

    if (!isExisting && quotes && quotes.length === 0) {
      return <PortalEmpty />;
    }

    return (
      <>
        {policies &&
          policies.map((policy: PortalPolicyType, idx: number) => {
            if (policy.status !== POLICY_STATUS.pendingSecondaryDocuments) {
              return null;
            }

            return (
              policy.payment.resultTime && (
                <section
                  className={styles.Section}
                  key={`PortalNotificationCardSection-${idx}`}
                >
                  <PortalNotificationCard
                    policy={policy}
                    notificationData={{
                      dueDate: format(
                        add(new Date(policy.payment.resultTime), {
                          weeks: 1,
                        }),
                        "yyyy-MM-dd"
                      ),
                      status: policy.status,
                      insuredObject: policy.insuredObject,
                    }}
                  />
                </section>
              )
            );
          })}

        {policies &&
          policies.map((policy: PortalPolicyType, idx: number) => (
            <section
              className={styles.Section}
              key={`PortalNotificationCardSection-${idx}`}
            >
              <PortalNotificationCard
                policy={policy}
                notificationData={policy.payment}
              />
            </section>
          ))}

        {quotes && quotes.length > 0 && (
          <section className={styles.Section}>
            <QuoteGroup heading="Quotes" quotes={quotes} />
          </section>
        )}

        <section className={styles.Section}>
          <ClaimGroup heading="Your Claims" claims={claims} />
        </section>

        {closedClaims && closedClaims.length > 0 && (
          <section className={styles.Section}>
            <ClaimClosedGroup
              heading="Closed Claims"
              smallHeading
              claims={closedClaims}
            />
          </section>
        )}

        <section className={styles.Section}>
          <PolicyGroup heading="Policies" policies={policies} />
        </section>

        <section className={styles.Section}>
          <PortalAssistantCard />
        </section>
      </>
    );
  }
  return <PortalEmpty />;
};

export default Dashboard;
