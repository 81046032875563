import React, { useState } from "react";
import SectionHeader from "~/components/SectionHeader";
import Button from "~/components/Button";
import ClaimCard from "~/components/ClaimCard";
import { Text } from "~/components/Typography";
import { ClaimType } from "../../types";
import styles from "./index.module.scss";
import Modal from "../Modal";
import SelectClaimType from "~/screens/SelectClaimType";

type PropsType = {
  heading: string;
  smallHeading?: boolean;
  claims?: ClaimType[];
};

const ClaimGroup = ({ claims, heading, smallHeading = false }: PropsType) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <SectionHeader
        title={heading}
        count={claims?.length}
        small={smallHeading}
        button={
          <Button
            onClick={() => setModalOpen(true)}
            variant="secondary"
            size="small"
          >
            Start a new claim
          </Button>
        }
      />

      {claims && claims.length > 0 ? (
        <>
          {claims.map((claim) => (
            <div key={claim.externalId} className={styles.Card}>
              <ClaimCard claim={claim} />
            </div>
          ))}
        </>
      ) : (
        <div className={styles.Empty}>
          <Text>You have no active claims.</Text>
        </div>
      )}

      <Modal
        title={`Start a new claim`}
        isOpen={modalOpen}
        shouldOverlayCloseOnClick
        onClose={() => setModalOpen(false)}
        noOffset
        id="new-claim-modal"
      >
        <div className={styles.Modal}>
          <SelectClaimType />
        </div>
      </Modal>
    </>
  );
};

export default ClaimGroup;
