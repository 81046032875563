import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;
const Menu = ({
  className = "menu-icon",
  fill = DEFAULT_FILL_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle cx="15" cy="15" r="15" fill={backgroundColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 11C8 10.4477 8.44772 10 9 10H21.2482C21.8004 10 22.2482 10.4477 22.2482 11C22.2482 11.5523 21.8004 12 21.2482 12H9C8.44772 12 8 11.5523 8 11ZM8 15C8 14.4477 8.44772 14 9 14H21.2482C21.8004 14 22.2482 14.4477 22.2482 15C22.2482 15.5523 21.8004 16 21.2482 16H9C8.44772 16 8 15.5523 8 15ZM9 18C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20H21.2482C21.8004 20 22.2482 19.5523 22.2482 19C22.2482 18.4477 21.8004 18 21.2482 18H9Z"
      fill={fill}
    />
  </svg>
);

export default Menu;
