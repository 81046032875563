const getAppOrigin = () => {
  // Support various origins in local development
  return (
    (process.env.REACT_APP_WITH_NGROK
      ? process.env.REACT_APP_ORIGIN_NGROK
      : process.env.REACT_APP_ENV === "local" && !process.env.REACT_APP_ORIGIN
      ? `${window.location.protocol}//${window.location.hostname}:3000`
      : process.env.REACT_APP_ORIGIN) || ""
  );
};

export default getAppOrigin;
