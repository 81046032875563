import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 25;
const logoHeight = 25;
const Info = ({
  className = "info-icon",
  fill = DEFAULT_FILL_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <ellipse
      cx="12.743"
      cy="12.5"
      rx="12.2257"
      ry="12.5"
      fill={backgroundColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 8.8354C8.25 8.42466 8.52086 8.25 8.67323 8.25H18.1073C18.2596 8.25 18.5305 8.42466 18.5305 8.8354V9.42109L18.5149 9.4209H8.25V8.8354ZM7 10.0459V8.8354C7 7.90917 7.66775 7 8.67323 7H18.1073C19.1127 7 19.7805 7.90917 19.7805 8.8354V16.0978C19.7805 17.024 19.1127 17.9332 18.1073 17.9332H8.67323C7.66775 17.9332 7 17.024 7 16.0978V10.0459ZM8.25 10.6709H18.5149L18.5305 10.6707V16.0978C18.5305 16.5085 18.2596 16.6832 18.1073 16.6832H8.67323C8.52086 16.6832 8.25 16.5085 8.25 16.0978V10.6709ZM9.56248 12.4663C9.56248 12.1211 9.8423 11.8413 10.1875 11.8413H11.1655H12.1436C12.4888 11.8413 12.7686 12.1211 12.7686 12.4663C12.7686 12.8115 12.4888 13.0913 12.1436 13.0913H11.1655H10.1875C9.8423 13.0913 9.56248 12.8115 9.56248 12.4663ZM10.1875 14.2622C9.8423 14.2622 9.56248 14.542 9.56248 14.8872C9.56248 15.2324 9.8423 15.5122 10.1875 15.5122H13.3904H16.5933C16.9385 15.5122 17.2183 15.2324 17.2183 14.8872C17.2183 14.542 16.9385 14.2622 16.5933 14.2622H13.3904H10.1875Z"
      fill={fill}
    />
  </svg>
);

export default Info;
