export function dataBase64ToBlob(dataURI: string) {
  const _dataURI = dataURI.replace(/^data:/, "");

  const type = _dataURI.match(/image\/[^;]+/);
  const base64 = _dataURI.replace(/^[^,]+,/, "");
  const arrayBuffer = new ArrayBuffer(base64.length);
  const typedArray = new Uint8Array(arrayBuffer);

  const options = type ? { type } : {};

  for (let i = 0; i < base64.length; i++) {
    typedArray[i] = base64.charCodeAt(i);
  }
  return new Blob([arrayBuffer], options as {});
}
