import { useState, useEffect } from "react";
import { useFieldApi, FormValue, FieldApi } from "informed";

export function useCustomFieldApi<VinputType = FormValue>(
  field: string
): FieldApi<VinputType> {
  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    setIsInitialRender(false);
  }, []);
  if (isInitialRender) {
    const originalWarn = console.warn;
    console.warn = (message: string) => {
      if (
        !(typeof message === "string" && message.includes("Attempting to get"))
      )
        originalWarn(message);
    };
  }
  const fieldApi = useFieldApi<VinputType>(field);

  return fieldApi;
}
