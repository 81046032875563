import React from "react";
import Loading from "../Loading";
import styles from "./index.module.scss";
import cx from "classnames";

type Props = {
  loading: boolean;
};

export default function LoadingWrapper({ loading }: Props) {
  return (
    <div
      className={cx(styles.Spinner, {
        [styles.SpinnerVisible]: loading,
      })}
    >
      <Loading />
    </div>
  );
}
