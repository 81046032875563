/* eslint-disable no-magic-numbers */
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useViewport } from "~/contexts/useViewport";
import SectionHeader from "~/components/SectionHeader";
import Button from "~/components/Button";
import { PortalPolicyType } from "../../types";
import PolicyCard from "../PolicyCard";
import PortalPolicyPlaceholder from "../PortalPolicyPlaceholder";
import styles from "./index.module.scss";

type PropsType = {
  policies: PortalPolicyType[];
  heading: string;
};

const PolicyGroup = ({ policies, heading }: PropsType) => {
  const history = useHistory();
  const handleClick = useCallback(() => history.push("/"), [history]);
  const viewport = useViewport();
  const getEmptyCols = (breakpoint: string) => {
    if (breakpoint === "medium" || breakpoint === "small") return 1;
    if (breakpoint === "large") return 2;
    return 3;
  };

  const emptyCols = getEmptyCols(viewport.breakpoint);

  const hasMotorPolicy = policies.find(
    (policy) => policy.policyObject === "auto"
  );
  const hasHomePolicy = policies.find(
    (policy) => policy.policyObject === "home"
  );

  const portalCards = policies.map((policy, idx) => (
    <PolicyCard key={idx} policy={policy} />
  ));

  if (!hasHomePolicy) {
    portalCards.push(<PortalPolicyPlaceholder type="home" key="home" />);
  }

  if (!hasMotorPolicy) {
    portalCards.push(<PortalPolicyPlaceholder type="motor" key="motor" />);
  }

  const mod = emptyCols - (portalCards.length % emptyCols);

  if (mod < emptyCols) {
    for (let idx = 0; idx < mod; idx++) {
      portalCards.push(
        <PortalPolicyPlaceholder type="empty" key={`empty-${idx}`} />
      );
    }
  }

  return (
    <>
      <SectionHeader
        title={heading}
        count={policies.length}
        button={
          <Button variant="secondary" size="small" onClick={handleClick}>
            Start a new policy
          </Button>
        }
      />
      <div className={styles.Cards}>{portalCards}</div>
    </>
  );
};

export default PolicyGroup;
