import gql from "graphql-tag";
import { useAuth0Context } from "~/contexts/Auth0Context";
import { useQueryThrowError } from "./queryWithErrorHandling";

const GET_PORTAL_INFO = gql`
  {
    getPortalInfo {
      individualProfile {
        name
      }
    }
  }
`;

export function useProfileName() {
  const auth0 = useAuth0Context();
  const { loading, data } = useQueryThrowError(GET_PORTAL_INFO, {
    skip: !auth0.token,
  });
  return !loading ? data?.getPortalInfo?.individualProfile?.name : undefined;
}
