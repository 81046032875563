import React, { memo } from "react";
import { useAuth0Context } from "~/contexts/Auth0Context";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useUserContext } from "~/contexts/UserProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import { A, TextSmall } from "~/components/Typography";
import Button from "~/components/Button";
import styles from "./index.module.scss";

function Footer() {
  const auth0 = useAuth0Context();
  const homeCtx = useHomeContext();
  const motorCtx = useMotorContext();
  const userCtx = useUserContext();
  const onboardCtx = useOnboardContext();

  const handleLogout = () => {
    homeCtx.removeStore();
    motorCtx.vehicleInfo.removeStore();
    motorCtx.savingsInfo.removeStore();
    motorCtx.policyInfo.removeStore();
    userCtx.removeStore();
    onboardCtx.removeStore();
  };

  return (
    <div className={styles.FooterWrapper}>
      <div className={styles.FooterContent}>
        <div className={styles.FooterContentCenter}>
          <TextSmall
            className={styles.portalCopyRight}
          >{`© Almi ${new Date().getFullYear()}`}</TextSmall>
          <div className={styles.portalLinks}>
            <A
              href="https://almi.bb/terms-of-use/"
              target="_blank"
              rel="noopener noreferrer"
            >{`Terms & Conditions`}</A>{" "}
            |{" "}
            <A
              href="https://almi.bb/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >{`Privacy Policy`}</A>{" "}
            |{" "}
            <Button
              type="button"
              text
              variant="textSmall"
              onClick={() => {
                handleLogout();
                auth0.logout();
              }}
            >{`Logout`}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Footer);
