import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;
const Plus = ({
  className = "plus-icon",
  fill = DEFAULT_FILL_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
    role="img"
    aria-hidden
  >
    <circle cx="15" cy="15" r="15" fill={backgroundColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3067 9.32401C16.3067 8.77173 15.859 8.32401 15.3067 8.32401C14.7545 8.32401 14.3067 8.77173 14.3067 9.32401L14.3067 14.2165L9.41421 14.2165C8.86193 14.2165 8.41421 14.6643 8.41421 15.2165C8.41421 15.7688 8.86193 16.2165 9.41421 16.2165L14.3067 16.2165L14.3067 21.1091C14.3067 21.6613 14.7545 22.1091 15.3067 22.1091C15.859 22.1091 16.3067 21.6613 16.3067 21.1091L16.3067 16.2165H21.1993C21.7515 16.2165 22.1993 15.7688 22.1993 15.2165C22.1993 14.6643 21.7515 14.2165 21.1993 14.2165H16.3067L16.3067 9.32401Z"
      fill={fill}
    />
  </svg>
);

export default Plus;
