import { FormValue, Radio, RadioGroup } from "informed";
import React, { memo, useContext } from "react";
import { useHistory } from "react-router-dom";

import MotorImg from "~/assets/images/illustration-motor.png";
import TruckImg from "~/assets/images/illustration-truck.png";
import BikeImg from "~/assets/images/illustration-bike.png";

import TileCard from "~/components/TileCard";
import { Text, H3 } from "~/components/Typography";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import { MotorContext } from "~/contexts/MotorProvider";

import { NO_COVERAGE_URL } from "~/routes/index.constant";

import styles from "./index.module.scss";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

const TYPE_VEHICLES = [
  {
    title: "Car",
    text: "e.g. Sedan, coupe, sports car, minivan, SUV",
    img: MotorImg,
  },
  {
    title: "Truck",
    text: "e.g. Pickup, full-size van, motorhome, tractor",
    img: TruckImg,
  },
  {
    title: "Motorcycle",
    text: "e.g. Scooter, chopper, sportbike, cruiser",
    img: BikeImg,
  },
];

function VehicleTypes({ nextPath }: { nextPath?: string }) {
  const motorCtx = useContext(MotorContext);
  const { updateAssistant } = useAssistantContext();
  const history = useHistory();
  const heapCtx = useHeapContext();

  React.useEffect(() => {
    updateAssistant({
      isOpen: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = async (value: FormValue) => {
    try {
      await motorCtx.vehicleInfo.setItem("type", value as string);

      heapCtx.track(HeapEventName.MOTOR_VEHICLE_TYPE, {
        "Vehicle Type": value as string,
      });

      if (value === "Motorcycle") {
        history.push({
          pathname: NO_COVERAGE_URL,
          search: "?message=motorcycle",
        });
      } else if (nextPath) {
        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Text className={styles.Subheading}>Let&apos;s find your vehicle.</Text>
      <fieldset>
        <legend>
          <H3 component="h1">What type of vehicle are you insuring?</H3>
        </legend>
        <div className={styles.VehicleTypesContent}>
          <RadioGroup field="type" keepState>
            {TYPE_VEHICLES.map(({ text, title, img }, index) => (
              <TileCard
                key={title}
                title={title}
                text={text}
                className={styles.Tile}
                inputId={`item-${index}`}
                checked={motorCtx.vehicleInfo.data.type === title}
                inputElement={
                  <Radio
                    id={`item-${index}`}
                    field="type"
                    value={title}
                    onClick={() => handleOnChange(title)}
                    className={styles.HideRadio}
                    checked={motorCtx.vehicleInfo.data.type === title}
                    name="type"
                  />
                }
                left={
                  <img
                    src={img}
                    alt={`Icon ${title}`}
                    aria-hidden
                    className={styles.TitleIcon}
                  />
                }
              />
            ))}
          </RadioGroup>
        </div>
      </fieldset>
    </>
  );
}
export default memo(VehicleTypes);
