import React, {
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

type Props = {
  children?: ReactNode;
};

type IPortalHeaderContext = {
  chosenName: string | null;
  setChosenName: Dispatch<SetStateAction<string | null>>;
};

const initialState: IPortalHeaderContext = {
  chosenName: null,
  setChosenName: () => {
    throw new Error("PortalHeader context has not yet been initialized");
  },
};

export const PortalHeaderContext = React.createContext(initialState);
export const usePortalHeaderContext = () => useContext(PortalHeaderContext);

export default function HeaderProvider({ children }: Props) {
  const [chosenName, setChosenName] = useState<string | null>(null);

  return (
    <PortalHeaderContext.Provider
      value={{
        chosenName,
        setChosenName,
      }}
    >
      {children}
    </PortalHeaderContext.Provider>
  );
}
