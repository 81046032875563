import * as storage from "~/helpers/storage";
import { PreviousClaim } from "../../../types";
import { FormValue } from "informed";

export type SavingsInfoInputs = {
  occupation?: string;
  previousClaims: PreviousClaim[];
  association?: string;
};

const defaultValue: SavingsInfoInputs = {
  occupation: undefined,
  previousClaims: [],
  association: "",
};

const keyStore = "savingsInfo";

export const SAVINGS_INFO_DEFAULT_STATE = () => {
  let savedValue = storage.getItem(keyStore) as object;
  savedValue = !savedValue ? {} : savedValue;
  return {
    data: { ...defaultValue, ...savedValue },
    setItem(key: string, value: FormValue | Array<PreviousClaim | undefined>) {
      this.data[key] = value;
      storage.saveItem(keyStore, this.data);
    },
    removeItem(key: string) {
      this.data[key] = undefined;
      storage.saveItem(keyStore, this.data);
    },
    removeStore() {
      this.data = { ...defaultValue };
      storage.removeItem(keyStore);
    },
  };
};
