import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import OnboardingRoutes from "./routes/OnboardingRoutes";
import PortalRoutes from "./routes/PortalRoutes";
import GoogleAnalyticsProvider from "~/contexts/GoogleAnalyticsProvider";
import GTMProvider from "~/contexts/GTMProvider";
import ErrorBoundary from "./components/ErrorBoundary";
import ActiveCampaign from "./components/ActiveCampaign";
import AddMetas from "./components/AddMetas";
import { ViewportProvider } from "./contexts/useViewport";
import Error from "./components/Error";
import HeapProvider from "./contexts/HeapProvider";
import PortalChecklistHome from "./flows/PortalChecklistHome";
import PortalChecklistMotor from "./flows/PortalChecklistMotor";
import { HelmetProvider } from "react-helmet-async";
import Session from "~/components/Session";
import { Auth0Provider } from "~/contexts/Auth0Context";
import { ModalProvider } from "~/components/Modal";
import CameraProvider from "~/contexts/CameraProvider";
import AccessRoute from "~/components/AccessRoute";
import SessionExpired from "~/components/SessionExpired";
import ScrollToTop from "~/components/ScrollToTop";
import Zendesk, { ZendeskAPI } from "react-zendesk";

const ZENDESK_WIDGET_KEY = process.env.REACT_APP_ZENDESK_WIDGET_KEY;

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ErrorBoundary errorComponent={Error}>
        <ModalProvider>
          <AccessRoute>
            <HeapProvider>
              <GTMProvider />
              <GoogleAnalyticsProvider>
                <Auth0Provider>
                  <Session>
                    <ViewportProvider>
                      <HelmetProvider>
                        <CameraProvider>
                          <AddMetas>
                            <ActiveCampaign>
                              <Switch>
                                <Route
                                  path={"/portal"}
                                  component={PortalRoutes}
                                />
                                <Route
                                  path={"/checklist/motor/:policyId/:step"}
                                  component={PortalChecklistMotor}
                                />
                                <Route
                                  path={"/checklist/home/:policyId/:step"}
                                  component={PortalChecklistHome}
                                />
                                <Route
                                  path={"/session-expired"}
                                  component={SessionExpired}
                                />
                                <Route
                                  path={"/"}
                                  component={OnboardingRoutes}
                                />
                              </Switch>
                            </ActiveCampaign>
                          </AddMetas>
                        </CameraProvider>
                      </HelmetProvider>
                    </ViewportProvider>
                  </Session>
                </Auth0Provider>
              </GoogleAnalyticsProvider>
            </HeapProvider>
          </AccessRoute>
        </ModalProvider>
      </ErrorBoundary>
      {ZENDESK_WIDGET_KEY && (
        <Zendesk
          zendeskKey={ZENDESK_WIDGET_KEY}
          onLoaded={() => {
            ZendeskAPI("webWidget", "hide");
          }}
        />
      )}
    </Router>
  );
}

export default App;
