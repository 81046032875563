import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import getAppOrigin from "~/helpers/getAppOrigin";

const origin = getAppOrigin();

export default function PaymentLanding() {
  const [securityToken, setSecurityToken] = useState("");
  const querySearch = new URLSearchParams(useLocation().search);

  useEffect(() => {
    setSecurityToken(querySearch.get("ID") || "");
  }, [querySearch]);

  useEffect(() => {
    if (securityToken && window.parent) {
      window.parent.postMessage(
        { messageType: "page-loaded", securityToken },
        origin
      );
    }
  }, [securityToken]);

  return <></>;
}
