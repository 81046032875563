import React, { ReactNode } from "react";
import { A, H3, Text } from "~/components/Typography";
import Button from "~/components/Button";
import { useHistory } from "react-router-dom";
import AlertImage from "~/assets/images/alert.png";

import styles from "./index.module.scss";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { AssistantData } from "~/contexts/AlmiAssistantProvider";
import Footer from "~/components/Footer";

export type ButtonOptions = {
  text: string;
  onClick: () => void;
};

export type PropsType = {
  subtitle: string;
  options?: {
    title?: string;
    image?: ReactNode;
    assistantMessage?: AssistantData;
    mainButton?: ButtonOptions;
    secondaryButton?: ButtonOptions;
  };
};

const Error = ({
  subtitle,
  options = {
    title: undefined,
    mainButton: undefined,
    secondaryButton: undefined,
    assistantMessage: undefined,
  },
}: PropsType) => {
  const history = useHistory();

  const {
    title,
    mainButton,
    secondaryButton,
    assistantMessage = { text: undefined, isOpen: false },
  } = options;

  useUpdateAssistant(assistantMessage);

  const renderButton = (options: ButtonOptions) => {
    return (
      <Button className={styles.Button} onClick={options.onClick}>
        {options.text}
      </Button>
    );
  };

  return (
    <>
      <div className={styles.Wrapper}>
        {!options?.image ? (
          <img className={styles.Alert} src={AlertImage} alt="Alert" />
        ) : (
          options.image
        )}

        <H3 className={styles.Title} component="h1">
          {title || "Uh-oh. Something went wrong"}
        </H3>
        <Text className={styles.Subtitle}>
          {subtitle}
          {"\n"}
          {"\n"}
          {title !== "Uh-oh. Transaction failed." && (
            <>
              If you think this is a mistake or continues to happen, please
              contact us at{" "}
              <A
                href="https://almi.bb/support"
                target="_blank"
                fontWeight="bold"
              >
                almi.bb/support
              </A>{" "}
              to help you sort it out.
            </>
          )}
          {title === "Uh-oh. Transaction failed." && (
            <>
              Note: If your card was charged in error during this process,
              please contact us at{" "}
              <A
                href="http://almi.bb/support"
                target="_blank"
                fontWeight="bold"
              >
                almi.bb/support
              </A>{" "}
              immediately to get a refund.
            </>
          )}
        </Text>
        <div className={styles.ButtonRow}>
          {mainButton
            ? renderButton(mainButton)
            : renderButton({
                text: "Go Back",
                onClick: () => history.goBack(),
              })}
          {secondaryButton && renderButton(secondaryButton)}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Error;
