import { InsuranceType } from "../types";

export default function getPolicyCodeFromInsuranceType(
  insuranceType: InsuranceType
) {
  switch (insuranceType) {
    case "motor": {
      return "auto";
    }

    case "home": {
      return "home";
    }

    default: {
      return "Unsupported Policy Type";
    }
  }
}
