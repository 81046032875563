import { Radio, RadioGroup } from "informed";
import React from "react";
import { useHistory } from "react-router";
import TileCard from "~/components/TileCard";
import { H3, H5, Text } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import pushWithParams from "~/helpers/pushWithParams";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
  countryName: string;
};

type CoverageOption = {
  title: string;
  text: string;
  value: string;
};

export default function AllRiskType({ nextPath, countryName }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const homeCtx = useHomeContext();
  const fieldName = "worldwideCoverage";
  const fieldState = useCustomFieldState(fieldName);

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 1,
      totalOfSteps: 3,
      title: "Contents Information",
    });
  });

  const handleOnChangeValue = (selection: string) => {
    try {
      if (selection === "local" || selection === "worldwide") {
        homeCtx.setState({
          allRiskType: selection,
        });

        if (nextPath) {
          pushWithParams(history, nextPath);
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const options: CoverageOption[] = [
    {
      title: `${countryName} coverage`,
      text: `Items will only be covered in ${countryName}`,
      value: "local",
    },
    {
      title: "Worldwide coverage",
      text: "Items will be covered anywhere in the world",
      value: "worldwide",
    },
  ];

  return (
    <>
      <H3>Do you want protection in {countryName}, or worldwide?</H3>

      <Text className={styles.SubTitle}>
        This will apply to all items you’ve selected under all risks.
      </Text>

      <div className={styles.TileGroupWrapper}>
        <RadioGroup field={fieldName} keepState>
          {options.map((option, index) => (
            <TileCard
              key={`WorldwideCoverage-TileCard-${index}`}
              title={<H5>{option.title}</H5>}
              text={option.text}
              inputId={`WorldwideCoverage-TileCard-${index}`}
              checked={
                fieldState.value === option.value ||
                homeCtx.allRiskType === option.value
              }
              inputElement={
                <Radio
                  id={`WorldwideCoverage-TileCard-${index}`}
                  field={fieldName}
                  value={option.value}
                  onClick={() => handleOnChangeValue(option.value)}
                  className={styles.HideRadio}
                  checked={fieldState.value === option}
                />
              }
              className={styles.TileCard}
            />
          ))}
        </RadioGroup>
      </div>
    </>
  );
}
