import React from "react";
import classnames from "classnames";
import { TextSmall, H4, TextExtraSmall } from "~/components/Typography";
import TypeImage from "../TypeImage";
import CheckImg from "~/assets/images/check.png";
import Status from "~/components/Status";
import { getTypeOfCoverage } from "~/helpers/getCoverageData";
import getDisplayStatus from "~/helpers/getDisplayStatus";
import styles from "./index.module.scss";
import { ClaimType } from "../../types";
import { formatIsoDate } from "~/helpers/dates";

type PropsType = {
  claim: ClaimType;
};

const ClaimDetails = ({ claim }: PropsType) => {
  const {
    insuredObject,
    externalId,
    vehicle,
    home,
    claimant,
    status,
    policyId,
    typeOfCover,
    dateOfLoss,
    locationOfLoss,
    dateReported,
    claimNarrative,
  } = claim;

  const getHomeTitle = () => {
    if (home?.allRiskValue) {
      return "Property + Contents + All Risk";
    } else if (home?.contentsValue) {
      return "Property + Contents";
    } else {
      return "Proeprty";
    }
  };

  return (
    <article className={styles.Article}>
      <div className={styles.Image}>
        {claim.vehicle ? (
          <TypeImage vehicleMake={vehicle.make} type="motor" variant="small" />
        ) : (
          <TypeImage type="home" variant="small" />
        )}
      </div>

      <div className={styles.Header}>
        <H4>{insuredObject}</H4>
        <TextExtraSmall>
          {`Claim No. ${externalId}`} <Status status={status} />
        </TextExtraSmall>
      </div>

      <div className={styles.Details}>
        <div className={styles.Icon}>
          <img src={CheckImg} className={styles.Check} alt="check" />
        </div>

        <div className={styles.Title}>
          <TextSmall>{`No. ${externalId}`}</TextSmall>
          <H4>Claim Details</H4>
        </div>

        <dl className={classnames(styles.List, styles.Left)}>
          <div>
            <dt>
              <TextSmall fontWeight="bold">Claimants</TextSmall>
            </dt>

            <dd>
              <TextSmall>{claimant}</TextSmall>
            </dd>
          </div>

          <div>
            <dt>
              <TextSmall fontWeight="bold">Policy No</TextSmall>
            </dt>

            <dd>
              <TextSmall>{policyId}</TextSmall>
            </dd>
          </div>

          <div>
            <dt>
              <TextSmall fontWeight="bold">Type</TextSmall>
            </dt>

            <dd>
              <TextSmall>
                {typeOfCover === "S"
                  ? getHomeTitle()
                  : getTypeOfCoverage(typeOfCover)?.title}
              </TextSmall>
            </dd>
          </div>

          <div>
            <dt>
              <TextSmall fontWeight="bold">Status</TextSmall>
            </dt>

            <dd>
              <TextSmall>{getDisplayStatus(status)}</TextSmall>
            </dd>
          </div>

          {claim.vehicle && (
            <div>
              <dt>
                <TextSmall fontWeight="bold">
                  Vehicle Registration No.
                </TextSmall>
              </dt>

              <dd>
                <TextSmall>{vehicle.registration}</TextSmall>
              </dd>
            </div>
          )}
        </dl>

        <dl className={classnames(styles.List, styles.Right)}>
          <div>
            <dt>
              <TextSmall fontWeight="bold">Date of Loss</TextSmall>
            </dt>

            <dd>
              <TextSmall>{formatIsoDate(dateOfLoss)}</TextSmall>
            </dd>
          </div>

          <div>
            <dt>
              <TextSmall fontWeight="bold">Location of Loss</TextSmall>
            </dt>

            <dd>
              <TextSmall>{locationOfLoss}</TextSmall>
            </dd>
          </div>

          <div>
            <dt>
              <TextSmall fontWeight="bold">Date Reported</TextSmall>
            </dt>

            <dd>
              <TextSmall>{formatIsoDate(dateReported)}</TextSmall>
            </dd>
          </div>

          <div>
            <dt>
              <TextSmall fontWeight="bold">Claim Narrative</TextSmall>
            </dt>

            <dd>
              <TextSmall>{claimNarrative}</TextSmall>
            </dd>
          </div>
        </dl>
      </div>
    </article>
  );
};

export default ClaimDetails;
