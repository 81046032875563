const MIDNIGHT = 0;
const NOON = 12;
const NIGHT = 18;

const getTimeOfDay = (date: Date) => {
  const hours = date.getHours();
  if (hours >= MIDNIGHT && hours <= NOON) {
    return "morning";
  }
  if (hours > NOON && hours < NIGHT) {
    return "afternoon";
  }
  return "evening";
};

export default getTimeOfDay;
