import React from "react";
import { H3, PreText } from "~/components/Typography";
import Button from "~/components/Button";
import { useHistory } from "react-router-dom";
import NotFoundImage from "~/assets/images/404.png";

import styles from "./index.module.scss";

const NotFound = () => {
  const history = useHistory();

  return (
    <div className={styles.Wrapper}>
      <img className={styles.NotFound} src={NotFoundImage} alt="Error" />
      <H3 className={styles.Title} component="h1">
        Uh Oh 404
      </H3>
      <PreText className={styles.Subtitle}>
        Something isn’t quite right. Please go back and try again.
      </PreText>
      <Button
        className={styles.Button}
        onClick={() => {
          if (history.length) {
            history.goBack();
          } else {
            history.push("/");
          }
        }}
      >
        Go back
      </Button>
      <br />
    </div>
  );
};

export default NotFound;
