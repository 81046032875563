import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import { getSvgWrapperDefaultValues } from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;
const CheckRound = ({
  className = "check-icon",
  width = logoWidth,
  height = logoHeight,
  backgroundColor = "#FDEDBF",
  fill = "#23453A",
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
    role="img"
    aria-label="check"
  >
    <circle cx="15.0837" cy="15" r="15" fill={backgroundColor} />
    <path
      d="M8.25 12.75L15 7.5L21.75 12.75V21C21.75 21.3978 21.592 21.7794 21.3107 22.0607C21.0294 22.342 20.6478 22.5 20.25 22.5H9.75C9.35218 22.5 8.97064 22.342 8.68934 22.0607C8.40804 21.7794 8.25 21.3978 8.25 21V12.75Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 22.5V15H17.25V22.5"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckRound;
