import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import LoadingWrapper from "~/components/LoadingWrapper";
import Modal from "~/components/Modal";
import SelectableCard from "~/components/SelectableCard";
import { H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import AdditionalDriverModal from "./AdditionalDriverModal";
import styles from "./style.module.scss";

type Props = {
  nextPath?: string;
};

export type ChecklistAdditionalDriverInput = {
  name: string;
  age: number;
  yearsDrivingExperience: number;
  occupation?: string;
  licenseNumber?: string;
  licenseDateOfIssue?: string;
  licenseType?: string;
};

export type ChecklistAdditionalDriver = ChecklistAdditionalDriverInput & {
  index: number;
  completed: boolean;
};

export default function AdditionalDriverDetails({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [additionalDrivers, setAdditionalDrivers] = React.useState<
    ChecklistAdditionalDriver[]
  >([]);
  const [allDriversValid, setAllDriversValid] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [
    selectedDriver,
    setSelectedDriver,
  ] = React.useState<ChecklistAdditionalDriver>();
  const [saving, setSaving] = React.useState<boolean>(false);

  const onContinueClick = async () => {
    try {
      if (nextPath) {
        setSaving(true);

        const drivers: ChecklistAdditionalDriverInput[] = [];

        additionalDrivers.forEach((driver) => {
          drivers.push({
            name: driver.name,
            age: driver.age,
            yearsDrivingExperience: driver.yearsDrivingExperience,
            occupation: driver.occupation,
            licenseNumber: driver.licenseNumber,
            licenseDateOfIssue: driver.licenseDateOfIssue,
            licenseType: driver.licenseType,
          });
        });

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          additionalDrivers: drivers,
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  const openAdditionalDriverModal = (
    additionalDriver: ChecklistAdditionalDriver
  ) => {
    setSelectedDriver(additionalDriver);
    setOpenModal(true);
  };

  const handleModalSave = (savedDriver: ChecklistAdditionalDriver) => {
    const i = additionalDrivers.findIndex((driver) => {
      return driver.index === savedDriver.index;
    });

    const newDrivers: ChecklistAdditionalDriver[] = [];

    additionalDrivers.forEach((driver, index) => {
      if (index === i) {
        newDrivers.push({ ...savedDriver });
      } else {
        newDrivers.push({ ...driver });
      }
    });

    setAdditionalDrivers(newDrivers);
    setOpenModal(false);
  };

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 2,
      totalOfSteps: 6,
      title: "Additional Driver's Details",
    });
  });

  //Driver validation
  React.useEffect(() => {
    let valid = true;

    if (additionalDrivers) {
      for (const driver of additionalDrivers) {
        if (!driver.completed) {
          valid = false;
          break;
        }
      }
    }

    setAllDriversValid(valid);
  }, [additionalDrivers]);

  //Initial setup of drivers
  React.useEffect(() => {
    if (
      checklistCtx.additionalDrivers &&
      checklistCtx.additionalDrivers.length
    ) {
      const drivers: ChecklistAdditionalDriver[] = [];

      checklistCtx.additionalDrivers.forEach((driver, index) => {
        const newDriver: ChecklistAdditionalDriver = {
          index,
          name: driver.name ?? "",
          age: driver.age ?? "",
          yearsDrivingExperience: driver.yearsDrivingExperience ?? "",
          occupation: driver.occupation ?? "",
          licenseNumber: driver.licenseNumber ?? "",
          licenseDateOfIssue: driver.licenseDateOfIssue ?? "",
          licenseType: driver.licenseType ?? "",
          completed: false,
        };

        if (
          newDriver.name &&
          newDriver.age &&
          newDriver.yearsDrivingExperience &&
          newDriver.occupation &&
          newDriver.licenseNumber &&
          newDriver.licenseDateOfIssue &&
          newDriver.licenseType
        ) {
          newDriver.completed = true;
        }

        drivers.push(newDriver);
      });

      setAdditionalDrivers(drivers);
    } else if (nextPath) {
      history.push(nextPath);
    }
  }, [checklistCtx.additionalDrivers, history, nextPath]);

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>A few more details about your additional drivers.</H3>

          <Text className={styles.SubHeaderText}>
            Fill out the details for each additional driver
          </Text>

          <div className={styles.DriversContentWrapper}>
            <div className={styles.DriverCardsWrapper}>
              {additionalDrivers.map(
                (additionalDriver: ChecklistAdditionalDriver) => {
                  return (
                    <SelectableCard
                      key={`AdditionalDriver-SelectableCard-${additionalDriver.index}`}
                      title={additionalDriver.name}
                      description={
                        additionalDriver.completed
                          ? "Details added"
                          : "Click to add details"
                      }
                      selected={additionalDriver.completed}
                      onClick={() => {
                        openAdditionalDriverModal(additionalDriver);
                      }}
                    />
                  );
                }
              )}
            </div>
          </div>

          <Modal
            isOpen={openModal && !!selectedDriver}
            title={`${selectedDriver?.name}'s details`}
            shouldOverlayCloseOnClick
          >
            {selectedDriver && (
              <AdditionalDriverModal
                additionalDriver={selectedDriver}
                handleSave={handleModalSave}
              />
            )}
          </Modal>

          <SubmitButton
            className={styles.ContinueButton}
            onClick={onContinueClick}
            disabled={!allDriversValid || saving}
          >
            Continue
          </SubmitButton>
        </>
      )}
    </>
  );
}
