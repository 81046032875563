import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";
const logoWidth = 30;
const logoHeight = 30;
const Download = ({
  className = "download-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle
      cx="15"
      cy="15"
      r="15"
      transform="rotate(-90 15 15)"
      fill={backgroundColor}
    />

    <path
      d="M21 17V19.6667C21 20.0203 20.8595 20.3594 20.6095 20.6095C20.3594 20.8595 20.0203 21 19.6667 21H10.3333C9.97971 21 9.64057 20.8595 9.39052 20.6095C9.14048 20.3594 9 20.0203 9 19.6667V17"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 12.3333L15 9L11.6667 12.3333"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9V17"
      stroke={fill}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Download;
