/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/generic-type-naming */
import { QueryResult, MutationResult } from "@apollo/react-common";
import {
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  useMutation,
  useQuery,
} from "@apollo/react-hooks";
import { OperationVariables } from "apollo-client";
import { DocumentNode } from "graphql";

/**
 * Catch errors Apollo knows about, plus
 * custom errors on successful responses
 */
function throwQueryError(result: QueryResult | MutationResult<any>) {
  const { loading, error, data } = result;
  if (loading) return;

  if (error) {
    throw error;
  }

  const resultWithError = data
    ? (Object.values(data).find((result: any) => result?.errMessage) as {
        errMessage: string;
      })
    : undefined;

  if (resultWithError) {
    throw new Error(resultWithError.errMessage);
  }
}

export function useQueryThrowError<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const result = useQuery(query, options);
  throwQueryError(result);
  return result;
}

export function useMutationThrowError<
  TData = any,
  TVariables = OperationVariables
>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  const result = useMutation(mutation, options);
  throwQueryError(result[1]);
  return result;
}
