import React from "react";
import { useFormApi, FieldProps, FormValue, FormValues } from "informed";
import { format } from "date-fns";
import Icon from "~/components/Icon";
import { TextSmall } from "~/components/Typography";
import Modal from "~/components/Modal";
import GridWrapper from "~/components/GridWrapper";
import DatePicker from "~/components/DatePicker";
import TextDate from "./TextDate";
import styles from "./index.module.scss";
import { DateInputProps } from "./index.types";

const TextDateWithPicker = (
  props: FieldProps<FormValue, FormValues> & DateInputProps
) => {
  const { setValue, setTouched } = useFormApi();
  const [openModal, setOpenModal] = React.useState(false);
  const { date, minimumDate, maximumDate, ...rest } = props;
  return (
    <>
      <TextDate {...rest} />
      <button
        type="button"
        onClick={() => setOpenModal(true)}
        className={styles.CalendarToggle}
        tabIndex={-1}
      >
        <Icon name="Calendar" className={styles.CalendarIcon} />
        <TextSmall className={styles.CalendarCta}>Choose date</TextSmall>
      </button>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title="Select a day"
        shouldOverlayCloseOnClick
      >
        <GridWrapper contentClassName={styles.Grid}>
          <div className={styles.Calendar} aria-hidden>
            <DatePicker
              date={date}
              minimumDate={minimumDate}
              maximumDate={maximumDate}
              setDate={(date) => {
                if (date) {
                  setValue(props.field, format(date, "dd/MM/yyyy"));
                  setTouched(props.field, true);
                }
                setOpenModal(false);
              }}
            />
          </div>
        </GridWrapper>
      </Modal>
    </>
  );
};

export default TextDateWithPicker;
