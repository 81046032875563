import React from "react";
import DownloadImg from "~/assets/images/download.svg";
import styles from "./index.module.scss";

type PropsType = {
  name: string;
  url: string;
};

const DownloadLink = ({ name, url }: PropsType) => {
  return (
    <a href={url} download className={styles.Link}>
      {name}
      <img
        src={DownloadImg}
        alt="download icon"
        className={styles.Icon}
        aria-hidden
      />
    </a>
  );
};

export default DownloadLink;
