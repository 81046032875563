import { FormValue, Radio, RadioGroup } from "informed";
import React, { useLayoutEffect, useState } from "react";
import classnames from "classnames";

import { SearchInput } from "../SearchInput";
import TileCard from "~/components/TileCard";
import { H3, Text } from "~/components/Typography";

import styles from "./index.module.scss";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

type ListObjectItem = unknown[];

type Props = {
  classNameGrid?: string;
  list: Array<{}> | string[];
  listKeyToFind?: string;
  renderItem?: (item: unknown, index: number) => JSX.Element;
  field: string;
  keepState?: boolean;
  loading?: boolean;
  onChange?: (value: FormValue) => void;
  autoFocus?: boolean;
  excludeFromFilter?: string[];
  noResultsCopy?: string;
  initialValue?: string;
  onFilterChange?: (filter: string) => void;
};

const SearchList = ({
  list,
  listKeyToFind,
  field,
  keepState,
  onChange,
  loading = false,
  classNameGrid,
  renderItem,
  autoFocus,
  excludeFromFilter,
  noResultsCopy,
  initialValue,
  onFilterChange,
}: Props) => {
  const [filter, setFilter] = useState("");
  const [filteredList, setFilteredList] = useState<ListObjectItem>([]);

  const fieldState = useCustomFieldState(field);

  if (
    !!(list as unknown[]).find((item) => typeof item === "object") &&
    (!listKeyToFind || !renderItem)
  ) {
    throw new Error(
      "[SearchList]: listKeyToFind and renderItem are required for list of objects"
    );
  }

  useLayoutEffect(() => {
    const searchList = list.filter((item) => {
      if (typeof item === "object" && listKeyToFind)
        return (
          item[listKeyToFind].toLowerCase().includes(filter) ||
          excludeFromFilter?.includes(item[listKeyToFind])
        );
      if (typeof item === "string")
        return (
          item.toLowerCase().includes(filter) ||
          excludeFromFilter?.includes(item)
        );
      return undefined;
    });
    setFilteredList(searchList);
  }, [filter, list, listKeyToFind, excludeFromFilter]);

  const handleChange = (value: string) => {
    setFilter(value.toLowerCase());

    if (typeof onFilterChange === "function") {
      onFilterChange(value.toLowerCase());
    }
  };

  const handleOnChangeValue = (formValue: FormValue) => {
    if (typeof onChange === "function") {
      onChange(formValue);
    }
  };

  return (
    <div className={styles.SearchListWrapper}>
      <SearchInput
        onChange={handleChange}
        autoFocus={autoFocus}
        searchListId="search-list"
      />

      <div className={styles.SearchListContainer}>
        <div className={styles.VisuallyHidden} role="status">
          {`${filteredList.length} results found`}
        </div>

        <ul
          className={classnames(styles.SearchListResults, classNameGrid)}
          id="search-list"
        >
          <RadioGroup field={field} keepState={keepState}>
            {typeof renderItem === "function"
              ? (filteredList as unknown[]).map(renderItem)
              : (filteredList as string[]).map((item, i) => (
                  <TileCard
                    component="li"
                    key={`SearchInput-TileCard-${i}`}
                    title={item}
                    inputId={`SearchInput-TileCard-${i}`}
                    checked={
                      initialValue
                        ? initialValue === item
                        : fieldState.value === item
                    }
                    inputElement={
                      <Radio
                        id={`SearchInput-TileCard-${i}`}
                        field={field}
                        value={item}
                        onClick={() => handleOnChangeValue(item)}
                        className={styles.VisuallyHidden}
                        checked={
                          initialValue
                            ? initialValue === item
                            : fieldState.value === item
                        }
                      />
                    }
                  />
                ))}
          </RadioGroup>
        </ul>

        {filteredList.length < 1 && !loading && filter.length > 0 && (
          <div className={styles.NoResults} id="search-list-desc">
            <H3>No results found.</H3>

            {noResultsCopy ? (
              <Text>{noResultsCopy}</Text>
            ) : (
              <Text>Try to type something new</Text>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchList;
