import React from "react";
import { FormValue, RadioGroup, useFormState } from "informed";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";
import RadioSelector from "~/components/RadioSelector";
import { H3, TextSmall } from "~/components/Typography";

import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";

import { useMotorContext } from "~/contexts/MotorProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";

import pushWithParams from "~/helpers/pushWithParams";
import { HeapEventName, useHeapContext } from "~/contexts/HeapProvider";

export default function NoClaimsLength({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const formState = useFormState();
  const fieldName = "yearsWithoutClaims";
  const heapCtx = useHeapContext();

  const {
    values: { hadClaim },
  } = formState;

  useUpdateAssistant({
    text: (
      <TextSmall>
        If you have a No Claims Discount, you may be asked to provide an NCD
        letter in the future.
      </TextSmall>
    ),
  });

  const handleOnChange = async (value: FormValue) => {
    try {
      if (value === "no") {
        //no claim
        const yearsDriving = motorCtx.policyInfo.data.yearsDriving;
        const newYearsWithoutClaims = Number(yearsDriving);
        await motorCtx.policyInfo.setItem(fieldName, newYearsWithoutClaims);
        await motorCtx.policyInfo.setItem("claimsHistory", ">2");

        heapCtx.track(HeapEventName.MOTOR_PAST_CLAIMS, {
          Claims: false,
        });

        pushWithParams(history, "/BRB/motor/job-shift-work");
      } else {
        //there's claim, get years on next screen
        if (nextPath) {
          heapCtx.track(HeapEventName.MOTOR_PAST_CLAIMS, {
            Claims: true,
          });

          pushWithParams(history, nextPath);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <VehicleSearchDisplaySmall
        prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
      />

      <H3 className={styles.Question}>
        Have you ever submitted a motor insurance claim?
      </H3>

      <div className={styles.claimContent}>
        <RadioGroup field="hadClaim" keepState>
          <RadioSelector
            key="yes"
            field="hadClaim"
            value="yes"
            checked={hadClaim === "yes"}
            onChange={(value) => handleOnChange(value)}
          >
            Yes
          </RadioSelector>

          <RadioSelector
            key="no"
            field="hadClaim"
            value="no"
            checked={hadClaim === "no"}
            onChange={(value) => handleOnChange(value)}
          >
            No
          </RadioSelector>
        </RadioGroup>
      </div>
    </>
  );
}
