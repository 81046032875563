import React from "react";
import classnames from "classnames";
import CarImg from "~/assets/images/car.png";
import HomeImg from "~/assets/images/home.png";
import { H5 } from "~/components/Typography";
import Button from "~/components/Button";
import styles from "./index.module.scss";
import { ENABLE_HOME_FLOW } from "~/helpers/constants";

type PropsType = {
  type: "motor" | "home" | "empty";
};

const content = [
  {
    id: "home",
    title: "Need a policy for your home?",
    subtitle: "Let’s get started.",
    img: { src: HomeImg, alt: "Home icon" },
  },
  {
    id: "motor",
    title: "Need a policy for your vehicle?",
    subtitle: "Let’s get started.",
    img: { src: CarImg, alt: "Car icon" },
  },
];

const PortalPolicyPlaceholder = ({ type }: PropsType) => {
  if (type === "home" && !ENABLE_HOME_FLOW) {
    return <></>;
  }
  const data = content.find((c) => c.id === type);
  return (
    <div className={classnames(styles.Wrapper, styles[type])}>
      {type !== "empty" && data && (
        <>
          <img src={data.img.src} alt={data.img.alt} className={styles.Img} />
          <div className={styles.Content}>
            <H5 className={styles.Title} component="h4">
              {data.title}
            </H5>
            <H5 className={styles.Subtitle}>{data.subtitle}</H5>
          </div>
          <Button
            internalLink="/"
            type="button"
            size="small"
            className={styles.Button}
          >
            Start a new policy
          </Button>
        </>
      )}
    </div>
  );
};

export default PortalPolicyPlaceholder;
