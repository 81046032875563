import React, { Fragment } from "react";
import { TextExtraSmall, TextSmall } from "~/components/Typography";
import styles from "./index.module.scss";

type DataType = {
  title?: string;
  value?: string;
  subTitle?: string;
};

type PropsType = {
  data: DataType[];
};

const PortalList = ({ data }: PropsType) => {
  return (
    <dl className={styles.List}>
      {data.map((d, idx) => (
        <Fragment key={idx}>
          {d.title && (
            <dt>
              <TextSmall fontWeight="bold">{d.title}</TextSmall>
            </dt>
          )}

          {d.value && (
            <dd>
              <TextSmall>{d.value}</TextSmall>
            </dd>
          )}

          {d.subTitle && (
            <dd>
              <TextExtraSmall>{d.subTitle}</TextExtraSmall>
            </dd>
          )}
        </Fragment>
      ))}
    </dl>
  );
};

export default PortalList;
