export function floatToCurrency(value: number | string) {
  const fractionDigits = 2;
  return parseFloat(value.toString()).toFixed(fractionDigits);
}

export function formatCurrency(
  value: number,
  dropZeroes = false,
  prefix = false,
  currencyCode: "BBD" | "GYD" = "BBD",
  round = true
) {
  // If each currency code starts requiring different behaivours it would be beneficial to give each currency a function
  // with arguments (value,dropZeroes,prefix,round), e.g. if you need these four styles at once
  // $1,000.00 , $1,000 , BBD 1,000.00 , BBD 1,000
  const currencies = [
    {
      currency: "BBD",
      numberPrefix: "$",
    },
    {
      currency: "GYD",
      numberPrefix: "$",
    },
  ];
  const roundedValue = round ? floatToCurrency(value) : value;
  const currency = currencies.find(({ currency }) => currencyCode === currency);
  const numberPrefix = currency?.numberPrefix ?? "";

  if (!currency) throw new Error("Unknown currency code");
  const formatted = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(Number(roundedValue));
  const stripped = dropZeroes ? formatted.replace(/\.\d{2}$/, "") : formatted;

  return prefix
    ? `${currencyCode}${numberPrefix}${stripped}`
    : `${numberPrefix}${stripped}`;
}
