import React, { useContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import styles from "./index.module.scss";
import { MotorContext } from "~/contexts/MotorProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import { useHistory } from "react-router-dom";
import useHasRequiredData from "~/helpers/useHasRequiredData";
import { useMutationThrowError } from "~/helpers/queryWithErrorHandling";
import { ENVIRONMENTS, POLICY_STATUS } from "~/helpers/constants";
import LoadingWrapper from "~/components/LoadingWrapper";
import { HomeContext } from "~/contexts/HomeProvider";
import { PolicyStatus } from "../../../types";

const hostedPageBaseURL = process.env.REACT_APP_FAC_HOSTED_PAGE_BASE_URL;
const hostedPageUpdateURL = process.env.REACT_APP_FAC_HOSTED_PAGE_UPDATE_URL;

const GET_POLICY = gql`
  query($getGeneralPolicyInput: String!) {
    getGeneralPolicy(input: $getGeneralPolicyInput) {
      status
    }
  }
`;

const REGISTER_PAYMENT_TRANSACTION = gql`
  mutation registerPaymentTransaction(
    $paymentAgreement: Boolean!
    $registerPaymentInput: PaymentTransactionRegisterInput!
  ) {
    registerPaymentTransaction(
      paymentAgreement: $paymentAgreement
      registerPaymentInput: $registerPaymentInput
    ) {
      securityToken
    }
  }
`;

type Props = {
  paymentResultUrl: string;
  nextPath?: string;
  onNext?: () => void;
  isPaymentUpdate?: boolean;
  updatePolicyId?: string;
  insuranceType?: string;
};

const loadHostedPaymentPageTimeoutMs = 20000;
const loadPaymentResultPageTimeoutMs = 30000;

export default function PaymentCheckout({
  paymentResultUrl,
  isPaymentUpdate,
  updatePolicyId,
  insuranceType,
}: Props) {
  const [
    registerPaymentTransaction,
    { data: registerData, loading: registerLoading },
  ] = useMutationThrowError(REGISTER_PAYMENT_TRANSACTION);

  const [securityToken, setSecurityToken] = useState<string>("");
  const motorCtx = useContext(MotorContext);
  const homeCtx = useContext(HomeContext);
  const onboardCtx = useOnboardContext();
  const history = useHistory();
  const defaultNumberOfRecurrences = 12;
  const [iframePageLastMessageType, setIframePageLastMessageType] = useState(
    ""
  );

  const policyId = !isPaymentUpdate
    ? insuranceType === "motor"
      ? motorCtx.policyInfo.data.id
      : homeCtx.policyId
    : updatePolicyId;

  const hostedPageURL = isPaymentUpdate
    ? hostedPageUpdateURL
    : hostedPageBaseURL;

  const isRecurring = !isPaymentUpdate ? onboardCtx.paymentIsRecurring : true;

  const numberOfRecurrences = !isPaymentUpdate
    ? onboardCtx.paymentNumberOfRecurrences
    : defaultNumberOfRecurrences;

  const isPaymentAgreement = isPaymentUpdate
    ? true
    : onboardCtx.paymentAgreement;

  const { data: policyData, loading: policyLoading } = useQuery(GET_POLICY, {
    variables: {
      getGeneralPolicyInput: policyId,
    },
    skip: !policyId,
    fetchPolicy: "no-cache",
  });

  const status = policyData?.getGeneralPolicy.status;

  useHasRequiredData({
    requiredData: [policyId],
    redirectUrl: "/portal",
    policyRequiredStatus: POLICY_STATUS.pendingPayment as PolicyStatus,
    policyStatus: status,
    skip: isPaymentUpdate || !status,
  });

  useEffect(() => {
    // Set up a message listener on mount and remove it on unmount
    const messageListener = (event: MessageEvent) => {
      const hostedPageOrigin = /^(https:\/\/[a-z]*.[a-z]*.[a-z]*)/.exec(
        hostedPageURL as string
      )?.[0];

      if (
        process.env.REACT_APP_ENV !== ENVIRONMENTS.local &&
        event.origin !== window.location.origin &&
        event.origin !== hostedPageOrigin
      ) {
        return;
      }

      // if messages are comming either from FAC HPP or from the payment result page,
      // store the message in local state
      if (
        event.data.messageType &&
        [
          "hpp-loaded",
          "hpp-submitted",
          "result-loaded",
          "result-clicked-next",
          "result-error",
          "page-loaded",
        ].includes(event.data.messageType)
      ) {
        setIframePageLastMessageType(event.data.messageType);
      }

      if (event.data.messageType === "page-loaded") {
        history.push(
          `${paymentResultUrl}?ID=${event.data.securityToken}&policy=${policyId}`
        );
      }
    };

    window.addEventListener("message", messageListener, false);

    return () => {
      window.removeEventListener("message", messageListener);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (policyId) {
      registerPaymentTransaction({
        variables: {
          paymentAgreement: isPaymentAgreement,
          registerPaymentInput: {
            policyId,
            isRecurring,
            numberOfRecurrences,
            isPaymentUpdate,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registerData?.registerPaymentTransaction?.securityToken) {
      setSecurityToken(registerData.registerPaymentTransaction.securityToken);
    }
  }, [registerData]);

  useEffect(() => {
    let iframePageLoadCheckInterval: NodeJS.Timeout;

    if (!iframePageLastMessageType) {
      iframePageLoadCheckInterval = setTimeout(() => {
        // if the hosted payment page has not been loaded or submitted after 20 secs
        if (
          iframePageLastMessageType !== "hpp-loaded" &&
          iframePageLastMessageType !== "hpp-submitted"
        ) {
          window.postMessage(
            {
              messageType: "checkout-error",
              error: {
                subtitle: "Error trying to load external payment page.",
              },
            },
            process.env.REACT_APP_ENV === "local" ? "*" : window.location.origin
          );
        }
      }, loadHostedPaymentPageTimeoutMs);
      /* if the hosted payment page form has been submitted, clear the
        previous timeout function and create a new one for the payment result page */
    } else if (iframePageLastMessageType === "hpp-submitted") {
      // @ts-ignore
      clearInterval(iframePageLoadCheckInterval);
      iframePageLoadCheckInterval = setTimeout(() => {
        // if the payment result page is loaded or clicked or errored out after 30 secs
        if (
          // @ts-ignore
          iframePageLastMessageType !== "result-loaded" &&
          // @ts-ignore
          iframePageLastMessageType !== "result-clicked-next" &&
          // @ts-ignore
          iframePageLastMessageType !== "result-error"
        ) {
          window.postMessage(
            {
              messageType: "checkout-error",
              error: {
                subtitle: "Error trying to load payment result page.",
              },
            },
            process.env.REACT_APP_ENV === "local" ? "*" : window.location.origin
          );
        }
      }, loadPaymentResultPageTimeoutMs);
    }

    return () => clearInterval(iframePageLoadCheckInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframePageLastMessageType]);

  return (
    <>
      <LoadingWrapper
        loading={registerLoading || !iframePageLastMessageType || policyLoading}
      />
      {securityToken && (
        <div className={styles.Wrapper}>
          <iframe
            className={styles.Iframe}
            title="FAC-Payment"
            src={`${hostedPageURL}${securityToken}`}
          />
        </div>
      )}
    </>
  );
}
