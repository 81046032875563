import * as storage from "~/helpers/storage";
import { FormValue } from "informed";

export type VehicleInfoInputs = {
  type?: string;
  make?: string;
  year?: number;
  model?: string;
  style?: string;
  value?: number;
  engineNumber?: string;
  chassisNumber?: string;
  isSportsCar?: boolean;
  isEngineModified?: "yes" | "no";
  isLeftSide?: "yes" | "no";
  isVehicleOwned?: "yes" | "no";
  engineSize?: number;
};

const defaultValue: VehicleInfoInputs = {
  type: undefined,
  make: undefined,
  year: undefined,
  model: undefined,
  style: undefined,
  value: undefined,
  engineNumber: undefined,
  chassisNumber: undefined,
  isSportsCar: undefined,
  isEngineModified: undefined,
  isLeftSide: undefined,
  isVehicleOwned: undefined,
  engineSize: undefined,
};

const keyStore = "vehicleInfo";

export const VEHICLE_INFO_DEFAULT_STATE = () => {
  let savedValue = storage.getItem(keyStore) as object;
  savedValue = !savedValue ? {} : savedValue;
  return {
    keyStore,
    data: { ...defaultValue, ...savedValue },

    setItem(key: string, value: FormValue) {
      this.data[key] = value;
      storage.saveItem(keyStore, this.data);
    },
    removeItem(key: string) {
      this.data[key] = undefined;
      storage.saveItem(keyStore, this.data);
    },
    removeStore() {
      this.data = { ...defaultValue };
      storage.removeItem(keyStore);
    },
  };
};
