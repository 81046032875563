import { useFormState } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import LoadingWrapper from "~/components/LoadingWrapper";
import TextInput from "~/components/TextInput";
import { H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./style.module.scss";

type Props = {
  nextPath?: string;
};

export default function PreviousProvider({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const formState = useFormState();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const fieldName = "previousProviderName";
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 5,
      title: "Previous Insurer",
    });
  });

  const handleOnNext = async () => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          previousProvider: (formState.values[fieldName] ?? "").toString(),
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>What was the name of your previous motor insurance provider?</H3>

          <Text className={styles.SubTitle}>
            If you don’t have a previous insurer, you can skip this step.
          </Text>

          <div className={styles.InsurerNameWrapper}>
            <TextInput
              field={fieldName}
              placeholder="Insurer Name"
              type="text"
              initialValue={checklistCtx.previousProvider ?? ""}
            />
          </div>
          <SubmitButton
            className={styles.ContinueButton}
            onClick={handleOnNext}
          >
            {!formState.values[fieldName] ? "Skip" : "Next"}
          </SubmitButton>
        </>
      )}
    </>
  );
}
