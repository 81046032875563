import React from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup } from "informed";

import styles from "./index.module.scss";

import { NO_COVERAGE_URL } from "~/routes/index.constant";
import { useHomeContext } from "~/contexts/HomeProvider";

import { H3 } from "~/components/Typography";

import RadioSelector from "~/components/RadioSelector";

import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

export default function RentOrOwn({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const fieldName = "occupancyType";
  const { value } = useCustomFieldState(fieldName);

  const handleOnNext = (value: FormValue) => {
    if (value === "rent") {
      homeCtx.setState({ isOwner: false });
      history.push(`${NO_COVERAGE_URL}?message=home-occupation-rent`);
      return;
    }
    if (nextPath) {
      homeCtx.setState({ isOwner: true });
      history.push(nextPath);
    }
  };

  return (
    <>
      <fieldset>
        <legend>
          <H3 component="h1">Do you rent or own this property?</H3>
        </legend>
        <div className={styles.RentOrOwnContent}>
          <RadioGroup field={fieldName} keepState>
            <RadioSelector
              key="Rent"
              field={fieldName}
              value="rent"
              checked={!homeCtx?.isOwner || value === "rent"}
              onChange={handleOnNext}
            >
              Rent
            </RadioSelector>
            <RadioSelector
              key="Own"
              field={fieldName}
              value="own"
              checked={homeCtx?.isOwner || value === "own"}
              onChange={handleOnNext}
            >
              Own
            </RadioSelector>
          </RadioGroup>
        </div>
      </fieldset>
    </>
  );
}
