import { SvgProps } from "./index.types";
export const DEFAULT_SIZE = 30;
export const DEFAULT_FILL_COLOR = "#23453A";
export const DEFAULT_BACKGROUND_COLOR = "#FDEDBF";

export const getSvgWrapperDefaultValues = ({
  className,
  width,
  height,
}: SvgProps) => ({
  viewBox: `0 0 ${width} ${height}`,
  version: "1.1",
  className,
  width,
  height,
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  fill: "none",
});
