import gql from "graphql-tag";
import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { Bold, H3, Text, TextSmall } from "~/components/Typography";
import { useUserContext } from "~/contexts/UserProvider";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { required } from "~/helpers/validators";
import styles from "./index.module.scss";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";

const SEND_VERIFICATION_EMAIL = gql`
  mutation sendVerificationEmail {
    sendVerificationEmail
  }
`;

const VERIFY_EMAIL_WITH_CODE = gql`
  mutation verifyEmailWithCode($verificationCode: String!) {
    verifyEmailWithCode(verificationCode: $verificationCode)
  }
`;

type Props = {
  nextPath?: string;
};

const VerificationCode = ({ nextPath }: Props) => {
  const userCtx = useUserContext();
  const fieldName = "verificationCode";
  const field = useCustomFieldState(fieldName);
  const history = useHistory();
  const apolloClient = useApolloClient();
  const assistantCtx = useAssistantContext();
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const fieldApi = useCustomFieldApi(fieldName);

  React.useEffect(() => {
    fieldApi.setError(undefined);
  }, [field.value, fieldApi]);

  const onVerifyClick = async () => {
    setLoading(true);

    try {
      const { data } = await apolloClient.mutate({
        mutation: VERIFY_EMAIL_WITH_CODE,
        variables: {
          verificationCode: field.value,
        },
      });

      if (data && nextPath) {
        history.push(nextPath);
      } else if (!data) {
        fieldApi.setError("Wrong code");
        setHasError(true);
      }
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length) {
        fieldApi.setError(error.graphQLErrors[0].message);
        setHasError(true);
      }
    } finally {
      setLoading(false);
      fieldApi.setTouched(true);
    }
  };

  const onResendCode = async () => {
    await apolloClient
      .mutate({
        mutation: SEND_VERIFICATION_EMAIL,
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        console.log("Resend code error: ", error);
      });
  };

  const defaultAssistantText = (
    <Text>
      If you didn’t receive a verification code,{" "}
      <Button text variant="textSmall" onClick={onResendCode}>
        send it again
      </Button>
      .
    </Text>
  );

  const errorAssistantText = (
    <Text>
      Make sure your email address is correct, or{" "}
      <Button text variant="textSmall" onClick={onResendCode}>
        resend code
      </Button>
      .
    </Text>
  );

  React.useEffect(() => {
    assistantCtx.updateAssistant({
      text: hasError ? errorAssistantText : defaultAssistantText,
      isOpen: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  return (
    <div className={styles.Content}>
      <TextSmall>
        A verification code has been sent to <br /> <Bold>{userCtx.email}</Bold>
      </TextSmall>

      <H3>Please enter the verification code</H3>

      <div className={styles.InputContainer}>
        <TextInput
          field={fieldName}
          placeholder="123456"
          keepState
          type="text"
          validate={(value) => {
            if (fieldApi.getError()) {
              return fieldApi.getError();
            }

            return required("Please enter the verification code")(value);
          }}
          validateOnBlur
          validateOnChange
          validateOnMount
          autoFocus
        />
      </div>

      <div className={styles.ButtonWrapper}>
        <Button
          disabled={loading || !field.value || !!fieldApi.getError()}
          onClick={onVerifyClick}
        >
          Verify
        </Button>
      </div>
    </div>
  );
};

export default VerificationCode;
