import { GeneralPolicyType, PolicyQuoteStatus } from "../types";
import { POLICY_COMPLETE_STATUS, POLICY_QUOTE_STATUS } from "./constants";
import getCountryCodeFromBranch from "./getCountryCodeFromBranch";

const insuranceMapping = {
  auto: "motor",
  home: "home",
};

const resumeRoute = (
  insuranceType: "auto" | "home",
  status?: PolicyQuoteStatus,
  typeOfCover?: string
) => {
  if (insuranceType === "auto") {
    switch (status) {
      case POLICY_QUOTE_STATUS.pendingEmailVerification: {
        return "verification";
      }

      case POLICY_QUOTE_STATUS.pendingEngineChassisNum: {
        return "engine-and-chassis";
      }

      case POLICY_QUOTE_STATUS.pendingOccupation: {
        return "occupation";
      }

      case POLICY_QUOTE_STATUS.pendingDocuments: {
        return "documents";
      }

      case POLICY_QUOTE_STATUS.pendingSigning: {
        return "documents-review";
      }

      case POLICY_QUOTE_STATUS.pendingPayment: {
        return "payment";
      }

      case POLICY_QUOTE_STATUS.quote: {
        if (typeOfCover) {
          return `coverage-plans?type=${typeOfCover}`;
        }
        return "coverage-plans";
      }

      default: {
        return "coverage-plans";
      }
    }
  } else if (insuranceType === "home") {
    switch (status) {
      case POLICY_QUOTE_STATUS.pendingEmailVerification: {
        return "verification";
      }

      case POLICY_QUOTE_STATUS.pendingDocuments: {
        return "documents";
      }

      case POLICY_QUOTE_STATUS.pendingSigning: {
        return "documents-review";
      }

      case POLICY_QUOTE_STATUS.pendingPayment: {
        return "payment";
      }

      case POLICY_QUOTE_STATUS.quote: {
        return "coverage-confirmation";
      }

      default: {
        return "coverage-confirmation";
      }
    }
  }
  return "quick-note";
};

const createResumeUrl = (policy: GeneralPolicyType, searchParams: string) => {
  const countryCode = getCountryCodeFromBranch(policy.branch);
  const insuranceType = insuranceMapping[policy.policyObject];
  if (
    !policy.status ||
    Object.values(POLICY_COMPLETE_STATUS).includes(policy.status)
  ) {
    return "/portal";
  }

  if (policy.policyObject !== "auto" && policy.policyObject !== "home") {
    return "/";
  }

  const resume = resumeRoute(
    policy.policyObject,
    policy.status,
    policy.typeOfCover
  );

  return `/${countryCode}/${insuranceType}/${resume}${searchParams}`;
};

export default createResumeUrl;
