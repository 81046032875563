import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_SIZE,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 25;
const logoHeight = 26;

const Minus = ({
  className = "minus-icon",
  fill = DEFAULT_FILL_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  width = DEFAULT_SIZE,
  height = DEFAULT_SIZE,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle cx="12.5" cy="13" r="12.5" fill={backgroundColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.01184 13.1804C7.01184 12.7202 7.38494 12.3471 7.84518 12.3471L17.666 12.3471C18.1263 12.3471 18.4994 12.7202 18.4994 13.1804C18.4994 13.6407 18.1263 14.0138 17.666 14.0138L7.84518 14.0138C7.38494 14.0138 7.01184 13.6407 7.01184 13.1804Z"
      fill={fill}
    />
  </svg>
);

export default Minus;
