import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { parse } from "query-string";
import { useAuth0Context } from "~/contexts/Auth0Context";
import GhostPortal from "~/components/GhostPortal";
import AppError from "~/helpers/AppError";

const LOADING_TIME = 1500;

const LoginCallback = () => {
  const history = useHistory();
  const auth0 = useAuth0Context();

  useEffect(() => {
    const hashParams = parse(history.location.hash);
    const queryParams = parse(history.location.search);

    if (queryParams.error && queryParams.error_description) {
      throw new AppError(`Login Error: ${queryParams.error_description}`);
    }
    if (
      hashParams.id_token &&
      typeof hashParams.id_token === "string" &&
      !auth0.token
    ) {
      auth0.handleLogin(history.location.hash);
      setTimeout(() => {
        if (
          queryParams.redirectTo &&
          typeof queryParams.redirectTo === "string"
        ) {
          return history.replace(queryParams.redirectTo);
        }
        return history.replace("/portal");
      }, LOADING_TIME);
    } else {
      const emptyCallbackRedirectTime = 500;
      setTimeout(() => {
        return history.replace("/portal");
      }, emptyCallbackRedirectTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GhostPortal />
    </>
  );
};

export default LoginCallback;
