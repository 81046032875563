import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ZendeskAPI } from "react-zendesk";

import { SupportData, useSupportContext } from "~/contexts/AlmiSupportProvider";

const wait = 300;
export function useUpdateSupport({
  isOpen = true,
  skip = false,
}: SupportData & { skip?: boolean }) {
  const { updateSupport } = useSupportContext();
  const history = useHistory();

  useEffect(() => {
    if (skip) {
      return () => {};
    }

    const unsubscribe = history.listen(() => {
      updateSupport({ isOpen: false });
      ZendeskAPI("webWidget", "hide");
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (!skip) {
      setTimeout(() => {
        updateSupport({ isOpen });
        ZendeskAPI("webWidget", "show");
      }, wait);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
}
