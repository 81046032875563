import { useFieldState } from "informed";
import React from "react";
import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { H3 } from "~/components/Typography";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";
import styles from "./index.module.scss";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import hasErrorCode from "~/helpers/hasErrorCode";
import Checkbox from "~/components/Checkbox";

enum Field {
  PolicyId = "AdminUpdatePolicyPremium-PolicyId",
  AnnualPremium = "AdminUpdatePolicyPremium-AnnualPremium",
  ExistingCC = "AdminUpdatePolicyPremium-ExistingCC",
  BccEmail = "AdminUpdatePolicyPremium-BccEmail",
}

const hostedPageUpdateURL = process.env.REACT_APP_FAC_HOSTED_PAGE_UPDATE_URL;

const UPDATE_POLICY_PREMIUM = gql`
  mutation adminUpdatePolicyPremium(
    $policyId: String!
    $annualPremium: Float!
    $useExistingCC: Boolean!
    $sendBccEmail: Boolean!
  ) {
    adminUpdatePolicyPremium(
      policyId: $policyId
      annualPremium: $annualPremium
      useExistingCC: $useExistingCC
      sendBccEmail: $sendBccEmail
    ) {
      refundAmount
      securityToken
      singleChargeAmount
      newMonthlyAmount
      newTransactionID
      remainingPaymentCount
    }
  }
`;

type Props = {
  onSuccess: (successMessage?: string) => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

const UpdatePolicyPremium = ({ onSuccess, onError }: Props) => {
  const policyIdState = useFieldState(Field.PolicyId);
  const annualPremiumState = useFieldState(Field.AnnualPremium);
  const existingCCState = useFieldState(Field.ExistingCC);
  const bccEmailState = useFieldState(Field.BccEmail);
  const [securityToken, setSecurityToken] = React.useState<string>();
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [updatePolicyPremium] = useMutation(UPDATE_POLICY_PREMIUM);

  const handleSubmit = async () => {
    setProcessing(true);
    onSuccess(undefined);
    onError(undefined);

    try {
      const response = await updatePolicyPremium({
        variables: {
          policyId: String(policyIdState.value),
          annualPremium: cleanNumberInput(annualPremiumState.value),
          useExistingCC: !!existingCCState.value,
          sendBccEmail: !!bccEmailState.value,
        },
      });

      setProcessing(false);

      const data = response?.data?.adminUpdatePolicyPremium;

      if (data.securityToken) {
        setSecurityToken(data.securityToken);
      } else if (data.refundAmount) {
        onSuccess(`Please issue user a refund for $${data.refundAmount}.`);
      } else if (data.singleChargeAmount) {
        onSuccess(
          `User has been charged the amount of $${data.singleChargeAmount}.`
        );
      } else if (data.newMonthlyAmount) {
        onSuccess(
          `User's monthly payment has been updated to $${data.newMonthlyAmount}.`
        );
      } else {
        onSuccess("Operation Successful!");
      }
    } catch (error) {
      onError(
        error.message,
        hasErrorCode(error, "NOT_ADMIN") ? "NOT_ADMIN" : undefined
      );

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      <H3>Update Policy Premium</H3>

      {!securityToken ? (
        <>
          <TextInput
            type="text"
            label="Policy ID"
            placeholder="AL-000000-AAAAAA"
            field={Field.PolicyId}
          />

          <TextInput
            type="currency"
            label="New Annual Premium"
            placeholder="$ 0.00"
            field={Field.AnnualPremium}
          />

          <Checkbox
            field={Field.ExistingCC}
            label="Attempt to Use Credit Card on File"
            initialValue
          />

          <Checkbox
            className={styles.SmallMarginTop}
            field={Field.BccEmail}
            label="Send Me A Copy of The Change Email"
            initialValue
          />

          <Button
            disabled={
              !policyIdState.value || !annualPremiumState.value || processing
            }
            onClick={handleSubmit}
          >
            {processing ? "Processing..." : "Process"}
          </Button>
        </>
      ) : undefined}

      {securityToken ? (
        <div className={styles.Wrapper}>
          <iframe
            className={styles.Iframe}
            title="FAC-Payment"
            src={`${hostedPageUpdateURL}${securityToken}`}
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default UpdatePolicyPremium;
