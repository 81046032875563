import React, { ReactNode, useEffect } from "react";
import * as storage from "~/helpers/storage";

export type IOnboardData = {
  country?: "BRB" | "GUY";
  insuranceType?: "motor" | "home";
  paymentIsRecurring?: boolean;
  paymentNumberOfRecurrences?: number;
  paymentAgreement?: boolean;
};

export type IOnboardContext = {
  setState: (newState?: IOnboardData) => void;
  removeStore: () => void;
} & IOnboardData;

const keyStore = "onboardInfo";

const initialState = () => {
  let savedValue = storage.getItem(keyStore) as IOnboardData;
  savedValue = !savedValue ? {} : savedValue;

  /**
   *  For the payment screen,it's necessary save in the localStorage
   *  for the case of the user refresh the page. For now, is not necessary to save
   *  the country or insuranceType because that will come from the url
   */

  const paymentIsRecurring = savedValue?.paymentIsRecurring;
  const paymentNumberOfRecurrences = savedValue?.paymentNumberOfRecurrences;
  const paymentAgreement = savedValue?.paymentAgreement;
  const DEFAULT_NUMBER_OF_RECURRENCES = 12;
  return {
    country: "BRB",
    insuranceType: undefined,
    paymentIsRecurring:
      typeof paymentIsRecurring !== "boolean" ? true : paymentIsRecurring,
    paymentNumberOfRecurrences:
      typeof paymentNumberOfRecurrences !== "number"
        ? DEFAULT_NUMBER_OF_RECURRENCES
        : paymentNumberOfRecurrences,
    paymentAgreement:
      typeof paymentAgreement !== "boolean" ? false : paymentAgreement,
    setState() {
      throw new Error("OnboardProvider context has not yet been initialized.");
    },
    removeStore() {
      throw new Error("OnboardProvider context has not yet been initialized.");
    },
  };
};

export const OnboardContext = React.createContext(
  initialState() as IOnboardContext
);
export const useOnboardContext = () => React.useContext(OnboardContext);

type Props = {
  children: ReactNode;
};

export default function OnboardProvider({ children }: Props) {
  const [state, setState] = React.useState(initialState() as IOnboardContext);
  const handleNewState = (newValue?: IOnboardData) => {
    if (newValue) {
      const newState = { ...state, ...newValue };
      storage.saveItem(keyStore, newState);
      return setState(newState);
    }
  };

  useEffect(() => {
    setState(initialState() as IOnboardContext);
  }, []);

  const handleRemoveStore = () => {
    storage.removeItem(keyStore);
  };
  return (
    <OnboardContext.Provider
      value={{
        ...state,
        setState: handleNewState,
        removeStore: handleRemoveStore,
      }}
    >
      {children}
    </OnboardContext.Provider>
  );
}
