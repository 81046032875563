import React from "react";
import { Route, Switch } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import UserProvider from "~/contexts/UserProvider";
import MotorProvider from "~/contexts/MotorProvider";
import HomeProvider from "~/contexts/HomeProvider";
import OnboardProvider from "~/contexts/OnboardProvider";
import LoginCallback from "~/screens/LoginCallback";
import PortalVerify from "~/screens/PortalVerify";
import AuthorizedRoute from "~/components/AuthorizedRoute";
import Portal from "~/screens/Portal";

export default () => {
  return (
    <UserProvider>
      <MotorProvider>
        <HomeProvider>
          <OnboardProvider>
            <HelmetProvider>
              <Switch>
                <Route exact path={"/portal/login"} component={LoginCallback} />
                <Route exact path={"/portal/verify"} component={PortalVerify} />
                <AuthorizedRoute path="/portal">
                  <Portal />
                </AuthorizedRoute>
              </Switch>
            </HelmetProvider>
          </OnboardProvider>
        </HomeProvider>
      </MotorProvider>
    </UserProvider>
  );
};
