import React, { useEffect, useMemo } from "react";
import classnames from "classnames";
import { useHistory, matchPath } from "react-router-dom";
import { Select, Option } from "informed";
import { useUserContext } from "~/contexts/UserProvider";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import { Text, H3 } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import SubmitButton from "~/components/SubmitButton";
import { CountryCode, InsuranceType } from "../../../types";
import { countryCodesData, findCountryByAlpha3 } from "~/helpers/countryCodes";
import { LOCAL_PHONE_CODES, CARIB_PHONE_CODES } from "~/helpers/constants";
// eslint-disable-next-line
import "flag-icon-css/css/flag-icon.css";
import styles from "./index.module.scss";
import pushWithParams from "~/helpers/pushWithParams";
import { phoneRequired } from "~/helpers/validators";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";
// import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
// import { useMotorContext } from "~/contexts/MotorProvider";

const QuotePhone = ({ nextPath }: { nextPath?: string }) => {
  const userCtx = useUserContext();
  const history = useHistory();
  const assistantCtx = useAssistantContext();
  // const heapCtx = useHeapContext();
  // const motorCtx = useMotorContext();
  const countryCodeFieldName = "countryCodeField";
  const phoneFieldName = "phoneNumberField";
  const countryCodeFieldState = useCustomFieldState(countryCodeFieldName);
  const phoneFieldState = useCustomFieldState(phoneFieldName);
  const phoneApi = useCustomFieldApi(phoneFieldName);
  const { value } = useCustomFieldState(phoneFieldName);
  const phoneRequiredError = "Please enter a valid phone number";

  const [selectedCountry, setSelectedCountry] = React.useState<{
    name: string;
    alpha2: string;
    alpha3: string;
    diallingCode: string;
  }>({ name: "", alpha2: "", alpha3: "", diallingCode: "" });

  const pathMatch: {
    params: { countryCode: CountryCode; insuranceType: InsuranceType };
  } | null = matchPath(history.location.pathname, {
    path: "/:countryCode/:insuranceType/:step",
    exact: true,
    strict: false,
  });

  const countryCodeInitialValue = useMemo(() => {
    if (userCtx.countryPhoneCode) {
      const contextCountryCode = findCountryByAlpha3(userCtx.countryPhoneCode);

      if (contextCountryCode) {
        return contextCountryCode.alpha3;
      }
    }

    if (pathMatch) {
      const paramCountryCode = findCountryByAlpha3(
        pathMatch.params.countryCode.toUpperCase()
      );

      if (paramCountryCode) {
        return paramCountryCode.alpha3;
      }
    }
    return undefined;
  }, [userCtx.countryPhoneCode, pathMatch]);

  useEffect(() => {
    if (assistantCtx.isOpen) {
      assistantCtx.updateAssistant({
        isOpen: false,
      });
    }
  }, [assistantCtx]);

  const handleClick = () => {
    if (phoneFieldState.value && countryCodeFieldState.value) {
      const val = phoneFieldState.value?.toString().replace(/[^\d]/g, "");
      const phoneReqFunction = phoneRequired(
        phoneRequiredError,
        selectedCountry.diallingCode
      );

      if (phoneReqFunction(val) !== undefined) {
        phoneApi.setError(phoneRequiredError);
        return;
      }

      userCtx.setState({
        phoneNumber: `${val}`,
        diallingCode: findCountryByAlpha3(countryCodeFieldState.value)
          ?.diallingCode,
        countryPhoneCode: countryCodeFieldState.value as string,
      });

      if (nextPath) {
        pushWithParams(history, nextPath);
      }
    }

    if (nextPath) {
      pushWithParams(history, nextPath);
    }
  };

  useEffect(() => {
    const code = findCountryByAlpha3(countryCodeFieldState.value);

    if (code !== undefined) {
      setSelectedCountry(code);
    }
  }, [countryCodeFieldState.value]);

  useEffect(() => {
    const phoneReqFunction = phoneRequired(
      phoneRequiredError,
      selectedCountry.diallingCode
    );

    if (phoneReqFunction(phoneFieldState.value) !== undefined) {
      phoneApi.setError(phoneRequiredError);
    } else {
      phoneApi.setError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry.diallingCode]);

  const label = "What's your phone number?";

  return (
    <>
      <Text>Great! Almost done</Text>
      <H3 className={styles.Subtitle} component="h1">
        {label}
      </H3>

      <div className={styles.InputWrapper}>
        <div className={styles.dropdownWrapper}>
          <span
            className={classnames(
              styles.flag,
              `flag-icon-${selectedCountry?.alpha2.toLowerCase()}`,
              {
                "flag-icon": selectedCountry,
              }
            )}
          />

          <label
            htmlFor="countryPhoneCode"
            className={styles.dropdownLabel}
            aria-label="Please select your country dialling code"
          >
            <Select
              className={classnames(styles.dropdown)}
              field={countryCodeFieldName}
              initialValue={countryCodeInitialValue}
              data-descr={`+${
                findCountryByAlpha3(countryCodeFieldState.value)?.diallingCode
              }`}
            >
              <optgroup label="Local">
                {countryCodesData
                  .filter((country) =>
                    LOCAL_PHONE_CODES.includes(country.alpha3)
                  )
                  .map((country) => (
                    <Option key={country.alpha2} value={country.alpha3}>
                      {`${country.alpha3} +${country.diallingCode}`}
                    </Option>
                  ))}
              </optgroup>

              <optgroup label="Caribbean">
                {countryCodesData
                  .filter((country) =>
                    CARIB_PHONE_CODES.includes(country.alpha3)
                  )
                  .map((country) => (
                    <Option key={country.alpha2} value={country.alpha3}>
                      {`${country.alpha3} +${country.diallingCode}`}
                    </Option>
                  ))}
              </optgroup>

              <optgroup label="Rest of the world">
                {countryCodesData
                  .filter(
                    (country) =>
                      ![...CARIB_PHONE_CODES, ...LOCAL_PHONE_CODES].includes(
                        country.alpha3
                      )
                  )
                  .map((country) => (
                    <Option key={country.alpha2} value={country.alpha3}>
                      {`${country.alpha3} +${country.diallingCode}`}
                    </Option>
                  ))}
              </optgroup>
            </Select>
          </label>
        </div>

        <TextInput
          label={label}
          hideLabelFromView
          field={phoneFieldName}
          placeholder="000-0000"
          keepState
          validate={phoneRequired(
            phoneRequiredError,
            selectedCountry.diallingCode
          )}
          initialValue={userCtx.phoneNumber?.toString()}
          validateOnBlur
          validateOnMount
          validateOnChange
          type="phone"
          autoComplete="tel-national"
          autoFocus
          inputMode="numeric"
        />
      </div>

      <div className={styles.ButtonWrapper}>
        <SubmitButton className={styles.Button} onClick={handleClick}>
          {!value ? "Skip" : "Next"}
        </SubmitButton>
      </div>
    </>
  );
};

export default QuotePhone;
