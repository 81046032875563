import React from "react";
import styles from "./index.module.scss";

type PropsType = {
  stepsLength: number;
  step: number;
};

const calculateCurrentStepPercentages = (stepsLength: number, step: number) => {
  const oneHundred = 100;
  const filled = (step / stepsLength) * oneHundred;
  return `${filled.toFixed()}%`;
};

const ProgressBar = ({ stepsLength, step }: PropsType) => {
  if (step > stepsLength) {
    console.warn(
      `[WARNINNG]: In the progress bar component, the current step number (>>>${step}<<<),
      should it not be greater than the total of steps (>>>${stepsLength}<<<)`
    );
  }
  const computedStep = step > stepsLength ? stepsLength : step;
  const filledPercentage = calculateCurrentStepPercentages(
    stepsLength,
    computedStep
  );

  return (
    <div
      role="progressbar"
      className={styles.ProgressBar}
      aria-label={`progress bar, ${filledPercentage}`}
      aria-valuemin={1}
      aria-valuemax={stepsLength}
      aria-valuenow={computedStep + 1}
      aria-valuetext={`step ${computedStep + 1} out of ${stepsLength}`}
    >
      <div
        className={styles.ProgressBarFilled}
        style={{ width: filledPercentage }}
      />
    </div>
  );
};

export default ProgressBar;
