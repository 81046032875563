import React from "react";
import { H4, TextSmall } from "~/components/Typography";
import CheckImg from "~/assets/images/check.png";
import DownloadLink from "~/components/DownloadLink";
import { PolicyDocumentType } from "../../types";
import styles from "./index.module.scss";

type PropsType = {
  documents: PolicyDocumentType[];
};

const getTitles = (type: string) => {
  switch (type) {
    case "POLICY_SCHEDULE":
      return {
        title: "Policy Schedule",
        subtitle: "Specific terms of coverage",
      };
    case "COVER_NOTE":
      return {
        title: "Cover Note",
        subtitle: "Your proof of coverage",
      };
    default:
      return { title: "Document", subtitle: "" };
  }
};

const PortalPolicyDocuments = ({ documents }: PropsType) => {
  return (
    <div
      className={styles.Document}
      role="group"
      aria-labelledby="policy-documents-title"
    >
      <div className={styles.Icon}>
        <img src={CheckImg} alt="" className={styles.CheckImg} />
      </div>

      <div className={styles.Heading}>
        <TextSmall>Documents</TextSmall>
        <H4 component="h3" id="policy-documents-title">
          Policy Documents
        </H4>
      </div>

      <div className={styles.Content}>
        {documents.map((doc, idx) => {
          const titles = getTitles(doc.documentType);
          return (
            <div className={styles.List} key={idx}>
              <div className={styles.ListTitle}>
                <TextSmall fontWeight="bold">{titles.title}</TextSmall>
                {titles.subtitle && <TextSmall>{titles.subtitle}</TextSmall>}
              </div>

              <div className={styles.ListAction}>
                <DownloadLink name="Download" url={doc.url} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PortalPolicyDocuments;
