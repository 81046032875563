import React from "react";

import {
  H4,
  H5,
  H6,
  TextSmall,
  TextExtraSmall,
  Text,
} from "~/components/Typography";
import Button from "~/components/Button";
import ButtonIcon from "~/components/ButtonIcon";
import GridWrapper from "~/components/GridWrapper";

import Modal from "~/components/Modal";
import PolicyDetailsCard from "~/components/PolicyDetailsCard";
import type { DetailData } from "~/components/PolicyDetailsCard";

import styles from "./index.module.scss";
import { InstallmentValue } from "../InstallmentValue";

export type SummaryValue = {
  title: string;
  value: string;
  excess?: string;
};

export type Props = {
  title: string;
  subTitle: string;
  summary: string | React.ReactNode;
  summaryTitle?: string;
  summaryValues: SummaryValue[];
  coveredContent: DetailData[];
  notCoveredContent: DetailData[];
  installmentCount: number;
  installmentValue: number;
  isOpen: boolean;
  showContinue?: boolean;
  showPrefix?: boolean;
  onSelectPlan?: () => void;
  onCloseModal: () => void;
  onPrev?: () => void;
  onNext?: () => void;
};

export default function PlanDetail(props: Props) {
  const {
    title,
    subTitle,
    summary,
    summaryTitle,
    summaryValues,
    coveredContent,
    notCoveredContent,
    installmentCount,
    installmentValue,
    isOpen = false,
    onCloseModal,
    onSelectPlan,
    onPrev,
    onNext,
    showContinue = true,
    showPrefix = false,
  } = props;
  const modalId = "plan-details-modal";
  return (
    <Modal
      isOpen={isOpen}
      shouldOverlayCloseOnClick
      onClose={onCloseModal}
      className={styles.ModalPlanDetail}
      id={modalId}
      hasDescription
      renderHeader={
        <div className={styles.HeaderDetail}>
          <div className={styles.HeaderDetailLeft}>
            <H4 component="h3">
              <span id={`modal-title-${modalId}`}>{title}</span>
            </H4>
            <TextSmall>
              <span id={`modal-desc-${modalId}`}>{subTitle}</span>
            </TextSmall>
          </div>
          <div className={styles.HeaderDetailRight}>
            <InstallmentValue
              installmentCount={installmentCount}
              installmentValue={installmentValue}
              showPrefix={showPrefix}
              large
            />
            {showContinue && (
              <Button
                className={styles.BtnContinueWithPlan}
                size="small"
                onClick={onSelectPlan}
              >
                Purchase this policy
              </Button>
            )}
          </div>
          <div className={styles.CloseIcon}>
            <ButtonIcon
              className={styles.CloseIconButton}
              iconName="ArrowDown"
              onClick={onCloseModal}
              ariaLabel="close dialog"
            />
          </div>
        </div>
      }
    >
      <GridWrapper
        containerClassName={styles.ModalGridContent}
        renderBeforeGridContent={
          <>
            {onPrev && (
              <ButtonIcon
                iconName="ArrowLeft"
                className={styles.ArrowLeft}
                onClick={onPrev}
                ariaLabel="previous plan"
              />
            )}
            {onNext && (
              <ButtonIcon
                iconName="ArrowRight"
                className={styles.ArrowRight}
                onClick={onNext}
                ariaLabel="next plan"
              />
            )}
          </>
        }
      >
        <div className={styles.PlanSummary}>
          <div className={styles.PlanSummaryText}>
            <H5 className={styles.TitleSpace} component="h4">
              {summaryTitle ?? "Summary"}
            </H5>
          </div>
          <div>
            {typeof summary === "string" ? <Text>{summary}</Text> : summary}
          </div>

          <div className={styles.PlanSummaryValues}>
            {summaryValues.map((e) => (
              <div key={e.title} className={styles.PlanSummaryValueWrapper}>
                <H6 className={styles.TitleSpace}>{e.title}</H6>
                <H4 component="h6">{e.value}</H4>
                {e.excess && (
                  <TextExtraSmall>Excess: {e.excess}</TextExtraSmall>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.PlanCardDetailsWrapper}>
          {coveredContent.length !== 0 && (
            <PolicyDetailsCard body={coveredContent} />
          )}
          {notCoveredContent.length !== 0 && (
            <div className={styles.PlanCardDetails}>
              <PolicyDetailsCard body={notCoveredContent} isCovered={false} />
            </div>
          )}
        </div>
      </GridWrapper>
    </Modal>
  );
}
