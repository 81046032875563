import React from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup } from "informed";

import styles from "./index.module.scss";

import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHomeContext } from "~/contexts/HomeProvider";

import { H3, TextSmall } from "~/components/Typography";
import RadioSelector from "~/components/RadioSelector";

export default function CoastalProperty({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const fieldName = "isCoastal";
  const { value } = useCustomFieldState(fieldName);

  useUpdateAssistant({
    text: (
      <TextSmall>
        If your property is less than 200m from the coast it is considered a
        coastal property.
      </TextSmall>
    ),
  });

  const handleOnNext = (value: FormValue) => {
    if (nextPath) {
      homeCtx.setState({ isCoastal: !!(value === "yes") });
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1">Is your home a beachfront or coastal property?</H3>
      </legend>
      <div className={styles.CoastalPropertyContent}>
        <RadioGroup field={fieldName} keepState>
          <RadioSelector
            key="yes"
            field={fieldName}
            value="yes"
            checked={homeCtx?.isCoastal || value === "yes"}
            onChange={handleOnNext}
          >
            Yes
          </RadioSelector>
          <RadioSelector
            key="No"
            field={fieldName}
            value="no"
            checked={!homeCtx?.isCoastal || value === "no"}
            onChange={handleOnNext}
          >
            No
          </RadioSelector>
        </RadioGroup>
      </div>
    </fieldset>
  );
}
