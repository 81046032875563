import React from "react";
import CheckImg from "~/assets/images/check.png";
import styles from "./index.module.scss";
import classNames from "classnames";

type PropsType = {
  children: React.ReactNode;
  iconImg?: React.ReactNode;
  iconSrc?: string;
  color?: "gold" | "everestGreen" | "coral";
  className?: string;
  groupDesc?: string;
};

const InfoCardWrapper = ({
  children,
  iconImg,
  iconSrc = CheckImg,
  color = "gold",
  className,
  groupDesc,
}: PropsType) => {
  return (
    <div
      className={classNames(className, styles.Wrapper, styles[color])}
      role="group"
      aria-label={groupDesc}
    >
      <div className={styles.Icon}>
        {iconImg ? iconImg : <img src={iconSrc} alt="" />}
      </div>
      {children}
    </div>
  );
};

export default InfoCardWrapper;
