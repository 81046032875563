import { ReactNode } from "react";
import { ButtonOptions } from "~/components/Error";
import { AssistantData } from "~/contexts/AlmiAssistantProvider";

class AppError extends Error {
  subtitle: string;

  options?: {
    title?: string;
    image?: ReactNode;
    assistantMessage?: AssistantData;
    mainButton?: ButtonOptions;
    secondaryButton?: ButtonOptions;
  };

  constructor(
    subtitle: string,
    options?: {
      title?: string;
      image?: ReactNode;
      assistantMessage?: AssistantData;
      mainButton?: ButtonOptions;
      secondaryButton?: ButtonOptions;
    }
  ) {
    super(subtitle);
    this.name = "AppError";
    this.subtitle = subtitle;
    this.options = options;
  }
}

export default AppError;
