import React from "react";
import { H3, PreText } from "~/components/Typography";
import Button from "~/components/Button";
import { useHistory, useLocation } from "react-router-dom";

import styles from "./index.module.scss";

const GoneTooFar = () => {
  const history = useHistory();
  const params = useLocation();

  const query = new URLSearchParams(params.search);

  const message = query.get("message");

  return (
    <div className={styles.Wrapper}>
      <H3 className={styles.Title} component="h1">
        Uh Oh, it appears you have gone too far...
      </H3>
      <PreText className={styles.Subtitle}>
        {message === "no-policy"
          ? "Please resume from your quote email or start a new quote to continue."
          : "Please start a new quote to continue."}
      </PreText>
      <Button
        className={styles.Button}
        onClick={() => {
          history.push("/");
        }}
      >
        Start a new quote
      </Button>
      <br />
    </div>
  );
};

export default GoneTooFar;
