import React from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
};

const getTrackingID = (): string => {
  return process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? "";
};

type GoogleAnalyticsContext = {
  trackingID: string;
  triedInitializing: boolean;
  isInitialized: boolean;
  currentPage: string;
  testMode: boolean;
};

const initialState: GoogleAnalyticsContext = {
  trackingID: "",
  triedInitializing: false,
  isInitialized: false,
  currentPage: "",
  testMode: false,
};

const GoogleAnalyticsContext = React.createContext(initialState);

export const useGoogleAnalyticsContext = () =>
  React.useContext(GoogleAnalyticsContext);

export default function GoogleAnalyticsProvider({ children }: Props) {
  const [state, setState] = React.useState<GoogleAnalyticsContext>(
    initialState
  );

  const location = useLocation();

  React.useEffect(() => {
    if (!state.triedInitializing) {
      const trackingID = getTrackingID();
      const isProduction = process.env.NODE_ENV === "production";
      let isTest = false;

      if (trackingID) {
        if (isProduction) {
          ReactGA.initialize(trackingID);
        } else {
          isTest = true;

          ReactGA.initialize(trackingID, {
            testMode: true,
          });
        }

        setState({
          ...state,
          trackingID,
          isInitialized: true,
          triedInitializing: true,
          testMode: isTest,
        });
      } else {
        console.warn("Google Analytics has not been initialized.");

        setState({
          ...state,
          triedInitializing: true,
        });
      }
    }
  }, [state]);

  React.useEffect(() => {
    if (state.isInitialized) {
      const url: string = location.pathname;

      if (url !== state.currentPage) {
        ReactGA.set({
          page: url,
        });

        ReactGA.pageview(url);

        if (state.testMode) {
          console.log(
            "Google Analytics Test PageView: ",
            ReactGA.testModeAPI.calls[ReactGA.testModeAPI.calls.length - 1]
          );
        }

        setState({
          ...state,
          currentPage: url,
        });
      }
    }
  }, [location, state]);

  return (
    <GoogleAnalyticsContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </GoogleAnalyticsContext.Provider>
  );
}
