import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import imgContentsCoverage from "~/assets/images/contents-coverage.png";

import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { H3, TextSmall } from "~/components/Typography";
import Button from "~/components/Button";
import { useHomeContext } from "~/contexts/HomeProvider";

export default function ContentsCoverage({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();

  useUpdateAssistant({
    text: (
      <TextSmall>
        This will protect your belongings in case of theft, loss, or damage.
      </TextSmall>
    ),
  });

  const handleOnYes = () => {
    if (nextPath) {
      history.push(nextPath);
    }
  };

  const handleOnNo = () => {
    homeCtx.setState({
      contentsValue: 0,
      contents: undefined,
      allRisk: false,
      allRiskType: undefined,
    });

    history.push("/BRB/home/insurance-review");
  };

  return (
    <>
      <H3 component="h1">
        Would you like to insure the contents in your home?
      </H3>
      <div className={styles.ImageWrapper}>
        <img
          src={imgContentsCoverage}
          alt="Contents Coverage"
          className={styles.Image}
        />
      </div>
      <div className={styles.ButtonsRow}>
        <Button className={styles.Button} onClick={handleOnYes}>
          Yes, please
        </Button>
        <Button
          className={styles.Button}
          text
          variant="text"
          autoFocus
          onClick={handleOnNo}
        >
          No, thanks
        </Button>
      </div>
    </>
  );
}
