import React from "react";
import classnames from "classnames";
import getDisplayStatus from "~/helpers/getDisplayStatus";
import getStatusColor from "~/helpers/getStatusColor";
import styles from "./index.module.scss";

type PropsType = {
  status: string;
};

export default function Status({ status }: PropsType) {
  return (
    <span className={classnames(styles.Status, styles[getStatusColor(status)])}>
      {getDisplayStatus(status)}
    </span>
  );
}
