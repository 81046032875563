import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const size = 30;

const MagnifyingGlassIcon = ({
  className = "magnifying-glass-icon",
  fill = DEFAULT_FILL_COLOR,
  width = size,
  height = size,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    fill="none"
    role="img"
    aria-hidden
  >
    <circle cx="15" cy="15" r="15" fill="#FDEDBF" />
    <path
      d="M10 14.4004C10 11.9701 11.9701 10 14.4004 10C16.8307 10 18.8008 11.9701 18.8008 14.4004C18.8008 15.5781 18.3381 16.6478 17.5846 17.4376C17.558 17.4592 17.5321 17.4825 17.5073 17.5073C17.4825 17.5322 17.4592 17.558 17.4376 17.5846C16.6478 18.3381 15.5781 18.8008 14.4004 18.8008C11.9701 18.8008 10 16.8307 10 14.4004ZM18.1638 19.578C17.1076 20.3471 15.807 20.8008 14.4004 20.8008C10.8656 20.8008 8 17.9353 8 14.4004C8 10.8656 10.8656 8 14.4004 8C17.9353 8 20.8008 10.8656 20.8008 14.4004C20.8008 15.807 20.3471 17.1076 19.578 18.1638L21.858 20.4438C22.2485 20.8343 22.2485 21.4675 21.858 21.858C21.4675 22.2485 20.8343 22.2485 20.4438 21.858L18.1638 19.578Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
    />
  </svg>
);

export default MagnifyingGlassIcon;
