import { useState, useEffect } from "react";
import { useFieldState, FormValue, FieldState } from "informed";

export function useCustomFieldState<VinputType = FormValue>(
  field: string
): FieldState<VinputType> {
  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  if (isInitialRender) {
    const originalWarn = console.warn;
    console.warn = (message: string) => {
      if (
        !(typeof message === "string" && message.includes("Attempting to get"))
      )
        originalWarn(message);
    };
  }
  const fieldState = useFieldState<VinputType>(field);

  return fieldState;
}
