/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import gql from "graphql-tag";
import { H3, Text, Bold, TextSmall } from "../Typography";
//import GridWrapper from "~/components/GridWrapper";
import MobileUpload from "~/assets/images/upload-mobile.png";
import styles from "./index.module.scss";
import Button from "~/components/Button";
import { useQueryThrowError } from "~/helpers/queryWithErrorHandling";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";

const SEND_RESUME_EMAIL = gql`
  query sendResumeEmail($externalId: String!) {
    sendResumeEmail(externalId: $externalId)
  }
`;

const MobileUploadRequired = ({
  title,
  email,
  policyId,
}: {
  title: string;
  email: string;
  policyId: string;
}) => {
  const hasEmailBeenSent = localStorage.getItem("resumeEmailSent")
    ? localStorage.getItem("resumeEmailSent")?.split(",").includes(policyId)
    : false;

  const setSent = () => {
    const sentItems = localStorage.getItem("resumeEmailSent");
    if (sentItems && policyId) {
      const ids = sentItems.split(",");
      if (policyId && !ids.includes(policyId)) {
        ids.push(policyId);
        localStorage.setItem("resumeEmailSent", ids.join(","));
      }
    } else {
      localStorage.setItem("resumeEmailSent", policyId);
    }
  };

  const { refetch } = useQueryThrowError(SEND_RESUME_EMAIL, {
    variables: {
      externalId: policyId,
    },
    skip: !policyId || hasEmailBeenSent,
    onCompleted: () => setSent(),
  });

  const assistant = useAssistantContext();

  const reSendEmail = async () => {
    try {
      assistant.updateAssistant({
        isOpen: true,
        text: <TextSmall>Sending...</TextSmall>,
      });
      const success = await refetch().then((res) => {
        const result = res.data.sendResumeEmail;
        return result;
      });

      if (!success) throw new Error("returned false");

      assistant.updateAssistant({
        isOpen: true,
        text: (
          <TextSmall>
            Email has been re-sent. Please check your inbox.
          </TextSmall>
        ),
      });
    } catch (error) {
      assistant.updateAssistant({
        isOpen: true,
        text: (
          <TextSmall>
            There has been an error sending your email. Please try again.
          </TextSmall>
        ),
      });
    }
  };

  return (
    <>
      <H3 component="h1">{title}</H3>
      <Text className={styles.Text}>
        This will help us verify who you are and ensure your information is
        secure!
      </Text>
      <img
        className={styles.image}
        src={MobileUpload}
        alt="upload on mobile illustration"
      />
      <Text className={styles.bottomText}>
        We’ve sent you an email at <Bold>{email}</Bold> with your quote. You can
        open the email on your phone and proceed from there.
      </Text>
      <TextSmall>
        If you didn&apos;t receive this email,{" "}
        <Button type="button" text variant="text" onClick={reSendEmail}>
          <span className={styles.Link}>send it again.</span>
        </Button>
      </TextSmall>
    </>
  );
};

export default MobileUploadRequired;
