import React from "react";
import classnames from "classnames";

import styles from "./index.module.scss";
import { TextSmall } from "~/components/Typography";

type Props = {
  title?: string;
  currentStep?: number;
  totalOfSteps?: number;
  className?: string;
};

export default function StepTitle(props: Props) {
  const { title, className } = props;
  return (
    <div className={classnames(styles.StepTitleWrapper, className)}>
      <TextSmall className={styles.Title}>{title}</TextSmall>
    </div>
  );
}
