import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import { getSvgWrapperDefaultValues } from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;
const CheckRound = ({
  className = "check-icon",
  width = logoWidth,
  height = logoHeight,
  backgroundColor = "#FDEDBF",
  fill = "#23453A",
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
    role="img"
    aria-label="check"
  >
    <circle cx="15" cy="15" r="15" fill={backgroundColor} />
    <path
      d="M21.1765 10.5882L13.0832 19.4118L8.82353 14.7678"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckRound;
