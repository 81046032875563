import React from "react";

import { Props } from "./index.types";

import * as icons from "./icons";
export default function Icon(props: Props) {
  const { name, ...rest } = props;
  const IconComponent = name ? icons[name] : () => null;
  return <IconComponent {...rest} />;
}
