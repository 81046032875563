import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;

const Question = ({
  className = "question-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle cx="15" cy="15" r="15" fill="#FDEDBF" />
    <path
      d="M11.672 11.2444C11.9272 10.519 12.4309 9.90727 13.0938 9.51763C13.7568 9.128 14.5363 8.98557 15.2942 9.11557C16.0522 9.24558 16.7396 9.63963 17.2349 10.2279C17.7301 10.8162 18.0011 11.5608 18 12.3298C18 14.5007 14.7437 15.5861 14.7437 15.5861"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8306 19.9277H14.8424"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Question;
