import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 12;
const logoHeight = 12;
const PenEdit = ({
  className = "pen-edit-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
    role="img"
    aria-hidden
  >
    <path
      d="M6 10.584H11"
      stroke={fill}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22783 1.74964C8.44082 1.53665 8.7297 1.41699 9.03092 1.41699C9.18007 1.41699 9.32776 1.44637 9.46555 1.50345C9.60335 1.56052 9.72855 1.64418 9.83401 1.74964C9.93948 1.85511 10.0231 1.98031 10.0802 2.11811C10.1373 2.2559 10.1667 2.40359 10.1667 2.55274C10.1667 2.70188 10.1373 2.84957 10.0802 2.98737C10.0231 3.12516 9.93948 3.25036 9.83401 3.35583L3.14158 10.0483L1 10.5837L1.53539 8.44208L8.22783 1.74964Z"
      stroke="#23453A"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PenEdit;
