import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { FormValue } from "informed";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { MotorContext } from "~/contexts/MotorProvider";

import { ModelType, sortModels } from "~/helpers/sortVehicles";

import { H3 } from "~/components/Typography";
import Loading from "~/components/Loading";
import SearchList from "~/components/SearchList";
import VehicleSearchDisplay from "~/components/VehicleSearchDisplay";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

type Props = {
  modelData: ModelType[];
  nextPath?: string;
  loading: boolean;
};

export default function VehicleModel(props: Props) {
  const { modelData, nextPath, loading } = props;
  const motorCtx = useContext(MotorContext);
  const history = useHistory();
  const [simplifiedModelData, setSimplifiedModelData] = useState<string[]>([]);
  const heapCtx = useHeapContext();

  const removeModelDetails = (modelName: string) => {
    // there are some duplications on model name with 'Model Name (detail)', this removes the (detail)
    return modelName.replace(/\s\(.+\)/, "");
  };

  useEffect(() => {
    setSimplifiedModelData(
      modelData
        .sort(sortModels)
        .map((item) => removeModelDetails(item.modelName))
        // filter out duplicates
        .filter((value: string, index: number, self: string[]) => {
          return self.indexOf(value) === index;
        })
        .sort((a: string, b: string) =>
          a.toUpperCase().localeCompare(b.toUpperCase())
        )
    );
  }, [modelData]);

  const handleOnChange = async (value: FormValue) => {
    await motorCtx.vehicleInfo.removeItem("isSportsCar");
    const selectedModel = modelData.find(
      // since the model name is simplified in the useEffect map, the search must also use the simplified value
      (model) => removeModelDetails(model.modelName) === value
    );
    if (selectedModel) {
      const { isSportsCar, trimOptions } = selectedModel;
      try {
        await motorCtx.vehicleInfo.setItem("model", value as string);

        heapCtx.track(HeapEventName.MOTOR_MODEL, {
          Model: value as string,
        });

        if (isSportsCar) {
          await motorCtx.vehicleInfo.setItem("isSportsCar", true);

          pushWithParams(
            history,
            history.location.pathname.replace("/models", "/left-right-hand")
          );
        }

        if (!isSportsCar && trimOptions && trimOptions.length > 0 && nextPath) {
          pushWithParams(history, nextPath);
        } else {
          await motorCtx.vehicleInfo.setItem("isSportsCar", false);

          if (motorCtx.vehicleInfo.data.style) {
            await motorCtx.vehicleInfo.removeItem("style");
          }

          pushWithParams(
            history,
            history.location.pathname.replace("/models", "/left-right-hand")
          );
        }
      } catch (error) {
        console.warn(error);
      }
    }
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1">{`What model is your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()}?`}</H3>
      </legend>
      <VehicleSearchDisplay display={["year", "make"]} />
      <div className={cx(styles.Spinner, { [styles.SpinnerVisible]: loading })}>
        <Loading />
      </div>
      <div
        className={cx(styles.ModelList, {
          [styles.ModelListVisible]: !loading,
        })}
      >
        <SearchList
          loading={loading}
          list={simplifiedModelData}
          field="model"
          keepState
          onChange={handleOnChange}
          initialValue={motorCtx.vehicleInfo.data.model}
        />
      </div>
    </fieldset>
  );
}
