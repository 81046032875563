import React from "react";
import PortalEmptyImg from "~/assets/images/portal-empty.png";
import Button from "~/components/Button";
import { Text } from "~/components/Typography";
import styles from "./index.module.scss";

const PortalEmpty = () => {
  const style = {
    backgroundImage: `url(${PortalEmptyImg})`,
  };
  return (
    <div className={styles.Container}>
      <div className={styles.EmptyImgContainer} style={style}></div>
      <Text className={styles.Text}>You have no recent quotes.</Text>
      <Button
        variant="secondary"
        size="small"
        internalLink={"/"}
        className={styles.Button}
      >
        Start a new Quote
      </Button>
    </div>
  );
};

export default PortalEmpty;
