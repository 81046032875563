import React from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup, useFormState } from "informed";
import { useMotorContext } from "~/contexts/MotorProvider";

import RadioSelector from "~/components/RadioSelector";
import { H3 } from "~/components/Typography";

import styles from "./index.module.scss";

import { NO_COVERAGE_URL } from "~/routes/index.constant";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

export default function OwnVehicle({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const heapCtx = useHeapContext();
  const motorCtx = useMotorContext();
  const formState = useFormState();

  const {
    values: { ownVehicle },
  } = formState;

  const handleOnChange = async (value: FormValue) => {
    try {
      if (nextPath) {
        await motorCtx.vehicleInfo.setItem("isVehicleOwned", value);
        heapCtx.track(HeapEventName.MOTOR_OWNERSHIP_TYPE, {
          "Ownership Type": value.toString(),
        });
        if (value === "no") {
          pushWithParams(history, `${NO_COVERAGE_URL}?message=own-vehicle`);
        } else {
          pushWithParams(history, nextPath);
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <>
      <fieldset>
        <legend>
          <H3 className={styles.Heading} component="h1">
            Are you the owner of this{" "}
            {motorCtx?.vehicleInfo.data.type?.toLowerCase()}?
          </H3>
        </legend>
        <div className={styles.OwnVehicleContent}>
          <RadioGroup field="ownVehicle" keepState>
            <RadioSelector
              key="yes"
              field="ownVehicle"
              value="yes"
              checked={ownVehicle === "yes"}
              onChange={(value) => handleOnChange(value)}
            >
              Yes
            </RadioSelector>
            <RadioSelector
              key="no"
              field="ownVehicle"
              value="no"
              checked={ownVehicle === "no"}
              onChange={(value) => handleOnChange(value)}
            >
              No
            </RadioSelector>
          </RadioGroup>
        </div>
      </fieldset>
    </>
  );
}
