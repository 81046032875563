import React from "react";
import { useHistory } from "react-router-dom";

import { H1 } from "~/components/Typography";

import Header from "~/components/Header";
import ProgressBar from "~/components/ProgressBar";

import { useHeaderContext } from "~/contexts/HeaderProvider";
import StepTitle from "~/components/StepTitle";

import GridWrapper from "~/components/GridWrapper";
import { useProfileName } from "~/helpers/useProfileName";

const GuyanaHome = () => {
  const history = useHistory();
  const { currentStep, totalOfSteps, title } = useHeaderContext();
  const profileName = useProfileName();

  return (
    <>
      <Header
        title={
          <StepTitle
            title={title}
            totalOfSteps={totalOfSteps}
            currentStep={currentStep}
          />
        }
        onPrev={() => history.goBack()}
        onClose={() => null}
        userInitial={profileName}
      />
      <ProgressBar stepsLength={totalOfSteps || 0} step={currentStep || 0} />
      <GridWrapper>
        <H1>Not implemented</H1>
      </GridWrapper>
    </>
  );
};

export default GuyanaHome;
