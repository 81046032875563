const getDisplayStatus = (status: string) => {
  switch (status) {
    case "review": {
      return "In Review";
    }
    case "pending": {
      return "Pending";
    }
    case "closed": {
      return "Closed";
    }
    case "open": {
      return "Open";
    }
    case "quote": {
      return "Quote";
    }
    case "pending_secondary_documents": {
      return "Pending documents";
    }
    case "pending_engchasnum":
    case "pending_occupation":
    case "pending_documents":
    case "pending_signing":
    case "pending_payment":
    case "pending_new_payment":
    case "pending_approval": {
      return "Pending";
    }
    case "active": {
      return "Active";
    }
    case "expired": {
      return "Expired";
    }
    case "cancelled": {
      return "Cancelled";
    }
    case "expiring_soon": {
      return "Expiring Soon";
    }
    default: {
      return "Unknown";
    }
  }
};

export default getDisplayStatus;
