import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;

const ArrowRight = ({
  className = "arrow-right-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle
      cx="15"
      cy="15"
      r="15"
      transform="rotate(-180 15 15)"
      fill="#FDEDBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3142 19.1C11.9237 19.4905 11.9237 20.1237 12.3142 20.5142C12.7047 20.9048 13.3379 20.9048 13.7284 20.5142L18.5355 15.7071C18.9261 15.3166 18.9261 14.6834 18.5355 14.2929L13.7284 9.48577C13.3379 9.09524 12.7047 9.09524 12.3142 9.48577C11.9237 9.87629 11.9237 10.5095 12.3142 10.9L16.4142 15L12.3142 19.1Z"
      fill={fill}
    />
  </svg>
);

export default ArrowRight;
