import React, { ReactNode } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

import { H5, TextSmall } from "~/components/Typography";

type Props = {
  left?: JSX.Element;
  right?: JSX.Element;
  title: string | JSX.Element;
  text?: string;
  inputId: string;
  inputElement: ReactNode;
  className?: string;
  checked: boolean;
  component?: "li";
};

export default function TileCard(props: Props) {
  const {
    inputElement,
    inputId,
    left,
    right,
    title,
    text,
    className,
    checked = false,
    component: Component = "div",
  } = props;

  return (
    <Component className={classnames(styles.TileCardWrapper, className)}>
      {inputElement}
      <label
        htmlFor={inputId}
        className={classnames(styles.Label, {
          [styles.Checked]: checked,
        })}
      >
        {left && <div className={styles.Left}>{left}</div>}
        <div className={styles.Center}>
          {typeof title === "string" ? <H5>{title}</H5> : title}
          {text && <TextSmall>{text}</TextSmall>}
        </div>
        {right && <div className={styles.Right}>{right}</div>}
      </label>
    </Component>
  );
}
