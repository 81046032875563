import React from "react";
import img from "~/assets/images/assistant-portal.png";
import { Bold, H4, TextSmall } from "../Typography";
import styles from "./index.module.scss";

export default function PortalAssistantCard() {
  return (
    <div
      className={styles.PortalAssistantCardWrapper}
      role="group"
      aria-labelledby="portal-assistant-title"
    >
      <img
        src={img}
        className={styles.Image}
        alt="person using a smart phone"
      />
      <div className={styles.Heading}>
        <H4 fontWeight="regular" component="h3" id="portal-assistant-title">
          What to do if you’re in a motor accident.
        </H4>
      </div>
      <div className={styles.Content}>
        <TextSmall>
          If anyone is hurt and requires emergency assistance, call
          <Bold> 511</Bold>.
        </TextSmall>
        <TextSmall>
          After making sure everyone is safe, call our Roadside Assistance
          hotline at{" "}
          <Bold className={styles.AssistanceHotline}> 246-430-4638 </Bold>
          for on-site support at the accident location.
        </TextSmall>
      </div>
    </div>
  );
}
