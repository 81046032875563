import React from "react";
import { formatCurrency } from "~/helpers/currency";
import { Bold, H4, TextExtraSmall } from "../Typography";
import styles from "./index.module.scss";

type Props = {
  installmentCount: number;
  installmentValue: number;
  showValue?: boolean;
  showPrefix?: boolean;
  large?: boolean;
};

export const InstallmentValue = ({
  installmentCount,
  installmentValue,
  showValue = true,
  showPrefix = false,
  large,
}: Props) => {
  return (
    <div className={styles.Wrapper}>
      {showValue ? (
        large ? (
          <H4>
            <Bold>{formatCurrency(installmentValue, false, showPrefix)}</Bold>
          </H4>
        ) : (
          <TextExtraSmall fontWeight="bold">
            {formatCurrency(installmentValue, false)}
          </TextExtraSmall>
        )
      ) : undefined}
      <TextExtraSmall className={styles.InstallmentText}>
        {installmentCount > 1 ? " /per month" : " /per year"}
      </TextExtraSmall>
    </div>
  );
};
