import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";
const logoWidth = 30;
const logoHeight = 30;
const Camera = ({
  className = "camera-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle
      cx="15"
      cy="15"
      r="15"
      transform="rotate(-90 15 15)"
      fill={backgroundColor}
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33181 10.7909C9.33181 9.90632 9.4552 9.64891 9.55206 9.55205C9.64892 9.45519 9.90633 9.33179 10.7909 9.33179H19.1091C19.9937 9.33179 20.2511 9.45519 20.348 9.55205C20.4448 9.64891 20.5682 9.90632 20.5682 10.7909V19.1091C20.5682 19.9937 20.4448 20.2511 20.348 20.3479C20.2511 20.4448 19.9937 20.5682 19.1091 20.5682H10.7909C9.90633 20.5682 9.64892 20.4448 9.55206 20.3479C9.4552 20.2511 9.33181 19.9937 9.33181 19.1091V10.7909ZM10.7909 7.93179C9.93077 7.93179 9.10862 8.01559 8.56211 8.5621C8.0156 9.10861 7.93181 9.93075 7.93181 10.7909V19.1091C7.93181 19.9692 8.0156 20.7914 8.56211 21.3379C9.10862 21.8844 9.93077 21.9682 10.7909 21.9682H19.1091C19.9693 21.9682 20.7914 21.8844 21.3379 21.3379C21.8844 20.7914 21.9682 19.9692 21.9682 19.1091V10.7909C21.9682 9.93075 21.8844 9.10861 21.3379 8.5621C20.7914 8.01559 19.9693 7.93179 19.1091 7.93179H10.7909ZM14.1358 13.3007C14.4769 13.1237 14.8652 13.0587 15.2454 13.1151C15.6332 13.1726 15.9922 13.3533 16.2694 13.6306C16.5466 13.9078 16.7274 14.2668 16.7849 14.6546C16.8412 15.0348 16.7763 15.4231 16.5993 15.7642C16.4223 16.1054 16.1422 16.382 15.7989 16.5548C15.4556 16.7276 15.0665 16.7878 14.687 16.7267C14.3076 16.6657 13.957 16.4865 13.6853 16.2147C13.4135 15.943 13.2343 15.5924 13.1733 15.2129C13.1122 14.8335 13.1724 14.4444 13.3452 14.1011C13.518 13.7578 13.7946 13.4777 14.1358 13.3007ZM15.4507 11.7303C14.7793 11.6307 14.0935 11.7454 13.491 12.058C12.8884 12.3707 12.3998 12.8653 12.0946 13.4717C11.7894 14.078 11.6832 14.7652 11.7911 15.4354C11.8989 16.1056 12.2153 16.7247 12.6953 17.2047C13.1753 17.6847 13.7944 18.0011 14.4646 18.1089C15.1348 18.2168 15.822 18.1106 16.4283 17.8054C17.0346 17.5002 17.5293 17.0116 17.842 16.409C18.1546 15.8065 18.2693 15.1207 18.1697 14.4493C18.0682 13.7643 17.749 13.1302 17.2594 12.6406C16.7698 12.151 16.1357 11.8318 15.4507 11.7303Z"
      fill={fill}
    />
    <path
      d="M13 6H11"
      stroke={fill}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Camera;
