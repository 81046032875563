import { useEffect } from "react";
import { useHistory } from "react-router";

export function useTrackPageVisits() {
  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      if (window.vgo) {
        window.vgo("update");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
