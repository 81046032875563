import { FormValue } from "informed";

import { VEHICLE_INFO_DEFAULT_STATE, VehicleInfoInputs } from "./VehicleInfo";
import { POLICY_INFO_DEFAULT_STATE, PolicyInfoInputs } from "./PolicyInfo";
import { SAVINGS_INFO_DEFAULT_STATE, SavingsInfoInputs } from "./SavingsInfo";

type ContextInfo = {
  setItem(key: string, value: FormValue): void;
  removeItem(key: string): void;
  removeStore(): void;
};

export type IkeyStore = "policyInfo" | "savingsInfo" | "vehicleInfo";

export const MOTOR_KEYS_STORE = ["policyInfo", "savingsInfo", "vehicleInfo"];

export type IData = VehicleInfoInputs | PolicyInfoInputs | SavingsInfoInputs;

export type MotorContextType = {
  vehicleInfo: ContextInfo & {
    data: VehicleInfoInputs;
  };
  policyInfo: ContextInfo & {
    data: PolicyInfoInputs;
  };
  savingsInfo: ContextInfo & {
    data: SavingsInfoInputs;
  };
  setState: (keyStore: IkeyStore, data: IData) => void;
};

export const MOTOR_DEFAULT_STATE = () => ({
  vehicleInfo: VEHICLE_INFO_DEFAULT_STATE(),
  policyInfo: POLICY_INFO_DEFAULT_STATE(),
  savingsInfo: SAVINGS_INFO_DEFAULT_STATE(),
  setState: () => {
    throw new Error("OnboardProvider context has not yet been initialized.");
  },
});
