import { FormValue } from "informed";
// https://countrycode.org/ was the info source of the diallingCodes
import countryCode from "./countryCodes.json";

export const findCountryByAlpha3 = (alpha3: FormValue | undefined) => {
  if (!alpha3 || typeof alpha3 !== "string") {
    return undefined;
  }
  return countryCode.find((country) => {
    return country.alpha3 === alpha3;
  });
};

export const countryCodesData = countryCode;
