import React, { useState } from "react";
import { Bold, H4, Text, TextSmall } from "~/components/Typography";
import Button from "~/components/Button";
import AlertImage from "~/assets/images/alert.png";
import styles from "./index.module.scss";
import TextInput from "~/components/TextInput";
import { Form, FormValues } from "informed";

const NoAccess = (props: { handleAccess: (access: boolean) => void }) => {
  const [hasError, setHasError] = useState(false);

  const handleSubmit = (values: FormValues) => {
    if (values.accessKey === process.env.REACT_APP_ACCESS_KEY) {
      localStorage.setItem("accessKey", process.env.REACT_APP_ACCESS_KEY);
      props.handleAccess(true);
    }
    setHasError(true);
  };
  return (
    <div className={styles.Wrapper}>
      <img className={styles.Alert} src={AlertImage} alt="Alert" />
      <H4 className={styles.Heading}>Access Denied</H4>
      <Text className={styles.Text}>
        Unfortunately you do not have access to this link. Please enter the
        almi-passphrase to continue, or contact us at{" "}
        <Bold>almi.bb/support</Bold> for access.
      </Text>
      <Form className={styles.Container} onSubmit={handleSubmit}>
        <TextInput
          className={styles.Password}
          field="accessKey"
          placeholder="almi-passphrase"
          type="password"
          autoComplete="current-password"
        />
        {hasError && (
          <TextSmall className={styles.StatusError}>
            Incorrect passphrase.
          </TextSmall>
        )}
        <Button className={styles.ContinueButton} type="submit">
          Continue
        </Button>
      </Form>
    </div>
  );
};

export default NoAccess;
