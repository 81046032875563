import { CountryCode } from "../types";

export default function getBranchFromCountryCode(code: CountryCode) {
  switch (code.toUpperCase()) {
    case "BRB": {
      return "BB";
    }

    case "GUY": {
      return "DG";
    }

    default: {
      return "Unsupported Country Code";
    }
  }
}
