import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import { getSvgWrapperDefaultValues } from "~/components/Icon/index.utils";

const logoWidth = 42;
const logoHeight = 41;
const AssistantInfo = ({
  className = "assistant-info-icon",
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
    role="img"
    aria-label="your almi assistant"
  >
    <ellipse
      cx="14.6341"
      cy="25.4146"
      rx="14.6341"
      ry="14.6341"
      fill="#23453A"
    />
    <ellipse
      cx="29.8039"
      cy="12.1961"
      rx="10.1961"
      ry="10.1961"
      fill="#FDEDBF"
      stroke="#FCF9F3"
      strokeWidth="3"
    />
    <path
      d="M29.9488 15.6534V11.5749"
      stroke="#23453A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.9494 8.85612H29.9596"
      stroke="#23453A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7746 21.6127C11.7746 23.0557 12.9443 24.2254 14.3873 24.2254C15.8302 24.2254 17 23.0557 17 21.6127C17 20.1698 15.8303 19 14.3873 19C12.9443 19 11.7746 20.1698 11.7746 21.6127Z"
      fill="#F6B600"
    />
    <path
      d="M8 31.4541L15.332 31.4541C18.4659 31.4541 21 28.8122 21 25.545L13.668 25.545C10.5341 25.545 8 28.1957 8 31.4541Z"
      fill="#F6B600"
    />
  </svg>
);

export default AssistantInfo;
