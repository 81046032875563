import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;

const ArrowUp = ({
  className = "arrow-up-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle
      cx="15"
      cy="15"
      r="15"
      transform="rotate(90 15 15)"
      fill="#FDEDBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0849 17.7006C19.4837 18.0995 20.1305 18.0995 20.5293 17.7006C20.9282 17.3018 20.9282 16.6551 20.5293 16.2562L15.7222 11.4491C15.3233 11.0502 14.6766 11.0502 14.2777 11.4491L9.47062 16.2562C9.07175 16.6551 9.07175 17.3018 9.47062 17.7006C9.8695 18.0995 10.5162 18.0995 10.9151 17.7006L15 13.6158L19.0849 17.7006Z"
      fill={fill}
    />
  </svg>
);

export default ArrowUp;
