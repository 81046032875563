import React, { useState } from "react";
import classnames from "classnames";
import Icon from "../Icon";

import styles from "./index.module.scss";

type Props = {
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  searchListId?: string;
};

export const SearchInput = ({
  onChange,
  className,
  placeholder = "search",
  autoFocus,
  searchListId,
}: Props) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleOnChange = (value: string) => {
    setSearchText(value);
    onChange(value);
  };

  const cancel = () => {
    setSearchText("");
    onChange("");
  };

  const hasFocusOrText = hasFocus || searchText;

  return (
    <div className={classnames(styles.SearchInputWrapper, className)}>
      <label
        className={classnames(styles.SearchInputLabel, {
          [styles.InputHasFocus]: hasFocusOrText,
          [styles.InputShrink]: hasFocusOrText,
          [styles.InputGrow]: !hasFocusOrText,
        })}
      >
        <Icon
          name="MagnifyingGlass"
          className={styles.MagnifyingGlassIcon}
          fill="#000000"
        />
        <input
          type="text"
          name="search"
          placeholder={placeholder}
          className={styles.SearchInput}
          onChange={({ currentTarget: { value } }) => handleOnChange(value)}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          value={searchText}
          autoFocus={autoFocus}
          aria-owns={searchListId}
          aria-describedby={searchListId && `${searchListId}-desc`}
        />
      </label>
      {
        <button
          className={classnames(styles.CancelBtn, {
            [styles.CancelBtnFadeIn]: hasFocusOrText,
            [styles.CancelBtnFadeOut]: !hasFocusOrText,
          })}
          onClick={cancel}
          type="button"
          aria-label="cancel"
        >
          <Icon
            name="Close"
            className={styles.CancelIcon}
            backgroundColor="#f1f0ea"
            fill="#000000"
          />
        </button>
      }
    </div>
  );
};
