import React, { ReactNode } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { ButtonOptions } from "~/components/Error";
import AppError from "~/helpers/AppError";
import { AssistantData } from "~/contexts/AlmiAssistantProvider";

type PropsType = {
  errorComponent: React.ElementType<{
    subtitle: string;
    options?: {
      title?: string;
      image?: ReactNode;
      assistantMessage?: AssistantData;
      mainButton?: ButtonOptions;
      secondaryButton?: ButtonOptions;
    };
  }>;
  children: React.ReactNode;
} & RouteComponentProps;

type StateType = {
  hasError: boolean;
  subtitle: string;
  options?: {
    title?: string;
    image?: ReactNode;
    assistantMessage?: AssistantData;
    mainButton?: ButtonOptions;
    secondaryButton?: ButtonOptions;
  };
};

class ErrorBoundary extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      hasError: false,
      subtitle: "",
    };

    const { history } = this.props;

    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  getIfExistReactMinifiedError(message?: string) {
    if (message) {
      const decodeError = decodeURIComponent(message);
      if (
        !decodeError?.includes("&args[]=Error:") ||
        !decodeError?.includes("&args[]")
      )
        return message;
      const clearMsg = decodeError
        ?.substring(
          decodeError?.lastIndexOf("&args[]=Error:"),
          decodeError?.lastIndexOf("&args[]")
        )
        .replace("&args[]=Error:", "");
      return typeof clearMsg === "string" && clearMsg.length
        ? clearMsg
        : "Unknown reason";
    }
    return "Unknown reason";
  }

  cleanSubtitle = (subtitle: string) => {
    return subtitle.replace(/^GraphQL error: /, "");
  };

  componentDidCatch(error: Error) {
    if (error instanceof AppError) {
      this.setState({
        hasError: true,
        subtitle: this.cleanSubtitle(error.subtitle),
        options: error.options,
      });
    } else {
      this.setState({
        hasError: true,
        subtitle: `An internal error occurred:\n${this.getIfExistReactMinifiedError(
          this.cleanSubtitle(error.message)
        )}`,
      });
    }
  }

  render() {
    const { errorComponent: ErrorComponent } = this.props;

    if (this.state.hasError) {
      return (
        <ErrorComponent
          subtitle={this.state.subtitle}
          options={this.state.options}
        />
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
