import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;
const EyeClose = ({
  className = "eye-close-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle cx="15" cy="15" r="15" fill="#FDEDBF" />
    <g clipPath="url(#eye-close-clip0)">
      <path
        d="M16.4133 16.4133C16.2302 16.6098 16.0094 16.7674 15.7641 16.8767C15.5188 16.9861 15.2539 17.0448 14.9854 17.0496C14.7169 17.0543 14.4501 17.0049 14.2011 16.9043C13.952 16.8037 13.7258 16.654 13.5359 16.4641C13.346 16.2742 13.1963 16.048 13.0957 15.7989C12.9951 15.5499 12.9457 15.2831 12.9504 15.0146C12.9552 14.7461 13.0139 14.4812 13.1233 14.2359C13.2326 13.9906 13.3902 13.7698 13.5867 13.5867M18.96 18.96C17.8204 19.8287 16.4327 20.3099 15 20.3333C10.3333 20.3333 7.66667 15 7.66667 15C8.49593 13.4546 9.6461 12.1044 11.04 11.04L18.96 18.96ZM13.6 9.82666C14.0589 9.71924 14.5287 9.66555 15 9.66666C19.6667 9.66666 22.3333 15 22.3333 15C21.9287 15.7571 21.446 16.4698 20.8933 17.1267L13.6 9.82666Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66667 7.66667L22.3333 22.3333"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="eye-close-clip0">
        <rect width="16" height="16" fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeClose;
