import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { Text, H3, Bold, TextSmall } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import Button from "~/components/SubmitButton";
import { yearsDrivingExperienceValidator } from "~/helpers/validators";

import { useMotorContext } from "~/contexts/MotorProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import pushWithParams from "~/helpers/pushWithParams";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

type Props = {
  nextPath?: string;
};

export default function HowManyYearsDriving(props: Props) {
  const { nextPath } = props;
  const history = useHistory();
  const motorCtx = useMotorContext();
  const heapCtx = useHeapContext();

  const fieldName = "yearsDriving";
  const { value, error } = useCustomFieldState(fieldName);

  useUpdateAssistant({
    text: (
      <TextSmall>
        This is typically how long you&apos;ve had your driver&apos;s license.
      </TextSmall>
    ),
  });

  const handleOnNext = async () => {
    try {
      if (value && !error) {
        await motorCtx.policyInfo.setItem(fieldName, Number(value));

        heapCtx.addUserProperties({
          "Driving Experience": Number(value),
        });

        heapCtx.track(HeapEventName.MOTOR_DRIVING_EXPERIENCE, {
          Experience: Number(value),
        });

        if (nextPath) {
          pushWithParams(history, nextPath);
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const label = "How many years of driving experience do you have?";

  return (
    <>
      <div className={styles.InlineTitleBox}>
        <Text>
          You&apos;ve chosen <Bold>Motor Insurance</Bold>
        </Text>
      </div>
      <H3 className={styles.Question} component="h1">
        {label}
      </H3>
      <TextInput
        className={styles.TextInputWrapper}
        label={label}
        hideLabelFromView
        field={fieldName}
        placeholder="00"
        type="number"
        size={2}
        initialValue={motorCtx.policyInfo.data.yearsDriving?.toString()}
        keepState
        validate={(value) =>
          yearsDrivingExperienceValidator(
            value || "",
            motorCtx.policyInfo.data.age || 0
          )
        }
        validateOnBlur
        validateOnMount
        validateOnChange
        autoFocus
        noValidate
        inputMode="numeric"
      />
      <div className={styles.ButtonWrapper}>
        <Button
          onClick={handleOnNext}
          disabled={!!error || !value}
          className={styles.ButtonNext}
        >
          Next
        </Button>
      </div>
    </>
  );
}
