import React from "react";

type CameraContextType = {
  permission: "prompt" | "granted" | "denied";
};

const initialState = (): CameraContextType => ({
  permission: "prompt",
});

export const CameraContext = React.createContext(
  initialState() as CameraContextType & {
    setState: (state: CameraContextType) => void;
  }
);

export const useCameraContext = () => React.useContext(CameraContext);

const CameraProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = React.useState(initialState());

  return (
    <CameraContext.Provider value={{ ...state, setState }}>
      {children}
    </CameraContext.Provider>
  );
};

export default CameraProvider;
