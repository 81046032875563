import { ApolloError } from "apollo-client";
import { GraphQLError } from "graphql";

/**
 * Check if an ApolloError object contains en error with the given code
 * @param error - The Error object to check
 * @param code - A status code e.g. "NOT_AUTHORIZED"
 */
export default function hasErrorCode(error: Error, code: string | string[]) {
  if (!(error instanceof ApolloError)) return false;
  return error.graphQLErrors.some((graphQLErr: GraphQLError) =>
    Array.isArray(code)
      ? code.includes(graphQLErr.extensions?.code)
      : graphQLErr.extensions?.code === code
  );
}
