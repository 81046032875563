import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";
const logoWidth = 30;
const logoHeight = 30;
const ArrowDown = ({
  className = "arrow-down-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
    role="img"
    aria-hidden
  >
    <circle
      cx="15"
      cy="15"
      r="15"
      transform="rotate(-90 15 15)"
      fill={backgroundColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9151 12.2994C10.5163 11.9005 9.86954 11.9005 9.47067 12.2994C9.07179 12.6982 9.07179 13.3449 9.47067 13.7438L14.2778 18.5509C14.6767 18.9498 15.3234 18.9498 15.7223 18.5509L20.5294 13.7438C20.9283 13.3449 20.9283 12.6982 20.5294 12.2994C20.1305 11.9005 19.4838 11.9005 19.0849 12.2994L15 16.3842L10.9151 12.2994Z"
      fill={fill}
    />
  </svg>
);

export default ArrowDown;
