import React from "react";

import styles from "./index.module.scss";

import { Radio } from "informed";

type Props = {
  label?: string;
  value: string;
  field: string;
  disabled?: boolean;
  keepState?: boolean;
  initialValue?: string;
  checked?: boolean;
  onClick?: () => void;
};

export default (props: Props) => {
  const {
    label,
    value,
    disabled,
    field,
    keepState,
    initialValue,
    onClick,
  } = props;

  return (
    <div className={styles.RadioButtonWrapper}>
      <label className={styles.RadioButton} htmlFor={value}>
        <Radio
          initialValue={initialValue}
          value={value}
          name={value}
          field={field}
          disabled={disabled}
          className={styles.Input}
          id={value}
          keepState={keepState}
          onClick={onClick}
        />
        <div className={styles.Checked} />
        {label && <span className={styles.Label}>{label}</span>}
      </label>
    </div>
  );
};
