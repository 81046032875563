import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  AssistantData,
  useAssistantContext,
} from "~/contexts/AlmiAssistantProvider";

const wait = 300;
export function useUpdateAssistant({
  text,
  isOpen = true,
  skip = false,
}: AssistantData & { skip?: boolean }) {
  const { updateAssistant } = useAssistantContext();
  const history = useHistory();

  useEffect(() => {
    if (skip) {
      return () => {};
    }

    const unsubscribe = history.listen(() => {
      updateAssistant({ isOpen: false, text });
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (!skip && text) {
      setTimeout(() => {
        updateAssistant({ isOpen, text });
      }, wait);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
}
