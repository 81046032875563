import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";
const logoWidth = 18;
const logoHeight = 18;
const Camera = ({
  className = "camera-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <path
      d="M5.61328 7.01123H2.23828V3.63623"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3752 4.62478C12.8007 4.05021 12.1185 3.59445 11.3678 3.2835C10.6171 2.97254 9.81254 2.8125 8.99999 2.8125C8.18744 2.8125 7.38284 2.97254 6.63214 3.2835C5.88143 3.59445 5.19933 4.05021 4.62477 4.62478L2.23828 7.01126"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3867 10.9888H15.7617V14.3638"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.625 13.3753C5.19956 13.9498 5.88187 14.4056 6.63237 14.7165C7.38307 15.0275 8.18767 15.1875 9.00022 15.1875C9.81278 15.1875 10.6174 15.0275 11.3681 14.7165C12.1188 14.4056 12.8009 13.9498 13.3754 13.3753L15.7619 10.9888"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Camera;
