import gql from "graphql-tag";

export const SAVE_MOTOR_POLICY = gql`
  mutation SavePolicy($savePolicyInput: SavePolicyInput!) {
    savePolicy(input: $savePolicyInput)
  }
`;

export const SAVE_HOME_POLICY = gql`
  mutation SaveHomePolicy($saveHomePolicyInput: SaveHomePolicyInput!) {
    saveHomePolicy(input: $saveHomePolicyInput)
  }
`;
