import React from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup } from "informed";

import styles from "./index.module.scss";

import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHomeContext } from "~/contexts/HomeProvider";

import { H3, TextSmall } from "~/components/Typography";
import RadioSelector from "~/components/RadioSelector";
import { NO_COVERAGE_URL } from "~/routes/index.constant";

export default function FloodRisk({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const fieldName = "isFloodRisk";
  const { value } = useCustomFieldState(fieldName);

  useUpdateAssistant({
    text: (
      <TextSmall>
        These areas have potential for erosion or movement of the soil, or are
        at risk of flooding due to excess rain.
      </TextSmall>
    ),
  });

  const handleOnNext = (value: FormValue) => {
    if (value === "yes") {
      homeCtx.setState({ isFloodZone: true });
      history.push(`${NO_COVERAGE_URL}?message=home-flood-risk`);
      return;
    }
    homeCtx.setState({ isFloodZone: false });
    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1">
          Is your home in an area with increased chances of land slippage or
          flooding?
        </H3>
      </legend>
      <div className={styles.FloodRiskContent}>
        <RadioGroup field={fieldName} keepState>
          <RadioSelector
            key="yes"
            field={fieldName}
            value="yes"
            checked={homeCtx?.isFloodZone || value === "yes"}
            onChange={handleOnNext}
          >
            Yes
          </RadioSelector>
          <RadioSelector
            key="No"
            field={fieldName}
            value="no"
            checked={!homeCtx?.isFloodZone || value === "no"}
            onChange={handleOnNext}
          >
            No
          </RadioSelector>
        </RadioGroup>
      </div>
    </fieldset>
  );
}
