import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  QuoteCoveredItem,
  ValuesByNumberOfInstallments,
} from "~/contexts/MotorProvider/PolicyInfo";
import AppError from "./AppError";
import { PolicyStatus } from "../types";
import alertImage from "~/assets/images/veriff-alert-image.svg";

const useHasRequiredData = (props: {
  requiredData: Array<
    | string
    | number
    | ValuesByNumberOfInstallments
    | QuoteCoveredItem[]
    | undefined
  >;
  policyRequiredStatus: PolicyStatus | PolicyStatus[];
  policyStatus?: PolicyStatus;
  redirectUrl?: "/portal";
  skip?: boolean; // Workaround since hooks cannot be conditional.
}) => {
  const { redirectUrl = "/portal", skip } = props;
  const history = useHistory();

  useEffect(() => {
    if (skip) {
      return;
    }

    const hasRequiredData =
      (Array.isArray(props.policyRequiredStatus)
        ? props.policyRequiredStatus.includes(
            props.policyStatus as PolicyStatus
          )
        : props.policyRequiredStatus === props.policyStatus) &&
      props.requiredData.every((data) => {
        return data || data === 0;
      });

    if (hasRequiredData || process.env.NODE_ENV === "test") {
      return;
    }
    throw new AppError(
      `Sorry! There seems to be an issue syncing this session with the server. If you have Almi open in other tabs, please close those and resume onboarding this policy from the portal.`,
      {
        title: "Syncing Issue",
        mainButton: {
          text: "Visit portal",
          onClick: () => history.push(redirectUrl),
        },
        assistantMessage: undefined,
        image: (
          <img src={alertImage} alt="Alert" style={{ marginBottom: "25px" }} />
        ),
      }
    );
  });
};

export default useHasRequiredData;
