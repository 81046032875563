import React from "react";
import { H3 } from "~/components/Typography";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import RadioBinary from "~/components/RadioBinary";
import { useHistory } from "react-router";

type Props = {
  nextPath?: string;
};

const Student = ({ nextPath }: Props) => {
  const motorCtx = useMotorContext();
  const heapCtx = useHeapContext();
  const history = useHistory();

  const handleNextClick = async (isStudent: boolean) => {
    if (nextPath) {
      if (isStudent) {
        await motorCtx.savingsInfo.setItem("occupation", "Student");

        heapCtx.addUserProperties({
          Occupation: "Student",
        });

        heapCtx.track(HeapEventName.MOTOR_OCCUPATION, {
          Occupation: "Student",
        });
      }

      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <VehicleSearchDisplaySmall
          prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
        />

        <H3 fontWeight="bold" component="h1">
          Are you a student?
        </H3>
      </legend>

      <RadioBinary handleClick={handleNextClick} field="isStudent" />
    </fieldset>
  );
};

export default Student;
