/**
 * Dates are tricky. A Javascript Date is really just an object that
 * contains a timestamp and methods to let us render that timestamp in
 * either the operating system timezone, or UTC. This means a Date object
 * can't be relied on to express the same date to every user, because the
 * date that is rendered depends on the timezone the user is in. The
 * purpose of these helper functions is to help us treat dates consistently
 * between packages, and be aware when we are using Date objects that are
 * set to the user's timezone.
 *
 *                                              /$$                     /$$
 *                                             |__/                    | $$
 *  /$$  /$$  /$$  /$$$$$$   /$$$$$$  /$$$$$$$  /$$ /$$$$$$$   /$$$$$$ | $$
 * | $$ | $$ | $$ |____  $$ /$$__  $$| $$__  $$| $$| $$__  $$ /$$__  $$| $$
 * | $$ | $$ | $$  /$$$$$$$| $$  \__/| $$  \ $$| $$| $$  \ $$| $$  \ $$|__/
 * | $$ | $$ | $$ /$$__  $$| $$      | $$  | $$| $$| $$  | $$| $$  | $$
 * |  $$$$$/$$$$/|  $$$$$$$| $$      | $$  | $$| $$| $$  | $$|  $$$$$$$ /$$
 *  \_____/\___/  \_______/|__/      |__/  |__/|__/|__/  |__/ \____  $$|__/
 *                                                            /$$  \ $$
 *                                                           |  $$$$$$/
 *                                                            \______/
 *
 * This file exists in four different locations. If you update one, please
 * update the others to match. TODO: Use Lerna to make this a common
 * dependency of each package.
 *
 * Locations:
 *  - packages/api/src/utils/dates.ts
 *  - packages/edge-system/src/utils/dates.ts
 *  - packages/maintenance-functions/lib/utils/dates.ts
 *  - packages/web/src/helpers/dates.ts
 */

import {
  add,
  format,
  parse,
  startOfToday,
  Duration,
  startOfMonth,
  getMonth,
  endOfMonth,
  endOfDay,
} from "date-fns";

export const QUOTE_DURATION_DAYS = 7;

export const isShiftedByGivenDays = (
  date: Date,
  daysShifted: number
): boolean => {
  return (
    format(add(date, { days: daysShifted }), "dMy") ===
    format(new Date(), "dMy")
  );
};

/**
 * Convert a YYYY-MM-DD date string to another format. Defaults to UK date format.
 * @param {string} isoDateString - A date in format YYYY-MM-DD.
 * @param {string} dateFnsFormat - The output format, passed through to date-fns.
 */
export function formatIsoDate(
  isoDateString: string,
  dateFnsFormat = "dd/MM/yyyy"
): string {
  return format(parse(isoDateString, "yyyy-MM-dd", new Date()), dateFnsFormat);
}

/**
 * Convert a YYYYMMDD date string to another format. Defaults to UK date format.
 * @param {string} ymdDateString - A date in format YYYYMMDD.
 * @param {string} dateFnsFormat - The output format, passed through to date-fns.
 */
export function formatYmdDate(
  ymdDateString: string,
  dateFnsFormat = "yyyy-MM-dd"
): string {
  return format(parse(ymdDateString, "yyyyMMdd", new Date()), dateFnsFormat);
}

/**
 * Convert a DD/MM/YYYY date string to another format. Defaults to ISO date format.
 * @param {string} ukDateString - A date in format DD/MM/YYYY.
 * @param {string} dateFnsFormat - The output format, passed through to date-fns.
 */
export function formatUkDate(
  ukDateString: string,
  dateFnsFormat = "yyyy-MM-dd"
): string {
  return format(parse(ukDateString, "dd/MM/yyyy", new Date()), dateFnsFormat);
}

/**
 * Add a period of time to a YYYY-MM-DD date string and return it in the same format.
 * @param {string} isoDateString - A date in format YYYY-MM-DD.
 * @param {Duration} duration - An object representing the duration of time to add.
 */
export function addIsoDate(isoDateString: string, duration: Duration): string {
  return format(
    add(parse(isoDateString, "yyyy-MM-dd", new Date()), duration),
    "yyyy-MM-dd"
  );
}

/**
 * Parse a Date object from a YYYY-MM-DD date string. The time will be
 * set to the beginning of that day (midnight) **in the user/OS's timezone**.
 * @param {string} isoDateString - A date in format YYYY-MM-DD.
 */
export function localDateFromIso(isoDateString: string): Date {
  return parse(isoDateString, "yyyy-MM-dd", new Date());
}

/**
 * Parse a Date object from a YYYYMMDD date string. The time will be
 * set to the beginning of that day (midnight) **in the user/OS's timezone**.
 * @param {string} ymdDateString - A date in format YYYYMMDD.
 */
export function localDateFromYmd(ymdDateString: string): Date {
  return parse(ymdDateString, "yyyyMMdd", new Date());
}

/**
 * Parse a Date object from a DD/MM/YYYY date string. The time will be
 * set to the beginning of that day (midnight) **in the user/OS's timezone**.
 * @param {string} ukDateString - A date in format DD/MM/YYYY.
 */
export function localDateFromUk(ukDateString: string): Date {
  return parse(ukDateString, "dd/MM/yyyy", new Date());
}

/**
 * Create a Date object representing the beginning (midnight) of today
 * **in the user/OS's timezone**.
 */
export function localDateToday(): Date {
  return startOfToday();
}

/**
 * Check that a YYYY-MM-DD string is valid
 * @param {string} isoDateString - A date in format YYYY-MM-DD.
 */
export function isValidIsoDate(isoDateString: string): boolean {
  return (
    /^\d{4}-\d{2}-\d{2}$/.test(isoDateString) &&
    !isNaN(Date.parse(isoDateString))
  );
}

/**
 * Check that a DD/MM/YYYY string is valid
 * @param {string} ukDateString - A date in format DD/MM/YYYY.
 */
export function isValidUkDate(ukDateString: string): boolean {
  return (
    /^\d{2}\/\d{2}\/\d{4}$/.test(ukDateString) &&
    !isNaN(localDateFromUk(ukDateString).getTime())
  );
}

/**
 * A custom Joi validator function for a YYYY-MM-DD string
 */
export function joiIsoDateStringValidator(value: string) {
  if (!isValidIsoDate(value)) {
    throw new Error("Invalid date string");
  }
  return value;
}

/**
 * Get the date today in AST timezone as YYYY-MM-DD string
 */
export function todaysDateInAst() {
  const date = new Date();
  const localOffset = date.getTimezoneOffset();
  const astUtcOffsetHours = 4;
  const minutesInAnHour = 60;
  const astOffset = astUtcOffsetHours * minutesInAnHour;
  const offsetDifference = localOffset - astOffset;
  const referenceDate = add(date, { minutes: offsetDifference });
  return format(referenceDate, "yyyy-MM-dd");
}

/**
 * Get the maximum coverage start date for new quotes created today.
 * Should be the earlier of April 30 or 6 months from today.
 */
export function getCoverageStartMaxDate() {
  const maxDaysCount = 90;
  const monthOfApril = 3;

  const astDate = localDateFromIso(todaysDateInAst());
  const maxDate = add(astDate, { days: maxDaysCount });

  let aprilCutoff = startOfMonth(astDate);
  while (getMonth(aprilCutoff) !== monthOfApril) {
    aprilCutoff = add(aprilCutoff, { months: 1 });
  }
  aprilCutoff = endOfMonth(aprilCutoff);

  return maxDate > aprilCutoff
    ? format(aprilCutoff, "yyyy-MM-dd")
    : format(maxDate, "yyyy-MM-dd");
}

/**
 * Get the maximum expiration date for a quote created today.
 * Should be the earlier of the coverage start date or 7 days from now.
 */
export function getQuoteExpirationDate(coverageStart: string) {
  const coverageStartDate = localDateFromIso(coverageStart);
  const maxExpirationDate = add(localDateToday(), {
    days: QUOTE_DURATION_DAYS,
  });

  return endOfDay(
    coverageStartDate < maxExpirationDate
      ? coverageStartDate
      : maxExpirationDate
  );
}
