import React, { useContext } from "react";
import { Bold, Text } from "~/components/Typography";
import { MotorContext } from "~/contexts/MotorProvider";

import styles from "./index.module.scss";

type PropsType = {
  prefix?: string;
};

const VehicleSearchDisplaySmall = ({ prefix }: PropsType) => {
  const { vehicleInfo } = useContext(MotorContext);

  if (!vehicleInfo || !vehicleInfo.data || !vehicleInfo.data.make) return null;

  return (
    <Text className={styles.Vehicle}>
      {`${prefix} `}
      <Bold>
        {vehicleInfo.data.year}
        {vehicleInfo.data.make ? ` ${vehicleInfo.data.make}` : null}
        {vehicleInfo.data.model ? ` ${vehicleInfo.data.model}` : null}
      </Bold>
    </Text>
  );
};

export default VehicleSearchDisplaySmall;
