/* eslint-disable no-magic-numbers */

const getBreakpoint = () => {
  if (window.innerWidth < 540) return "small";
  if (window.innerWidth < 768) return "medium";
  if (window.innerWidth < 1024) return "large";
  if (window.innerWidth < 1200) return "xlarge";
  return "xxlarge";
};

export default getBreakpoint;
