import React from "react";
import { useAuth0Context } from "~/contexts/Auth0Context";
import { H4, Text } from "~/components/Typography";
import Button from "~/components/Button";
import AlertImage from "~/assets/images/alert.png";
import styles from "./index.module.scss";

const SessionExpired = () => {
  const auth0 = useAuth0Context();

  const handleLogin = () => {
    return auth0.login("/portal")();
  };

  return (
    <div className={styles.Wrapper}>
      <img className={styles.Alert} src={AlertImage} alt="Alert" />
      <H4 className={styles.Heading}>Session Expired</H4>
      <Text className={styles.Text}>
        Your session has expired due to inactivity.
      </Text>
      <Button onClick={handleLogin}>Login to Portal</Button>
    </div>
  );
};

export default SessionExpired;
