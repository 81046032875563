import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const size = 130;

const AlmiIcon = ({
  className = "almi-icon",
  fill = DEFAULT_FILL_COLOR,
  width = size,
  height = size,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    aria-label="almi logo"
    role="img"
  >
    <path
      d="M34.106 53.376c14.18 0 25.739-11.485 25.739-25.76 0-14.274-11.558-25.677-25.739-25.677-14.18 0-25.738 11.567-25.738 25.76 0 14.192 11.558 25.677 25.738 25.677zM73.133 1.857v71.231c0 30.446 24.595 55.065 55.01 55.065V56.922c0-30.446-24.676-55.065-55.01-55.065zM13.864 82.169c-15.744 15.75-11.48 45.447-11.48 45.447s29.684 4.266 45.428-11.485c15.744-15.75 11.48-45.447 11.48-45.447S29.608 66.5 13.864 82.17z"
      fill={fill}
    />
  </svg>
);

export default AlmiIcon;
