/* eslint-disable no-magic-numbers */
import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

import Button from "../Button";
import Icon from "../Icon";

import { H5, TextSmall } from "../Typography";

export type Props = {
  onSelectFile?: () => void;
  onTakePhoto?: () => void;
  onPressCard?: () => void;
  title: string;
  description?: string;
  filename?: string;
  error?: string;
  uploaded: boolean;
  uploadedPercentage: number;
  uploadDisabled: boolean;
};

const squareRightBorderLimit = 92;
const squareRightBorderRestMultiplier = 2;
function calcProgressBarBorderRadius(uploadedPercentage: number) {
  const pixelsRound =
    uploadedPercentage >= squareRightBorderLimit
      ? `${
          squareRightBorderRestMultiplier *
          (squareRightBorderLimit - 1 - uploadedPercentage) *
          -1
        }px`
      : "0";
  return `16px ${pixelsRound} ${pixelsRound} 16px`;
}

const ButtonTemplate = ({
  onClick,
  children,
  disable,
}: {
  onClick: () => void;
  children: React.ReactNode;
  disable: boolean;
}) => (
  <Button
    className={styles.InnerButton}
    size="xsmall"
    variant="tertiary"
    onClick={(e?: MouseEvent) => {
      e?.preventDefault();
      // Stop Propogation prevents onPressCard and other parent eventHandlers
      // from executing.
      e?.stopPropagation();
      onClick();
    }}
    disabled={disable}
  >
    {children}
  </Button>
);

export default function FileInputCard({
  onSelectFile,
  onTakePhoto,
  onPressCard,
  title,
  description,
  uploadedPercentage,
  uploaded,
  error,
  filename,
  uploadDisabled,
}: Props) {
  const uploadFileButton = (
    <ButtonTemplate
      onClick={() => {
        if (onSelectFile) onSelectFile();
      }}
      disable={uploadDisabled}
    >
      <Icon name="Download" backgroundColor="transparent" fill="currentColor" />
      {error || uploaded ? "Re-upload file" : "Select file"}
    </ButtonTemplate>
  );

  const takePhotoButton = (
    <ButtonTemplate
      onClick={() => {
        if (onTakePhoto) onTakePhoto();
      }}
      disable={uploadDisabled}
    >
      <Icon name="Camera" backgroundColor="transparent" fill="currentColor" />
      {error || uploaded ? "Retake photo" : "Take photo"}
    </ButtonTemplate>
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (onPressCard && (event.key === "Enter" || event.key === " ")) {
      onPressCard();
    }
  };

  return (
    <div
      className={classnames(styles.CardWrapper, {
        [styles.Selected]: uploaded,
      })}
      onKeyPress={
        !uploaded && !error && onPressCard ? handleKeyPress : undefined
      }
      onClick={uploaded || error ? undefined : onPressCard}
      tabIndex={!uploaded && !error && onPressCard ? -1 : undefined}
      role={!uploaded && !error && onPressCard ? "button" : undefined}
    >
      <div
        className={styles.BackgroundOverlayer}
        style={{
          width: `${uploadedPercentage}%`,
          backgroundColor: "#D9DCD6",
          borderRadius: calcProgressBarBorderRadius(
            uploaded ? 0 : uploadedPercentage
          ),
        }}
      />
      {uploaded ?? (
        <div className={styles.SVGWrapper}>
          <div className={styles.SVGContent}>
            <Icon
              width="30px"
              height="30px"
              name="Plus"
              className={styles.SelectedIcon}
            />
          </div>
        </div>
      )}
      <div className={styles.Content}>
        <H5 className={classnames(styles.CardTitle, styles.Vertical)}>
          {title}
        </H5>
        <TextSmall className={styles.CardDescription}>{description}</TextSmall>
        {filename && (
          <TextSmall className={styles.CardDescription}>{filename}</TextSmall>
        )}
        {error && (
          <TextSmall
            className={classnames(styles.CardDescription, {
              [styles.Error]: error,
            })}
          >
            {error}
          </TextSmall>
        )}
      </div>
      <div className={styles.InnerButtonsWrapper}>
        {onSelectFile && uploadFileButton}
        {onTakePhoto && takePhotoButton}
      </div>
    </div>
  );
}
