import React, { ReactNode, memo, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import classNames from "classnames";
import styles from "./index.module.scss";
import { AvailableIcons } from "~/components/Icon/index.types";
import { ENVIRONMENTS } from "~/helpers/constants";
import Icon from "~/components/Icon";
import { TextSmall } from "~/components/Typography";
import SideBarNavigation from "../SideBarNavigation";

type Props = {
  title?: string | ReactNode;
  onPrev?: () => void;
  onClose?: (() => void) | string;
  closeIcon?: AvailableIcons;
  hideBackButton?: boolean;
  userInitial?: string;
};

function Header(props: Props) {
  const {
    title,
    onClose,
    onPrev,
    closeIcon,
    hideBackButton,
    userInitial,
  } = props;
  const [ready, setReady] = useState(false);

  useLayoutEffect(() => {
    if (!ready) {
      setReady(true);
    }
  }, [ready]);

  const renderHeaderLeft = () => {
    if (typeof onPrev === "function" && !hideBackButton) {
      return (
        <div className={styles.HeaderLeft}>
          <button
            type="button"
            onClick={onPrev}
            className={styles.IconNavigation}
            aria-label="previous step"
            disabled={!ready}
            tabIndex={-1}
          >
            <Icon width={40} height={40} name="ArrowLeft" />
          </button>
        </div>
      );
    } else return null;
  };

  const renderCloseButton = () => {
    if (typeof onClose === "function")
      return (
        <button
          type="button"
          onClick={onClose}
          className={styles.IconNavigation}
          aria-label="close"
          tabIndex={-1}
        >
          <Icon width={40} height={40} name={closeIcon ? closeIcon : "Close"} />
          close
        </button>
      );
    if (typeof onClose === "string")
      return (
        <a
          href={onClose}
          className={styles.IconNavigation}
          aria-label="link to almi home page"
        >
          <Icon width={40} height={40} name={closeIcon ? closeIcon : "Close"} />
        </a>
      );
    return "";
  };

  const renderProfileIcon = (initial: string) => {
    return <SideBarNavigation iconLetter={initial} />;
  };

  const renderHeaderRight = () => {
    return (
      <div className={styles.HeaderRight}>
        {/* Accept a full name bit reduce to first letter */}
        {userInitial ? renderProfileIcon(userInitial[0]) : renderCloseButton()}
      </div>
    );
  };

  const renderHeaderCenter = () =>
    title ? (
      <div
        className={classNames(
          styles.HeaderCenter,
          (hideBackButton || !onPrev) && styles.HeaderCenterLeft
        )}
      >
        {typeof title === "string" ? (
          <TextSmall className={styles.Title}>{title}</TextSmall>
        ) : (
          title
        )}
      </div>
    ) : null;

  return (
    <>
      {process.env.REACT_APP_ENV !== ENVIRONMENTS.production && (
        <Helmet>
          <script>
            {`window.markerConfig = {
              destination: "601ebaacfc9988758e164953",
            };`}
          </script>
          <script>
            {`!(function (e, r, t) {
            if (e.__Marker) return;
            e.__Marker = {};
            var n = r.createElement("script");
            (n.async = 1), (n.src = "https://edge.marker.io/latest/shim.js");
            var s = r.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(n, s);
          })(window, document);`}
          </script>
        </Helmet>
      )}
      {process.env.REACT_APP_ENV === ENVIRONMENTS.production && (
        <Helmet>
          <script>
            {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2467430,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
          </script>
        </Helmet>
      )}
      {process.env.REACT_APP_ENV === ENVIRONMENTS.uat && (
        <Helmet>
          <script>
            {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2467218,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
          </script>
        </Helmet>
      )}

      <header className={styles.HeaderWrapper}>
        {renderHeaderLeft()}
        {renderHeaderCenter()}
        {renderHeaderRight()}
      </header>
    </>
  );
}

export default memo(Header);
