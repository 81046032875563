import React from "react";

import classnames from "classnames";

import styles from "./index.module.scss";

import Icon from "~/components/Icon";
import Dialogue from "~/components/Dialogue";

import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";

export default function Assistant({ className }: { className?: string }) {
  const currentAssistant = useAssistantContext();

  React.useEffect(() => {
    if (currentAssistant.isOpen) {
      // ToDo: Set this dynamically with true height of assistant
      document.documentElement.style.setProperty(
        "--footer-height",
        "var(--assistant-height)"
      );
    } else {
      document.documentElement.style.setProperty("--footer-height", "0px");
    }
  }, [currentAssistant.isOpen]);

  if (!currentAssistant.text) return null;

  return (
    <div className={classnames(styles.Wrapper, className)}>
      <button
        className={classnames(styles.IconWrapper, {
          [styles.AllowPointerEvents]: currentAssistant.isOpen,
        })}
        type="button"
        onMouseDown={(event) => event.preventDefault()}
        onClick={() =>
          currentAssistant.updateAssistant({
            showTextOnMobile: !currentAssistant.showTextOnMobile,
          })
        }
      >
        <Icon
          name={currentAssistant.text ? "AssistantInfo" : "AssistantHelp"}
          className={currentAssistant.isOpen ? styles.Visible : styles.Hidden}
        />
      </button>
      <Dialogue
        className={classnames(
          currentAssistant.text && currentAssistant.isOpen
            ? styles.VisibleScaleIn
            : styles.HiddenScaleOut,
          {
            [styles.HiddenScaleOutOnMobile]:
              currentAssistant.text &&
              currentAssistant.isOpen &&
              !currentAssistant.showTextOnMobile,
          }
        )}
        text={currentAssistant.text}
      />
    </div>
  );
}
