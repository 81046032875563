import React from "react";

import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { H3, Text, Bold } from "~/components/Typography";
import NoteWithList from "~/components/NoteWithList";
import SubmitButton from "~/components/SubmitButton";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import { InsuranceType } from "../../../types";
import pushWithParams from "~/helpers/pushWithParams";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

export default function QuickNote({
  insuranceType,
  nextPath,
}: {
  insuranceType: InsuranceType;
  nextPath?: string;
}) {
  const history = useHistory();
  const assistantCtx = useAssistantContext();
  const insuranceTypeString = insuranceType as string;

  useMountEffect(() => {
    assistantCtx.updateAssistant({
      isOpen: false,
    });
  });

  return (
    <div className={styles.QuickNoteWrapper}>
      <Text>
        You&apos;ve chosen
        <Bold>
          {` ${insuranceTypeString[0].toUpperCase()}${insuranceTypeString.substring(
            1
          )}`}{" "}
          Insurance
        </Bold>
      </Text>
      <H3 className={styles.Subtitle} component="h1">
        Just a quick note before you continue...
      </H3>
      <NoteWithList
        className={styles.Note}
        mainText={
          "You're about to get a free insurance quote. To complete your home coverage, you’ll need to upload a few things:"
        }
        list={[
          "Photo of your National ID or passport",
          "Proof of address (e.g. a utility bill or bank statement with your address)",
        ]}
      />
      <div className={styles.ButtonNextWrapper}>
        <SubmitButton
          onClick={() => (nextPath ? pushWithParams(history, nextPath) : null)}
          className={styles.BtnNext}
        >
          Ok, got it!
        </SubmitButton>
      </div>
    </div>
  );
}
