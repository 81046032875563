import React from "react";

import Button from "~/components/Button";
import { H4 } from "~/components/Typography";
import UploadImg from "~/assets/images/illustration-documentupload.png";

import styles from "./index.module.scss";

type Props = {
  message?: string;
  error?: string;
  onConfirm: () => void;
  onCancel: () => void;
  onConfirmButton?: string;
  onCancelButton?: string;
};

export default function EnableCameraDialog(props: Props) {
  const {
    message = "Are you sure you want to exit?",
    error,
    onConfirm,
    onCancel,
    onConfirmButton,
    onCancelButton,
  } = props;
  return (
    <div className={styles.EnableCameraDialogWrapper}>
      <img
        src={UploadImg}
        className={styles.ModalTopIcon}
        alt="a camera icon"
      />

      <H4>{error ?? message}</H4>
      <div className={styles.ButtonGroup}>
        {onCancelButton && (
          <Button
            variant="tertiary"
            size="small"
            className={styles.Btn}
            onClick={() => onCancel()}
          >
            {onCancelButton}
          </Button>
        )}
        {!error && onConfirmButton && (
          <Button
            size="small"
            className={styles.Btn}
            onClick={() => onConfirm()}
          >
            {onConfirmButton}
          </Button>
        )}
      </div>
    </div>
  );
}
