import React, { useEffect, useReducer, useState } from "react";
import { gql } from "apollo-boost";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { isAfter } from "date-fns";
import UploadImg from "~/assets/images/upload-mobile.png";

import {
  H3,
  PreTextExtraSmall,
  Text,
  TextExtraSmall,
} from "~/components/Typography";
import SelectableCard from "~/components/SelectableCard";

import styles from "./index.module.scss";
import PhotoFileInput from "~/components/FileInput/PhotoFileInput";
import PhotoOrDocumentFileInput from "~/components/FileInput/PhotoOrDocumentFileInput";
import type { FileType } from "~/components/FileInput/utils";
import Modal from "~/components/Modal";

import GridWrapper from "~/components/GridWrapper";
import Button from "~/components/Button";
import MobileUploadRequired from "~/components/MobileUploadRequired";

import {
  DOCUMENT_TYPES,
  VERIFIABLE_DOCUMENTS_TYPES,
  VERIFICATION_STATUS,
} from "~/helpers/constants";
import pushWithParams from "~/helpers/pushWithParams";
import { localDateFromIso, localDateToday } from "~/helpers/dates";
import getPolicyCodeFromInsuranceType from "~/helpers/getPolicyCodeFromInsuranceType";
import { InsuranceType } from "../../../types";
import LoadingWrapper from "~/components/LoadingWrapper";
import { HeapEventName, useHeapContext } from "~/contexts/HeapProvider";
import hasErrorCode from "~/helpers/hasErrorCode";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

type DocumentPart = {
  documentType: string;
  name?: string;
  accept?: string;
  fileName?: string;
  context?: string;
  uploaded?: boolean;
  validUntil?: string;
  verificationStatus?: string;
  error?: string;
  preMessage?: string;
  validExtensions?: string[];
};

export type DocumentWrapper = {
  name: string;
  header?: string;
  subHeader?: string;
  description: string;
  shortDescription?: string;
  documentParts?: DocumentPart[];
  optionalDocuments?: DocumentWrapper[];
  minNumberOfOptionalDocs?: number;
  verify?: boolean;
  errors?: string[];
};

type RecoveredDocument = {
  documentType: string;
  context: string;
  countryCode: string;
  fileName: string;
  validUntil: string;
  verificationReady: boolean;
  verificationStatus: string;
};

type Props = {
  documentWrappersList: DocumentWrapper[];
  policyId: string;
  email: string;
  insuranceType: InsuranceType;
  nextPath?: string;
};

const GET_DOCUMENTS = gql`
  query getDocuments {
    getDocuments {
      documentType
      context
      countryCode
      fileName
      validUntil
      verificationReady
      verificationStatus
    }
  }
`;

const UPLOAD_DOCUMENT = gql`
  mutation uploadDocument($policyId: String!, $document: DocumentFileInput!) {
    uploadDocument(policyId: $policyId, document: $document)
  }
`;

const TRIGGER_VERIFICATION = gql`
  mutation triggerVerification(
    $policyObject: String!
    $lastUploadedVerifiableDocumentType: String!
  ) {
    triggerVerification(
      policyObject: $policyObject
      lastUploadedVerifiableDocumentType: $lastUploadedVerifiableDocumentType
    )
  }
`;

const documentsListReducer = (
  documentsList: DocumentWrapper[],
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: { type: string; payload?: any }
): DocumentWrapper[] => {
  if (action.type === "replace") {
    return action.payload;
  }

  if (action.type === "updateDocumentPart") {
    const newDocumentPart: DocumentPart = action.payload;
    const { documentType, context } = newDocumentPart;

    return documentsList.map((requiredDocument: DocumentWrapper) => ({
      ...requiredDocument,
      documentParts: requiredDocument.documentParts?.map(
        (documentPart: DocumentPart) =>
          documentPart.documentType === documentType &&
          documentPart.context === context
            ? newDocumentPart
            : documentPart
      ),
      errors: undefined,
    }));
  }

  if (action.type === "updateDocumentPartOptional") {
    const newDocumentPart = action.payload;
    const { documentType, context } = newDocumentPart;

    return documentsList.map((requiredDocument: DocumentWrapper) => ({
      ...requiredDocument,
      optionalDocuments: requiredDocument.optionalDocuments?.map(
        (optionalDocumentWrapper: DocumentWrapper) =>
          optionalDocumentWrapper.documentParts?.[0]?.documentType ===
          newDocumentPart.documentType
            ? {
                ...optionalDocumentWrapper,
                documentParts: optionalDocumentWrapper.documentParts?.map(
                  (documentPart: DocumentPart) =>
                    documentPart.documentType === documentType &&
                    documentPart.context === context
                      ? newDocumentPart
                      : documentPart
                ),
                errors: undefined,
              }
            : optionalDocumentWrapper
      ),
    }));
  }

  if (action.type === "resetErrors") {
    return documentsList.map((requiredDocument: DocumentWrapper) => ({
      ...requiredDocument,
      documentParts: requiredDocument.documentParts?.map(
        (documentPart: DocumentPart) => ({
          ...documentPart,
          error: "",
        })
      ),
    }));
  }
  throw new Error("Unrecognized action");
};

const documentIsValid = (doc: RecoveredDocument | DocumentPart) =>
  !!doc.validUntil &&
  !isAfter(localDateToday(), localDateFromIso(doc.validUntil));

const hasNeededDocs = (item: DocumentWrapper) => {
  if (item.documentParts) {
    // Wrapper defines set of (mandatory) document parts
    return item.documentParts.every((part) => part.uploaded);
  } else if (item.optionalDocuments) {
    // Wrapper defines optional set of document wrappers
    return (
      item.optionalDocuments.reduce((acc, optDoc) => {
        if (hasNeededDocs(optDoc)) {
          return acc + 1;
        }
        return acc;
      }, 0) >= (item.minNumberOfOptionalDocs as number)
    );
  } else {
    return false;
  }
};
const didVeriffFail = (item: DocumentWrapper) => {
  return (
    item.verify &&
    item.documentParts?.length &&
    !!item.documentParts[0].verificationStatus &&
    item.documentParts[0].verificationStatus !== VERIFICATION_STATUS.started &&
    item.documentParts[0].verificationStatus !== VERIFICATION_STATUS.approved
  );
};
const didVeriffExpire = (item: DocumentWrapper) => {
  if (
    item.verify &&
    item.documentParts?.length &&
    item.documentParts[0].validUntil
  ) {
    return !documentIsValid(item.documentParts[0]);
  } else {
    return false;
  }
};
const hadPreviousVeriffIssue = (item: DocumentWrapper): boolean => {
  if (item.documentParts) {
    // Wrapper defines set of (mandatory) document parts
    return didVeriffFail(item) || didVeriffExpire(item);
  } else if (item.optionalDocuments) {
    // Wrapper defines optional set of document wrappers
    return item.optionalDocuments.some((optDoc) =>
      hadPreviousVeriffIssue(optDoc)
    );
  } else {
    return false;
  }
};
let lastUploadedVerifiableDocumentType: string | null = null;

const DocumentUpload = ({
  documentWrappersList,
  nextPath,
  email,
  insuranceType,
  policyId,
}: Props) => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const [openModal, setOpenModal] = useState("");
  // TODO: move this state and related logic to the reducer state
  const [selectedDocument, setSelectedDocument] = useState<DocumentWrapper>();
  // TODO: move this state and related logic to the reducer state
  const [
    selectedItemWithOptinalDocuments,
    setSelectedItemWithOptinalDocuments,
  ] = useState<DocumentWrapper>();

  const [loading, setLoading] = useState<boolean>(true);
  const [showMobile, setShowMobile] = React.useState<boolean>(false);
  const [complete, setComplete] = React.useState<boolean>(false);
  const [, setError] = useState();

  const [documentsList, dispatchDocumentsList] = useReducer(
    documentsListReducer,
    documentWrappersList
  );

  const [
    successfulVerificationDocumentUpload,
    setSuccessfulVerificationDocumentUpload,
  ] = useState<boolean>();

  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);

  const [
    triggerVerification,
    { loading: loadingTriggerVerification },
  ] = useMutation(TRIGGER_VERIFICATION);

  const heapCtx = useHeapContext();

  const rehydrateDocument = (
    document: DocumentWrapper,
    prevUpedDocuments: RecoveredDocument[]
  ) => {
    for (const documentPart of document.documentParts as DocumentPart[]) {
      const prevUpedDoc = prevUpedDocuments.find(
        (prevUpedDoc: DocumentPart) => {
          return (
            documentPart.documentType === prevUpedDoc.documentType &&
            (!documentPart.context ||
              documentPart.context === prevUpedDoc.context)
          );
        }
      );
      if (prevUpedDoc) {
        if (
          document.verify &&
          (prevUpedDoc.verificationStatus ||
            documentPart.documentType === DOCUMENT_TYPES.face)
        ) {
          if (
            prevUpedDoc.verificationStatus === VERIFICATION_STATUS.started ||
            prevUpedDoc.documentType === DOCUMENT_TYPES.face
          ) {
            documentPart.uploaded = true;
            documentPart.fileName = prevUpedDoc.fileName;
          } else if (
            prevUpedDoc.verificationStatus === VERIFICATION_STATUS.approved
          ) {
            const isValid = prevUpedDoc.validUntil
              ? documentIsValid(prevUpedDoc)
              : true;
            documentPart.uploaded = isValid;
            documentPart.fileName = isValid ? prevUpedDoc.fileName : "";
            documentPart.validUntil = prevUpedDoc.validUntil;
          }
          documentPart.verificationStatus = prevUpedDoc.verificationStatus;
        } else {
          const isValid = prevUpedDoc.validUntil
            ? documentIsValid(prevUpedDoc)
            : true;
          documentPart.uploaded = isValid;
          documentPart.fileName = isValid ? prevUpedDoc.fileName : "";
        }
      }
    }
  };

  const validateDocuments = () => {
    const resetSelfie = () => {
      const selfieWrapper = documentsList.find(
        (docWrapper) =>
          docWrapper.documentParts?.[0].documentType === DOCUMENT_TYPES.face
      );
      if (selfieWrapper?.documentParts?.length) {
        selfieWrapper.documentParts[0].uploaded = false;
        const errMsg = "Your Selfie needs to be reuploaded.";
        selfieWrapper.errors = [errMsg];
      }
    };
    documentsList.forEach((documentWrapper) => {
      if (documentWrapper.documentParts) {
        const documentPart = documentWrapper.documentParts[0];
        if (
          documentWrapper.verify &&
          !documentPart.uploaded &&
          documentPart.documentType !== DOCUMENT_TYPES.face
        ) {
          resetSelfie();
        }
      } else if (documentWrapper.optionalDocuments) {
        if (
          !documentWrapper.optionalDocuments.some((optDoc) => {
            if (!optDoc.verify) return true;
            const isValid =
              optDoc.documentParts?.length &&
              (optDoc.documentParts[0].verificationStatus ===
                VERIFICATION_STATUS.started ||
                (optDoc.documentParts[0].uploaded &&
                  (!optDoc.documentParts[0].validUntil ||
                    documentIsValid(optDoc.documentParts[0]))));

            if (didVeriffFail(optDoc)) {
              const errMsg = `Your ${optDoc.name} needs to be reuploaded.`;
              optDoc.errors = optDoc.errors
                ? !optDoc.errors.includes(errMsg)
                  ? [...optDoc.errors, errMsg]
                  : optDoc.errors
                : [errMsg];
              return false;
            }
            if (didVeriffExpire(optDoc)) {
              const errMsg = `Your ${optDoc.name} has expired.`;
              optDoc.errors = !optDoc.errors
                ? [errMsg]
                : [...optDoc.errors, errMsg];
              return false;
            }

            return isValid;
          })
        ) {
          resetSelfie();
        }
      }
    });
  };

  const fetchUploadedDocuments = async () => {
    setLoading(true);

    try {
      const { data } = await apolloClient.query({
        query: GET_DOCUMENTS,
        variables: {
          policyId,
        },
        fetchPolicy: "no-cache",
      });

      const prevUpedDocuments = data?.getDocuments;

      if (prevUpedDocuments && prevUpedDocuments.length) {
        for (const document of documentsList) {
          if (document.documentParts) {
            rehydrateDocument(document, prevUpedDocuments);
          } else if (document.optionalDocuments) {
            document.optionalDocuments.forEach((optionalDocument) => {
              rehydrateDocument(optionalDocument, prevUpedDocuments);
            });
          }
        }
        validateDocuments();
        dispatchDocumentsList({ type: "replace", payload: [...documentsList] });
      }
    } catch (error) {
      // Make error boundary catch this
      setError(() => {
        throw error;
      });
    }

    setLoading(false);
  };

  useMountEffect(() => {
    fetchUploadedDocuments();
  });

  useEffect(() => {
    if (nextPath && complete && !loadingTriggerVerification) {
      pushWithParams(history, nextPath);
    }
  }, [nextPath, complete, loadingTriggerVerification, history]);

  const handleClick = (item: DocumentWrapper) => {
    if (openModal === "optionalDocsOpen" && item.documentParts) {
      setOpenModal("optionalDocOpen");
      setSelectedDocument(item);
      dispatchDocumentsList({
        type: "resetErrors",
      });
    } else if (item.documentParts) {
      setOpenModal("open");
      setSelectedDocument(item);
      dispatchDocumentsList({
        type: "resetErrors",
      });
    } else if (item.optionalDocuments) {
      setOpenModal("optionalDocsOpen");
      setSelectedItemWithOptinalDocuments(item);
      dispatchDocumentsList({
        type: "resetErrors",
      });
    }
  };

  const closeCurrentModal = () => {
    setSelectedDocument(undefined);
    if (openModal === "optionalDocOpen") {
      setOpenModal("optionalDocsOpen");
    } else {
      setOpenModal("");
    }
  };

  const verifyDocument = async () => {
    try {
      const { data } = await triggerVerification({
        variables: {
          policyObject: getPolicyCodeFromInsuranceType(insuranceType),
          lastUploadedVerifiableDocumentType,
        },
      });
      setSuccessfulVerificationDocumentUpload(data.triggerVerification);
    } catch (err) {
      // Ignore the error if the user hasn't uploaded enough files yet
      if (!hasErrorCode(err, ["FILES_INCOMPLETE", "BEING_VERIFIED"])) {
        setError(() => {
          throw err;
        });
      }
    }
  };

  const handleUpload = async (item: DocumentPart, fileObject: FileType) => {
    const isRequredDocumentUpload = openModal !== "optionalDocOpen";

    const { base64Contents, fileName, mimeType } = fileObject;

    const {
      documentType,
      context,
      fileName: originalFileName,
      uploaded: originalUploaded,
    } = item;

    dispatchDocumentsList({
      type: "updateDocumentPart",
      payload: { ...item, uploaded: false, fileName, error: "" },
    });

    try {
      await uploadDocument({
        variables: {
          policyId,
          document: {
            contents: base64Contents,
            mimeType,
            documentType,
            context,
            fileName,
          },
        },
      });

      //Wrapping in previousState to avoid race conditions
      setSelectedDocument((previousState) => {
        return {
          ...(previousState as DocumentWrapper),
          documentParts: previousState?.documentParts?.map((docPart) => {
            if (
              item.documentType === docPart.documentType &&
              item.context === docPart.context
            ) {
              return { ...item, uploaded: true };
            }

            return docPart;
          }),
        };
      });

      if (isRequredDocumentUpload) {
        dispatchDocumentsList({
          type: "updateDocumentPart",
          payload: { ...item, uploaded: true },
        });
      } else {
        dispatchDocumentsList({
          type: "updateDocumentPartOptional",
          payload: { ...item, uploaded: true },
        });

        setSelectedItemWithOptinalDocuments((previousState) => {
          return {
            ...(previousState as DocumentWrapper),
            optionalDocuments: previousState?.optionalDocuments?.map(
              (optionalDocumentWrapper: DocumentWrapper) =>
                optionalDocumentWrapper.documentParts?.[0]?.documentType ===
                item.documentType
                  ? {
                      ...optionalDocumentWrapper,
                      documentParts: optionalDocumentWrapper.documentParts?.map(
                        (documentPart: DocumentPart) =>
                          documentPart.documentType === documentType &&
                          documentPart.context === context
                            ? { ...item, uploaded: true }
                            : documentPart
                      ),
                      errors: undefined,
                    }
                  : optionalDocumentWrapper
            ),
          };
        });
      }

      if (documentType === DOCUMENT_TYPES.face) {
        heapCtx.track(HeapEventName.ONBOARDING_UPLOAD_SELFIE, {
          "Policy ID": policyId,
        });
      } else {
        heapCtx.track(HeapEventName.ONBOARDING_UPLOAD_DOCUMENT, {
          "Policy ID": policyId,
          "ID/Document Type": documentType,
        });
      }

      if (selectedDocument?.verify && selectedDocument.documentParts?.length) {
        const uploadedDocumentType =
          selectedDocument.documentParts[0].documentType;

        lastUploadedVerifiableDocumentType =
          uploadedDocumentType === DOCUMENT_TYPES.face
            ? lastUploadedVerifiableDocumentType
            : uploadedDocumentType;

        if (
          lastUploadedVerifiableDocumentType &&
          !successfulVerificationDocumentUpload &&
          (selectedDocument.documentParts.some((docPart) => docPart.uploaded) ||
            selectedDocument.documentParts.length === 1)
        ) {
          verifyDocument();
        }
      }
    } catch (error) {
      if (hasErrorCode(error, ["BEING_VERIFIED", "VERIFIED"])) {
        if (isRequredDocumentUpload) {
          dispatchDocumentsList({
            type: "updateDocumentPart",
            payload: {
              ...item,
              uploaded: originalUploaded,
              fileName: originalFileName,
              error: error.message,
            },
          });
        } else {
          setSelectedDocument((previousState) => {
            return {
              ...(previousState as DocumentWrapper),
              documentParts: previousState?.documentParts?.map((docPart) => {
                if (
                  item.documentType === docPart.documentType &&
                  item.context === docPart.context
                ) {
                  return { ...item, error: error.message };
                }

                return docPart;
              }),
            };
          });
        }
        throw error.message;
      } else {
        // Make error boundary catch this
        // Update: Error is caught by FileInput components.
        throw error;
      }
    }
  };

  const handleDoneClick = () => {
    heapCtx.track(HeapEventName.ONBOARDING_IDENTITY_SUBMIT, {
      "Policy ID": policyId,
    });

    setComplete(true);
  };

  useMountEffect(() => {
    if (!isMobile) {
      setShowMobile(true);
    } else {
      heapCtx.track(HeapEventName.ONBOARDING_DOCUMENT_UPLOAD_SCREEN, {
        "Policy ID": policyId,
      });
    }
  });

  if (showMobile) {
    return (
      <>
        <MobileUploadRequired
          title="To complete the process and get your coverage plan, you will need a
        smartphone."
          email={email}
          policyId={policyId}
        />
      </>
    );
  }

  return (
    <>
      <LoadingWrapper
        loading={loading || (complete && loadingTriggerVerification)}
      />

      {!loading && !(complete && loadingTriggerVerification) && (
        <>
          <H3 className={styles.Message} component="h1">
            Upload the following documents to verify your identity.
          </H3>

          <Text className={styles.Message}>
            Please complete your verification before your coverage start date.
            Document images are sufficient. Scanner not required.
          </Text>

          <div className={styles.Contents}>
            {documentsList.map((item, i) => {
              const selected =
                hasNeededDocs(item) && !hadPreviousVeriffIssue(item);

              return (
                <div
                  key={`DocumentWrappers-SelectableCard-${i}`}
                  className={styles.SelectableCardWrapper}
                >
                  <SelectableCard
                    title={item.name}
                    description={
                      selected ? "Successfully uploaded" : item.shortDescription
                    }
                    ctaText={
                      (item.documentParts &&
                        item.documentParts.some((part) => part.uploaded)) ||
                      (item.optionalDocuments &&
                        item.optionalDocuments
                          .reduce((acc: boolean[], optDoc) => {
                            acc.push(
                              !!optDoc.documentParts &&
                                optDoc.documentParts.some(
                                  (part) => part.uploaded
                                )
                            );
                            return acc;
                          }, [])
                          .some((uploaded: boolean) => uploaded))
                        ? "Change"
                        : undefined
                    }
                    selected={selected}
                    onClick={() => handleClick(item)}
                    headingComponent="h2"
                    className={styles.SelectableCard}
                  />

                  {item?.errors?.length && (
                    <PreTextExtraSmall className={styles.DocWrapperError}>
                      {item.errors?.length && item.errors.join("\n")}
                      {item.optionalDocuments?.some(
                        (optDoc) => optDoc.errors?.length
                      )
                        ? item.optionalDocuments
                            .map((optDoc) =>
                              optDoc.errors ? optDoc.errors : []
                            )
                            .flat()
                            .join("\n")
                        : null}
                    </PreTextExtraSmall>
                  )}
                </div>
              );
            })}
            <div className={styles.NextBtnWrapper}>
              <Button
                variant="primary"
                onClick={handleDoneClick}
                className={styles.NextBtn}
                disabled={
                  !documentsList.every(
                    (item) =>
                      hasNeededDocs(item) &&
                      (!hadPreviousVeriffIssue(item) ||
                        successfulVerificationDocumentUpload)
                  )
                }
              >
                Continue
              </Button>
            </div>
          </div>

          <Modal
            isOpen={
              (openModal === "optionalDocsOpen" ||
                openModal === "optionalDocOpen") &&
              !!selectedItemWithOptinalDocuments
            }
            onClose={closeCurrentModal}
            title={selectedItemWithOptinalDocuments?.name}
            shouldOverlayCloseOnClick
            id="select-document-modal"
          >
            <GridWrapper contentClassName={styles.GridWrapper}>
              {selectedItemWithOptinalDocuments?.header && (
                <H3 className={styles.Message}>
                  {selectedItemWithOptinalDocuments.header}
                </H3>
              )}

              {selectedItemWithOptinalDocuments?.subHeader && (
                <Text className={styles.Message}>
                  {selectedItemWithOptinalDocuments.subHeader}
                </Text>
              )}

              <div className={styles.Contents}>
                {selectedItemWithOptinalDocuments?.optionalDocuments &&
                  selectedItemWithOptinalDocuments?.optionalDocuments.map(
                    (item, i) => {
                      const selected =
                        !!item.documentParts &&
                        item.documentParts.every((part) => part.uploaded);

                      return (
                        <div
                          key={`DocumentWrappers-SelectableCard-${i}`}
                          className={styles.SelectableCardWrapper}
                        >
                          <SelectableCard
                            title={item.name}
                            description={
                              selected
                                ? "Successfully uploaded"
                                : item.description
                            }
                            ctaText={
                              item.documentParts &&
                              item.documentParts.some((part) => part.uploaded)
                                ? "Change"
                                : undefined
                            }
                            selected={selected}
                            className={styles.SelectableCard}
                            onClick={() => handleClick(item)}
                            headingComponent="h2"
                          />

                          {item?.errors?.length && (
                            <PreTextExtraSmall
                              className={styles.DocWrapperError}
                            >
                              {item.errors?.join("\n")}
                            </PreTextExtraSmall>
                          )}
                        </div>
                      );
                    }
                  )}
                <div className={styles.NextBtnWrapper}>
                  <Button
                    variant="primary"
                    onClick={closeCurrentModal}
                    className={styles.NextBtn}
                    disabled={
                      !!selectedItemWithOptinalDocuments?.optionalDocuments &&
                      !hasNeededDocs(selectedItemWithOptinalDocuments)
                    }
                  >
                    Done
                  </Button>
                </div>
              </div>
            </GridWrapper>
          </Modal>

          <Modal
            isOpen={
              (openModal === "open" || openModal === "optionalDocOpen") &&
              !!selectedDocument
            }
            onClose={closeCurrentModal}
            title={selectedDocument?.name}
            shouldOverlayCloseOnClick
            id="select-document-modal"
          >
            <GridWrapper>
              <div className={styles.ModalContents}>
                <div className={styles.ModalTop}>
                  <img
                    src={UploadImg}
                    className={styles.ModalTopIcon}
                    alt="a camera icon"
                  />

                  <div className={styles.ModalTopText}>
                    <H3>{selectedDocument?.name}</H3>
                    <Text>{selectedDocument?.description}</Text>
                  </div>
                </div>

                <div className={styles.CTAs}>
                  {selectedDocument?.documentParts &&
                    selectedDocument?.documentParts.map((item, i) => {
                      const {
                        name,
                        accept,
                        uploaded,
                        error,
                        fileName,
                        documentType,
                        preMessage,
                        validExtensions,
                        verificationStatus,
                      } = item;

                      const sharedProps = {
                        preMessage,
                        key: `DocumentWrappers-FileInput-${i}`,
                        title: name as string,
                        onFileSelect: async (fileObject: FileType) => {
                          await handleUpload(item, fileObject);
                        },
                        initialFilename:
                          verificationStatus === VERIFICATION_STATUS.started
                            ? "This document is being verified, and does not need to be re-submitted."
                            : fileName,
                        initialUploaded: uploaded ?? false,
                        initialError: error,
                        disabledUpload:
                          verificationStatus === VERIFICATION_STATUS.started ||
                          loadingTriggerVerification,
                      };

                      if (VERIFIABLE_DOCUMENTS_TYPES.includes(documentType))
                        return (
                          <PhotoFileInput
                            {...sharedProps}
                            defaultFacingMode={
                              documentType === DOCUMENT_TYPES.face
                                ? "user"
                                : "environment"
                            }
                          />
                        );
                      else
                        return (
                          <PhotoOrDocumentFileInput
                            {...sharedProps}
                            defaultFacingMode={
                              documentType === DOCUMENT_TYPES.face
                                ? "user"
                                : "environment"
                            }
                            accept={accept}
                            validExtensions={validExtensions}
                          />
                        );
                    })}
                </div>

                {selectedDocument?.documentParts?.[0].documentType ===
                  DOCUMENT_TYPES.proofAddress && (
                  <TextExtraSmall className={styles.FileTypeMessage}>
                    Files must be .pdf, .jpg, .jpeg, or .png format under 13MB
                    in size.
                  </TextExtraSmall>
                )}

                <div className={styles.BtnWrapper}>
                  <Button
                    variant="primary"
                    onClick={closeCurrentModal}
                    disabled={
                      (selectedDocument?.documentParts &&
                        selectedDocument.documentParts.some(
                          (part) => !part.uploaded
                        )) ||
                      loadingTriggerVerification
                    }
                  >
                    {loadingTriggerVerification
                      ? "Sending for verification"
                      : "Done"}
                  </Button>
                </div>
              </div>
            </GridWrapper>
          </Modal>
        </>
      )}
    </>
  );
};

export default DocumentUpload;
