import React from "react";
import cx from "classnames";
import ReactModal from "react-modal";
import ButtonIcon from "~/components/ButtonIcon";
import { H4 } from "~/components/Typography";

import styles from "./index.module.scss";
import "./animations.scss";

type PropsType = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  overlayClassName?: string;
  contentClassName?: string;
  className?: string;
  title?: string;
  renderHeader?: React.ReactNode;
  noOffset?: boolean;
  hasDescription?: boolean;
  id?: string;
  shouldOverlayCloseOnClick: boolean;
};

ReactModal.setAppElement("#root");
ReactModal.defaultStyles.content = {};

/*
  Modals:
  ReactModal uses the portal functionality of React to render the component at the root of the DOM,
  outside of the scope of React. It isn't rendered in semantic order. To implement a modal, just
  wrap your content with the <Modal> element at the bottom of your page component, or in some other
  component. Open/Closed state is managed in the ModalContextProvider. The className prop is passed
  to the Overlay of the modal, allowing you to position it wherever you want and control the margin.
*/

/*
   When a modal is open, it adds its children to the virtual DOM. When a modal
   is closed, it removes its children from the virtual DOM. It is important to
   ensure that in the case of nested modals, the parent modal must remain open
   for the child to be open.

   In order to enable nested modal functionality (e.g.
     <Modal>
       <Modal>
         <div />
       </Modal>
     </Modal>
   ), information about a modal's children must be accessible. A React context
   is used to do so.

   The context provides child modals access to the parent modal's `childIsOpen`
   state. The child modals then set the parent modal's state to inform the
   parent modal that a child modal is open. When a child modal is open, the
   parent modal will stay open.
 */
const initialModalContext: {
  childIsOpen: boolean;
  setChildIsOpen: (arg0: boolean) => void;
} = {
  childIsOpen: false,
  setChildIsOpen: () => {
    throw new Error("Modal context is not initialized");
  },
};

// Since every modal requires a provider, we can wrap the application with a dummy provider
const ModalContext = React.createContext(initialModalContext);
const useModalContext = () => React.useContext(ModalContext);
export const ModalProvider = ({ children }: { children: React.ReactNode }) => (
  <ModalContext.Provider
    value={{ childIsOpen: false, setChildIsOpen: () => {} }}
  >
    {children}
  </ModalContext.Provider>
);

const Modal = ({
  isOpen,
  children,
  className,
  overlayClassName,
  contentClassName,
  onClose,
  title,
  renderHeader,
  shouldOverlayCloseOnClick,
  noOffset = false,
  hasDescription,
  id,
  ...rest
}: PropsType) => {
  const parentContext = useModalContext();
  const [childIsOpen, setChildIsOpen] = React.useState(false);
  const selfIsOpen = childIsOpen ? true : isOpen;

  React.useEffect(() => parentContext.setChildIsOpen(selfIsOpen), [
    selfIsOpen,
    parentContext,
  ]);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  // Every modal has its own context. The child modal will access the context of the closest parent modal
  return (
    <ModalContext.Provider value={{ childIsOpen, setChildIsOpen }}>
      <ReactModal
        isOpen={selfIsOpen}
        id={id}
        closeTimeoutMS={300}
        className={cx(styles.Modal, className, noOffset && "NoOffset")}
        overlayClassName={cx(styles.Overlay, overlayClassName)}
        bodyOpenClassName={styles.Open}
        shouldCloseOnOverlayClick={shouldOverlayCloseOnClick}
        onRequestClose={handleOnClose}
        aria={{
          labelledby: `modal-title-${id}`,
          describedby: hasDescription ? `modal-desc-${id}` : undefined,
        }}
        {...rest}
      >
        {!renderHeader ? (
          <div className={styles.HeaderWrapper}>
            <H4 className={styles.ModalTitle}>
              <span id={`modal-title-${id}`}>{title}</span>
            </H4>
            <ButtonIcon
              iconName="ArrowDown"
              className={styles.ArrowDown}
              onClick={handleOnClose}
              ariaLabel="close modal"
              tabIndex={-1}
            />
          </div>
        ) : (
          renderHeader
        )}

        <div className={cx(styles.Content, contentClassName)}>{children}</div>
      </ReactModal>
    </ModalContext.Provider>
  );
};

export default Modal;
