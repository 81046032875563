import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { H3 } from "~/components/Typography";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useFieldState } from "informed";
import styles from "./index.module.scss";
import hasErrorCode from "~/helpers/hasErrorCode";

const CANCEL_PAYMENT = gql`
  mutation adminCancelRecurringPayment($policyId: String!) {
    adminCancelRecurringPayment(policyId: $policyId)
  }
`;

type Props = {
  onSuccess: (successMessage?: string) => void;
  onError: (errorMessage: string | undefined, errorCode?: string) => void;
};

const AdminCancelRecurringPayment = ({ onSuccess, onError }: Props) => {
  const idFieldName = "AdminCancelRecurringPayment-PolicyIdField";
  const idFieldState = useFieldState(idFieldName);
  const [cancelPayment] = useMutation(CANCEL_PAYMENT);
  const [processing, setProcessing] = React.useState<boolean>(false);

  const handleSubmit = async () => {
    setProcessing(true);
    onSuccess(undefined);
    onError(undefined);

    try {
      const response = await cancelPayment({
        variables: {
          policyId: String(idFieldState.value),
        },
      });

      setProcessing(false);

      if (response?.data?.adminCancelRecurringPayment) {
        onSuccess("Operation Successful!");
      }
    } catch (error) {
      onError(
        error.message,
        hasErrorCode(error, "NOT_ADMIN") ? "NOT_ADMIN" : undefined
      );

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      <H3>Cancel Payment</H3>

      <TextInput
        type="text"
        label={"Policy ID"}
        placeholder="AL-000000-AAAAAA"
        field={idFieldName}
      />

      <Button
        disabled={!idFieldState.value || processing}
        onClick={handleSubmit}
      >
        {processing ? "Processing..." : "Process"}
      </Button>
    </div>
  );
};

export default AdminCancelRecurringPayment;
