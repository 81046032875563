import { useFormState } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import LoadingWrapper from "~/components/LoadingWrapper";
import TextInput from "~/components/TextInput";
import { H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./style.module.scss";

type Props = {
  nextPath?: string;
};

export default function EmployerName({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const formState = useFormState();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const employerNameField = "employerName";
  const employerAddressField = "employerAddress";
  const [showEmployerAddress, setShowEmployerAddress] = React.useState(false);
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 4,
      title: "Occupation",
    });
  });

  const handleOnNext = () => {
    setShowEmployerAddress(true);
  };

  const handleOnContinue = async () => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: String(checklistCtx.policyId),
          employerName: String(formState.values[employerNameField]) ?? null,
          employerAddress:
            String(formState.values[employerAddressField]) ?? null,
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <Text>We need a few more details about your occupation.</Text>
          <H3 className={styles.Title}>
            What is your company/employer’s name?
          </H3>

          <div className={styles.CompanyTextWrapper}>
            <TextInput
              field={employerNameField}
              placeholder="Company Name"
              type={"text"}
              initialValue={
                checklistCtx.employerName
                  ? String(checklistCtx.employerName)
                  : ""
              }
            />
          </div>

          {showEmployerAddress && (
            <>
              <H3 className={styles.Title}>
                What is your company/employer’s address?
              </H3>

              <div className={styles.CompanyTextWrapper}>
                <TextInput
                  field={employerAddressField}
                  placeholder="Address"
                  type="text"
                  initialValue={
                    checklistCtx.employerAddress
                      ? String(checklistCtx.employerAddress)
                      : ""
                  }
                />
              </div>
            </>
          )}

          {!showEmployerAddress && (
            <SubmitButton
              className={styles.ContinueButton}
              onClick={handleOnNext}
              disabled={!formState.values[employerNameField]}
            >
              Next
            </SubmitButton>
          )}

          {showEmployerAddress && (
            <SubmitButton
              className={styles.ContinueButton}
              onClick={handleOnContinue}
              disabled={!formState.values[employerAddressField]}
            >
              Next
            </SubmitButton>
          )}
        </>
      )}
    </>
  );
}
