import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import getBreakpoint from "~/helpers/getBreakpoint";

interface IViewport {
  breakpoint: string;
}

const ViewportContext = createContext<IViewport>({
  breakpoint: getBreakpoint(),
});

export const ViewportProvider: FC = ({ children }) => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint());

  useEffect(() => {
    const handleResize = () => {
      const currentBreakpoint = getBreakpoint();
      if (currentBreakpoint !== breakpoint) {
        setBreakpoint(currentBreakpoint);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return (
    <ViewportContext.Provider value={{ breakpoint }}>
      {children}
    </ViewportContext.Provider>
  );
};

export function useViewport() {
  return useContext<IViewport>(ViewportContext);
}
