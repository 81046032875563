export const getOrdinalWord = (number: number) => {
  const ordinals = {
    1: "First",
    2: "Second",
    3: "Third",
    4: "Fourth",
    5: "Fifth",
    6: "Sixth",
    7: "Seventh",
    8: "Eigth",
    9: "Ninth",
    10: "Tenth",
  };

  return ordinals[number];
};
