import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { H3, TextSmall } from "~/components/Typography";
import SubmitButton from "~/components/SubmitButton";
import Icon from "~/components/Icon";
import GridWrapper from "~/components/GridWrapper";
import OnboardingLayout from "~/components/Layouts/OnboardingLayout";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import pushWithParams from "~/helpers/pushWithParams";

import { useAuth0Context } from "~/contexts/Auth0Context";
import AlreadyHaveAccount from "~/components/AlreadyHaveAccount";

function Welcome({ nextPath }: { nextPath: string }) {
  const history = useHistory();

  useUpdateAssistant({
    text: <TextSmall>You can view helpful tips here</TextSmall>,
  });
  const auth0 = useAuth0Context();

  const Login = () => {
    if (!auth0.isAuthenticated()) {
      return (
        <AlreadyHaveAccount
          auth0Redirect="/portal"
          wrapperStyle={styles.LoginWrapper}
        />
      );
    }
    return null;
  };

  return (
    <OnboardingLayout withProgress={false}>
      <GridWrapper>
        <div className={styles.WelcomeContent}>
          <H3 fontWeight="regular" component="h1">
            Hey there, welcome to
            <Icon
              name="AlmiWordmark"
              fill="#23453A"
              width={50}
              height={18}
              className={styles.AlmiWordmark}
            />
            <br />
            Your digital insurance assistant. <br />
            <br />
            Let&apos;s work together to find the right insurance plan for you.
          </H3>
          <br />
          <SubmitButton
            className={styles.ButtonLetStarted}
            onClick={() => pushWithParams(history, nextPath)}
            autoFocus
          >
            Let’s get started
          </SubmitButton>
          <Login />
        </div>
      </GridWrapper>
    </OnboardingLayout>
  );
}

export default Welcome;
