export function saveItem(
  key: string,
  value: string | number | boolean | object | []
) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getItem(key: string) {
  try {
    const value = sessionStorage.getItem(key);
    return JSON.parse(value as string);
  } catch (error) {
    return null;
  }
}

export function removeItem(key: string) {
  try {
    return sessionStorage.removeItem(key);
  } catch (error) {
    return null;
  }
}
