import React from "react";
import { useHistory } from "react-router-dom";
import { RadioGroup, Radio } from "informed";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

import styles from "./index.module.scss";

import { useMotorContext } from "~/contexts/MotorProvider";

import { H3, H5, TextSmall } from "~/components/Typography";
import VehicleSearchDisplay from "~/components/VehicleSearchDisplay";
import TileCard from "~/components/TileCard";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

const engineSizes = [
  {
    title: "Less than 1000cc",
    value: 500,
  },
  {
    title: "Between 1001 - 1400cc",
    value: 1200,
  },
  {
    title: "Between 1401cc - 1650cc",
    value: 1525,
  },
  {
    title: "Between 1651 - 1800cc",
    value: 1725,
  },
  {
    title: "Between 1801cc - 2500cc",
    value: 2150,
  },
  {
    title: "More than 2500cc",
    value: 2501,
  },
];
export default function EngineSize({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const fieldState = useCustomFieldState("engineSize");
  const heapCtx = useHeapContext();

  useUpdateAssistant({
    text: (
      <TextSmall>
        If your car has a 2L engine, that&apos;s 2000cc. You can find this on
        your registration document.
      </TextSmall>
    ),
  });

  const handleOnChangeValue = async (value: number) => {
    try {
      if (nextPath) {
        await motorCtx.vehicleInfo.setItem("engineSize", value);

        heapCtx.track(HeapEventName.MOTOR_ENGINE_SIZE, {
          "Engine Size": String(value),
        });

        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1">{`What is your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()}'s engine size?`}</H3>
        <VehicleSearchDisplay display={["year", "make", "model"]} />
      </legend>
      <div className={styles.Content}>
        <RadioGroup field="engineSize" keepState>
          {engineSizes.map((item, i) => (
            <TileCard
              key={`SearchInput-TileCard-${i}`}
              title={<H5>{item.title}</H5>}
              inputId={`SearchInput-TileCard-${i}`}
              checked={
                fieldState.value === item.value ||
                motorCtx.vehicleInfo.data.engineSize === item.value
              }
              inputElement={
                <Radio
                  id={`SearchInput-TileCard-${i}`}
                  field="engineSize"
                  value={item.value}
                  onClick={() => handleOnChangeValue(item.value)}
                  className={styles.HideRadio}
                  checked={fieldState.value === item}
                />
              }
            />
          ))}
        </RadioGroup>
      </div>
    </fieldset>
  );
}
