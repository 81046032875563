import TagManager from "react-gtm-module";

export default function GTMProvider() {
  const isProduction = process.env.NODE_ENV === "production";

  const getGtmID = (): string => {
    return process.env.REACT_APP_GOOGLE_TAG_MANAGER ?? "";
  };

  if (isProduction) {
    const tagManagerArgs = {
      gtmId: getGtmID(),
    };

    TagManager.initialize(tagManagerArgs);
  }

  return null;
}
