import React from "react";
import { H4, H5 } from "~/components/Typography";
import styles from "./index.module.scss";

type PropsType = {
  title: string;
  small?: boolean;
  count?: number;
  button?: React.ReactNode;
};
const SectionHeader = ({ title, button, count, small = false }: PropsType) => {
  return (
    <div className={styles.Section}>
      {small ? (
        <H5 className={styles.Heading} component="h2">
          {title}
          {!!count && <span className={styles.Count}>{count}</span>}
        </H5>
      ) : (
        <H4 className={styles.Heading} component="h2">
          {title}
          {!!count && <span className={styles.Count}>{count}</span>}
        </H4>
      )}
      {button}
    </div>
  );
};

export default SectionHeader;
