import React from "react";
import SectionHeader from "~/components/SectionHeader";
import Button from "~/components/Button";
import { QuoteType } from "../../types";
import QuoteCard from "../QuoteCard";
import styles from "./index.module.scss";

type PropsType = {
  quotes: QuoteType[];
  heading: string;
};

const QuoteGroup = ({ quotes, heading }: PropsType) => {
  return (
    <>
      <SectionHeader
        title={heading}
        count={quotes.length}
        button={
          <Button variant="secondary" size="small" internalLink={"/"}>
            Start a new quote
          </Button>
        }
      />
      <div className={styles.Cards}>
        {quotes.map((quote) => (
          <QuoteCard key={quote.id} quote={quote} />
        ))}
      </div>
    </>
  );
};

export default QuoteGroup;
