import React, { useState } from "react";
import { Bold, TextExtraSmall, H4 } from "~/components/Typography";
import Modal from "~/components/Modal";
import Status from "~/components/Status";
import TypeImage from "~/components/TypeImage";
import Button from "~/components/Button";
import ClaimDetails from "~/components/ClaimDetails";
import styles from "./index.module.scss";
import { ClaimType } from "../../types";

type PropType = {
  claim: ClaimType;
};

export default function ClaimCard({ claim }: PropType) {
  const [modalOpen, setModalOpen] = useState(false);
  const { insuredObject, externalId, status, vehicle } = claim;

  return (
    <>
      <div className={styles.ClaimCard}>
        <div className={styles.Icon}>
          {claim.vehicle ? (
            <TypeImage
              type={"motor"}
              vehicleMake={vehicle.make}
              variant="small"
            />
          ) : (
            <TypeImage type={"home"} variant="small" />
          )}
        </div>

        <div className={styles.Content}>
          <H4 className={styles.insuredObject}>{insuredObject}</H4>

          <TextExtraSmall className={styles.ClaimDetails}>
            Claim ID <Bold>{externalId}</Bold>
            <Status status={status} />
          </TextExtraSmall>
        </div>

        <div className={styles.Action}>
          <Button
            className={styles.Button}
            variant="senary"
            onClick={() => setModalOpen(true)}
            size="small"
          >
            See claim details
          </Button>
        </div>
      </div>

      <Modal
        title={`Claim ${externalId}`}
        isOpen={modalOpen}
        shouldOverlayCloseOnClick
        onClose={() => setModalOpen(false)}
        noOffset
        id={`claim-modal-${externalId}`}
      >
        <div className={styles.Modal}>
          <ClaimDetails claim={claim} />
        </div>
      </Modal>
    </>
  );
}
