import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 31;
const logoHeight = 30;
const User = ({
  className = "user-icon",
  fill = DEFAULT_FILL_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <g mask="url(#mask0)">
      <circle cx="15.0837" cy="15" r="15" fill={backgroundColor} />
    </g>
    <path
      d="M21.0837 22V20.3333C21.0837 19.4493 20.7677 18.6014 20.2051 17.9763C19.6425 17.3512 18.8794 17 18.0837 17H12.0837C11.2881 17 10.525 17.3512 9.96242 17.9763C9.39981 18.6014 9.08374 19.4493 9.08374 20.3333V22"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0837 14C16.7406 14 18.0837 12.6569 18.0837 11C18.0837 9.34315 16.7406 8 15.0837 8C13.4269 8 12.0837 9.34315 12.0837 11C12.0837 12.6569 13.4269 14 15.0837 14Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default User;
