import React from "react";

import styles from "./index.module.scss";

import { Text } from "~/components/Typography";
import Note, { Props as NoteProps } from "../Note";

type Props = {
  list: string[];
} & NoteProps;

const NoteWithList = ({ mainText, className, list, noMaxWidth }: Props) => (
  <Note mainText={mainText} className={className} noMaxWidth={noMaxWidth}>
    <ul className={styles.NoteList}>
      {list.map((listItem, i) => (
        <li key={`NoteListItem-${i}`}>
          <Text>{listItem}</Text>
        </li>
      ))}
    </ul>
  </Note>
);
export default NoteWithList;
