import { Branch } from "../types";

export default function getCountryCodeFromBranch(code: Branch) {
  switch (code.toUpperCase()) {
    case "BB": {
      return "BRB";
    }

    case "DG": {
      return "GUY";
    }

    default: {
      return "Unsupported Country Code";
    }
  }
}
