import { useFormState } from "informed";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import LoadingWrapper from "~/components/LoadingWrapper";
import TextInput from "~/components/TextInput";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { H3, Text } from "~/components/Typography";
import { useMotorContext } from "~/contexts/MotorProvider";
import styles from "./style.module.scss";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import MobileUploadRequired from "~/components/MobileUploadRequired";
import { isMobile } from "react-device-detect";
import { UserContext } from "~/contexts/UserProvider";

type Props = {
  nextPath?: string;
};

const UPDATE_VEHICLE = gql`
  mutation updateVehicle($policyId: String!, $input: MotorVehicleUpdateInput!) {
    updateVehicle(policyId: $policyId, input: $input)
  }
`;

export default function EngineAndChassisNumber({ nextPath }: Props) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const userCtx = useContext(UserContext);
  const formState = useFormState();
  const engineNumberField = "engineNumber";
  const chassisNumberField = "chassisNumber";
  const [showChassisNumber, setShowChassisNumber] = React.useState(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [updateVehicle] = useMutation(UPDATE_VEHICLE);

  const handleOnNext = () => {
    setShowChassisNumber(true);
  };

  useUpdateAssistant({
    text: (
      <Text>
        You can find this information on your Pro forma invoice, sale letter,
        previous insurance certificate or renewal notice.
      </Text>
    ),
  });

  const handleOnContinue = async () => {
    try {
      if (nextPath) {
        setSaving(true);
        await Promise.all([
          // waits for all promises in the array
          motorCtx.vehicleInfo.setItem(
            "engineNumber",
            formState.values[engineNumberField]
          ),
          motorCtx.vehicleInfo.setItem(
            "chassisNumber",
            formState.values[chassisNumberField]
          ),
          updateVehicle({
            variables: {
              policyId: motorCtx.policyInfo.data.id,
              input: {
                engineNumber: formState.values[engineNumberField],
                chassisNumber: formState.values[chassisNumberField],
              },
            },
          }),
        ]);

        if (motorCtx.savingsInfo.data.occupation === "Student") {
          history.push("/BRB/motor/documents");
        } else {
          history.push(nextPath);
        }
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  if (!isMobile && userCtx.email) {
    return (
      <MobileUploadRequired
        title="Great! Your account has been created.
  To complete the process and get your coverage plan, you will need a smartphone."
        email={userCtx.email}
        policyId={motorCtx.policyInfo.data.id || ""}
      />
    );
  }
  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <Text>
            We need this to issue your Motor Insurance cover note digitally.
          </Text>

          <H3 className={styles.Title}>Engine Number</H3>

          <div className={styles.TextWrapper}>
            <TextInput
              field={engineNumberField}
              placeholder="G16AID318681"
              type={"text"}
              initialValue={
                motorCtx.vehicleInfo.data.engineNumber
                  ? motorCtx.vehicleInfo.data.engineNumber
                  : ""
              }
            />
          </div>

          {!showChassisNumber && (
            <SubmitButton
              className={styles.ContinueButton}
              onClick={handleOnNext}
              disabled={!formState.values[engineNumberField]}
            >
              Next
            </SubmitButton>
          )}

          {showChassisNumber && (
            <>
              <H3 className={styles.Title}>Chassis Number (VIN)</H3>

              <div className={styles.TextWrapper}>
                <TextInput
                  field={chassisNumberField}
                  placeholder="MHYDN71V4LJ403091"
                  type="text"
                  initialValue={
                    motorCtx.vehicleInfo.data.chassisNumber
                      ? motorCtx.vehicleInfo.data.chassisNumber
                      : ""
                  }
                />
              </div>
            </>
          )}

          {showChassisNumber && (
            <SubmitButton
              className={styles.ContinueButton}
              onClick={handleOnContinue}
              disabled={!formState.values[chassisNumberField]}
            >
              Continue
            </SubmitButton>
          )}
        </>
      )}
    </>
  );
}
