import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./index.module.scss";
import { Link as RouterLink, LinkProps } from "react-router-dom";

export type TextTagType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";

export type Props = {
  children?: ReactNode | string | number | undefined;
  className?: string;
  fontWeight?: "regular" | "bold";
  inject?: string;
  component?: TextTagType;
  id?: string;
  labelledby?: string;
};

export type injectableProps = Props & { styleType: string };

type Href = {
  href: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  rel?: string;
} & Props;

type ButtonAnchorProps = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Props;

const classes = ({ className, style, fontWeight }: Props & { style: string }) =>
  classNames(className, style, {
    [styles.FontWeightRegular]: fontWeight === "regular",
    [styles.FontWeightBold]: fontWeight === "bold",
  });

export const H1 = ({
  children,
  className,
  component: Component = "h1",
  ...rest
}: Props) => (
  <Component className={classes({ className, style: styles.H1, ...rest })}>
    {children}
  </Component>
);

export const H2 = ({
  children,
  className,
  component: Component = "h2",
  ...rest
}: Props) => (
  <Component className={classes({ className, style: styles.H2, ...rest })}>
    {children}
  </Component>
);

export const H3 = ({
  children,
  className,
  component: Component = "h3",
  id,
  ...rest
}: Props) => (
  <Component
    id={id}
    className={classes({ className, style: styles.H3, ...rest })}
  >
    {children}
  </Component>
);

export const H4 = ({
  children,
  className,
  component: Component = "h4",
  id,
  ...rest
}: Props) => (
  <Component
    id={id}
    className={classes({ className, style: styles.H4, ...rest })}
  >
    {children}
  </Component>
);

export const H5 = ({
  children,
  className,
  component: Component = "h5",
  ...rest
}: Props) => (
  <Component className={classes({ className, style: styles.H5, ...rest })}>
    {children}
  </Component>
);

export const H6 = ({
  children,
  className,
  component: Component = "h5",
  id,
  ...rest
}: Props) => (
  <Component
    id={id}
    className={classes({ className, style: styles.H6, ...rest })}
  >
    {children}
  </Component>
);

export const Text = ({ children, className, ...rest }: Props) => (
  <p className={classes({ className, style: styles.Text, ...rest })}>
    {children}
  </p>
);

export const TextSmall = ({
  children,
  className,
  component: Component = "p",
  ...rest
}: Props) => (
  <Component
    className={classes({ className, style: styles.TextSmall, ...rest })}
  >
    {children}
  </Component>
);

export const TextExtraSmall = ({ children, className, ...rest }: Props) => (
  <p className={classes({ className, style: styles.TextExtraSmall, ...rest })}>
    {children}
  </p>
);

export const InjectableText = ({
  className,
  styleType,
  inject,
  ...rest
}: injectableProps) => (
  <p
    className={classes({
      className,
      style: styleType,
      ...rest,
    })}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: inject ? inject : "" }}
  />
);

export const PreText = ({ children, className, ...rest }: Props) => (
  <pre className={classes({ className, style: styles.Text, ...rest })}>
    {children}
  </pre>
);

export const PreTextExtraSmall = ({ children, className, ...rest }: Props) => (
  <pre
    className={classes({ className, style: styles.TextExtraSmall, ...rest })}
  >
    {children}
  </pre>
);

export const Meta = ({ children, className, ...rest }: Props) => (
  <strong className={classes({ className, style: styles.Meta, ...rest })}>
    {children}
  </strong>
);

export const Bold = ({ children, className, ...rest }: Props) => (
  <strong
    className={classes({
      className,
      style: styles.FontWeightBold,
      ...rest,
    })}
  >
    {children}
  </strong>
);

// A regular anchor link
export const A = ({
  children,
  className,
  href,
  target,
  rel,
  onClick,
  ...rest
}: Href) => (
  <a
    className={classes({ className, style: styles.A, ...rest })}
    target={target}
    href={href}
    onClick={onClick}
    rel={rel}
  >
    {children}
  </a>
);

// A button that looks like a regular anchor link
export const ButtonAnchor = ({
  children,
  className,
  onClick,
  ...rest
}: ButtonAnchorProps) => (
  <button
    className={classes({
      className,
      style: styles.ButtonAnchor,
      ...rest,
    })}
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
);

// A router link that looks like a regular anchor link
export const Link = (props: LinkProps) => (
  <RouterLink
    {...props}
    className={classes({
      className: props.className,
      style: styles.A,
    })}
  >
    {props.children}
  </RouterLink>
);
