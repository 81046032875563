import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 31;
const logoHeight = 31;
const LogOut = ({
  className = "logout-icon",
  fill = DEFAULT_FILL_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <g mask="url(#mask0)">
      <circle cx="15.2113" cy="15.5952" r="15" fill={backgroundColor} />
    </g>
    <path
      d="M13.9613 22.3452H10.9613C10.5635 22.3452 10.1819 22.1872 9.90064 21.9059C9.61934 21.6246 9.4613 21.243 9.4613 20.8452V10.3452C9.4613 9.94739 9.61934 9.56586 9.90064 9.28455C10.1819 9.00325 10.5635 8.84521 10.9613 8.84521H13.9613"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2113 19.3452L22.9613 15.5952L19.2113 11.8452"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9613 15.5952H13.9613"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LogOut;
