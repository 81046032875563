import React from "react";
import { Route, useHistory } from "react-router-dom";
import { useAuth0Context } from "~/contexts/Auth0Context";
import Loading from "~/components/Loading";
import styles from "./index.module.scss";
import { parse } from "query-string";

type PropsType = {
  children: React.ReactElement;
  path?: string | string[];
  redirectAfterLogin?: string;
  passOnProps?: boolean;
  exact?: boolean;
};

const AuthorizedRoute = ({
  children,
  redirectAfterLogin,
  passOnProps,
  ...rest
}: PropsType) => {
  const history = useHistory();
  const auth0 = useAuth0Context();
  const childWithProps = React.cloneElement(children, rest);

  const initiateLogin = (pathname: string) => {
    const searchParams = parse(history.location.search);
    let redirect = redirectAfterLogin;
    console.log("Pathname: ", pathname);
    console.log("SearchParams: ", searchParams);

    if (
      searchParams.redirectAfterLogin &&
      !Array.isArray(searchParams.redirectAfterLogin)
    ) {
      redirect = searchParams.redirectAfterLogin;
    }

    console.log("Post login redirect: ", redirect);

    return auth0.login(redirect ? redirect : pathname)();
  };

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (auth0.isLoading()) {
          return (
            <div className={styles.loadingWrapper}>
              <Loading />
            </div>
          );
        } else {
          if (!auth0.isAuthenticated()) {
            initiateLogin(location.pathname);
            return (
              <div className={styles.loadingWrapper}>
                <Loading />
              </div>
            );
          } else {
            return passOnProps ? childWithProps : children;
          }
        }
      }}
    />
  );
};

export default AuthorizedRoute;
