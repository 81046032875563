import React from "react";
import cx from "classnames";
import Modal from "~/components/Modal";
import Button from "~/components/Button";
import { H4 } from "~/components/Typography";

import styles from "./index.module.scss";
import "./animations.scss";

type PropsType = {
  message?: string;
  id?: string;
  className?: string;
  overlayClassName?: string;
  onCancel?: () => void;
  internalLink?: string;
  externalLink?: string;
  hasDescription?: boolean;
  isOpen: boolean;
};

export default function AlertModal({
  className,
  overlayClassName,
  onCancel,
  internalLink,
  externalLink,
  message = "Are you sure you want to exit?",
  id,
  ...rest
}: PropsType) {
  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Modal
      id={id}
      className={cx(styles.Modal, className)}
      overlayClassName={cx(styles.Overlay, overlayClassName)}
      onClose={handleOnCancel}
      shouldOverlayCloseOnClick={false}
      {...rest}
    >
      <div className={styles.AlertModalWrapper}>
        <H4>{message}</H4>
        <div className={styles.ButtonGroup}>
          <Button
            variant="tertiary"
            size="small"
            className={styles.Btn}
            internalLink={internalLink}
            externalLink={externalLink}
          >
            Yes
          </Button>
          <Button size="small" className={styles.Btn} onClick={handleOnCancel}>
            No, thanks
          </Button>
        </div>
      </div>
    </Modal>
  );
}
