import React from "react";
import { Helmet } from "react-helmet-async";

import Header from "~/components/Header";
import ProgressBar from "~/components/ProgressBar";
import StepTitle from "~/components/StepTitle";

import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHistory } from "react-router-dom";
import { useProfileName } from "~/helpers/useProfileName";

type Props = {
  onClose?: (() => void) | string;
  onPrev?: () => void;
  hideBackButton?: boolean;
};

function ProgressHeader(props: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const profileName = useProfileName();

  return (
    <>
      <Helmet>
        <title>{`${headerCtx.title}`}</title>
      </Helmet>
      <Header
        title={
          <StepTitle
            title={headerCtx.title}
            totalOfSteps={headerCtx.totalOfSteps}
            currentStep={headerCtx.currentStep}
          />
        }
        onClose={props.onClose}
        onPrev={props.onPrev ? props.onPrev : history.goBack}
        hideBackButton={props.hideBackButton}
        userInitial={profileName}
      />
      <ProgressBar
        stepsLength={headerCtx.totalOfSteps || 0}
        step={headerCtx.currentStep + 1 || 0}
      />
    </>
  );
}
export default ProgressHeader;
