import { matchPath, useHistory } from "react-router";
import { CountryCode, InsuranceType } from "../types";

type StepPathMatchType = {
  params: {
    countryCode: CountryCode;
    insuranceType: InsuranceType;
    step: "string";
  };
} | null;

export function useIsPaymentCheckout() {
  const history = useHistory();

  const paymentPathMatch: StepPathMatchType = matchPath(
    history.location.pathname,
    {
      path: "/:countryCode/:insuranceType/:step",
      exact: true,
      strict: false,
    }
  );

  return paymentPathMatch?.params.step.toLowerCase() === "checkout";
}
