import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;

const ArrowLeft = ({
  className = "arrow-left-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
    role="img"
    aria-hidden
  >
    <circle cx="15" cy="15" r="15" fill="#FDEDBF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6858 10.9C18.0763 10.5095 18.0763 9.87629 17.6858 9.48576C17.2953 9.09524 16.6621 9.09524 16.2716 9.48576L11.4645 14.2929C11.0739 14.6834 11.0739 15.3166 11.4645 15.7071L16.2716 20.5142C16.6621 20.9047 17.2953 20.9047 17.6858 20.5142C18.0763 20.1237 18.0763 19.4905 17.6858 19.1L13.5858 15L17.6858 10.9Z"
      fill={fill}
    />
  </svg>
);

export default ArrowLeft;
