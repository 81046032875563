import React from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup } from "informed";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

import styles from "./index.module.scss";

import { NO_COVERAGE_URL } from "~/routes/index.constant";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";

import { H3, TextSmall } from "~/components/Typography";
import RadioSelector from "~/components/RadioSelector";

export default function CommercialActivity({
  nextPath,
}: {
  nextPath?: string;
}) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const fieldName = "commercialActivities";
  const { value } = useCustomFieldState(fieldName);

  useUpdateAssistant({
    text: (
      <TextSmall>
        Commercial activity includes selling or producing goods for sale.
        Working from home is not a commercial activity.
      </TextSmall>
    ),
  });

  const handleOnNext = (value: FormValue) => {
    if (value === "yes") {
      homeCtx.setState({ commercialActivities: true });
      history.push(`${NO_COVERAGE_URL}?message=home-commercial-activity`);
      return;
    }
    if (nextPath) {
      homeCtx.setState({ commercialActivities: false });
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1">
          Do you use your home for any commercial activities?
        </H3>
      </legend>
      <div className={styles.CommercialActivityContent}>
        <RadioGroup field={fieldName} keepState>
          <RadioSelector
            key="yes"
            field={fieldName}
            value="yes"
            checked={homeCtx?.commercialActivities || value === "yes"}
            onChange={handleOnNext}
          >
            Yes
          </RadioSelector>
          <RadioSelector
            key="No"
            field={fieldName}
            value="no"
            checked={!homeCtx?.commercialActivities || value === "no"}
            onChange={handleOnNext}
          >
            No
          </RadioSelector>
        </RadioGroup>
      </div>
    </fieldset>
  );
}
