import React, { ReactNode, useContext, useEffect, useState } from "react";
import * as storage from "~/helpers/storage";

import {
  IData,
  IkeyStore,
  MOTOR_DEFAULT_STATE,
  MotorContextType,
} from "./index.default";

type Props = {
  children: ReactNode;
};
export type { MotorContextType } from "./index.default";
const initialState = MOTOR_DEFAULT_STATE;
export const MotorContext = React.createContext(
  initialState() as MotorContextType
);
export const useMotorContext = () => useContext(MotorContext);

export default function MotorProvider({ children }: Props) {
  const [state, setState] = useState(initialState());

  const handleNewState = (keyStore: IkeyStore, data: IData) => {
    if (data) {
      const newData = {
        ...state[keyStore].data,
        ...data,
      };

      const newInfoState = {
        ...state[keyStore],
        data: newData,
      };

      const newState = {
        ...state,
        [keyStore]: newInfoState,
      };

      storage.saveItem(keyStore, newData);
      setState(newState);
    }
  };

  useEffect(() => {
    setState(initialState());
  }, []);
  return (
    // @ts-ignore
    <MotorContext.Provider value={{ ...state, setState: handleNewState }}>
      {children}
    </MotorContext.Provider>
  );
}
