import React, { useContext } from "react";
import { H3 } from "~/components/Typography";
import { MotorContext } from "~/contexts/MotorProvider";

import styles from "./index.module.scss";

type PropsType = {
  display: string[];
};

const VehicleSearchDisplay = ({ display }: PropsType) => {
  const store = useContext(MotorContext);

  return (
    <div className={styles.Vehicle}>
      {store?.vehicleInfo?.data?.year && display.includes("year") && (
        <H3 className={styles.VehicleItem} component="h2">
          {store.vehicleInfo.data.year}
          {store?.vehicleInfo?.data?.make && display.includes("make")
            ? ` ${store.vehicleInfo.data.make}`
            : null}
          {store?.vehicleInfo?.data?.model && display.includes("model")
            ? ` ${store.vehicleInfo.data.model}`
            : null}
        </H3>
      )}
    </div>
  );
};

export default VehicleSearchDisplay;
