import React from "react";

import { Bold, TextSmall } from "~/components/Typography";
import Status from "~/components/Status";
import CalendarImg from "~/assets/images/calendar.svg";
import styles from "./ClaimCardClosed.module.scss";
import { ClaimClosedType } from "../../types";
import { formatIsoDate } from "~/helpers/dates";

type PropType = {
  claim: ClaimClosedType;
};

export default function ClaimCard({ claim }: PropType) {
  const { insuredObject, externalId, status, dateSettled } = claim;
  return (
    <div className={styles.ClaimCard}>
      <TextSmall fontWeight="bold" className={styles.Title}>
        {insuredObject}
      </TextSmall>
      <div className={styles.DetailsWrapper}>
        <div className={styles.Details}>
          <TextSmall className={styles.ClaimDetails}>
            Claim ID <Bold>{externalId}</Bold> <Status status={status} />
          </TextSmall>
        </div>
        <div className={styles.Date}>
          {dateSettled && (
            <>
              <img
                src={CalendarImg}
                alt="calendar"
                className={styles.CalendarImg}
              />

              <TextSmall>{formatIsoDate(dateSettled)}</TextSmall>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
