import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;
const Calendar = ({
  className = "calendar-icon",
  fill = DEFAULT_FILL_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle cx="15" cy="15" r="15" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.35 8.2C18.35 7.8134 18.0366 7.5 17.65 7.5C17.2634 7.5 16.95 7.8134 16.95 8.2V8.79999H13.15V8.2C13.15 7.8134 12.8366 7.5 12.45 7.5C12.0634 7.5 11.75 7.8134 11.75 8.2V8.79999H10.5C9.39543 8.79999 8.5 9.69542 8.5 10.8V13.4V19.9C8.5 21.0046 9.39543 21.9 10.5 21.9H19.6C20.7046 21.9 21.6 21.0046 21.6 19.9V13.4V10.8C21.6 9.69542 20.7046 8.79999 19.6 8.79999H18.35V8.2ZM20.2 12.7V10.8C20.2 10.4686 19.9314 10.2 19.6 10.2H18.35V10.8C18.35 11.1866 18.0366 11.5 17.65 11.5C17.2634 11.5 16.95 11.1866 16.95 10.8V10.2H13.15V10.8C13.15 11.1866 12.8366 11.5 12.45 11.5C12.0634 11.5 11.75 11.1866 11.75 10.8V10.2H10.5C10.1686 10.2 9.9 10.4686 9.9 10.8V12.7H20.2ZM9.9 14.1H20.2V19.9C20.2 20.2314 19.9314 20.5 19.6 20.5H10.5C10.1686 20.5 9.9 20.2314 9.9 19.9V14.1Z"
      fill={fill}
    />
  </svg>
);

export default Calendar;
