import React from "react";
import { Form } from "informed";
import MultiStepForm from "~/components/MultiStepForm";
import ChecklistProvider from "~/contexts/ChecklistProvider";
import HealthConditions from "~/screens/Portal/Checklist/Motor/HealthConditions";
import ProsecutionsPastFiveYears from "~/screens/Portal/Checklist/Motor/ProsecutionsPastFiveYears";
import RefusedInsurance from "~/screens/Portal/Checklist/Motor/RefusedInsurance";
import EmployerName from "~/screens/Portal/Checklist/Motor/EmployerName";
import PreviousProvider from "~/screens/Portal/Checklist/Motor/PreviousProvider";
import VehicleFinanced from "~/screens/Portal/Checklist/Motor/VehicleFinanced";
import FinalDocumentUpload from "~/screens/Portal/Checklist/Common/FinalDocumentUpload";
import Declaration from "~/screens/Portal/Checklist/Common/Declaration";
import AssistantProvider from "~/contexts/AlmiAssistantProvider";
import HeaderProvider from "~/contexts/HeaderProvider";
import { HelmetProvider } from "react-helmet-async";
import Footer from "~/components/Footer";
import ChecklistSubmitted from "~/screens/Portal/Checklist/Common/ChecklistSubmitted";
import ChecklistStarted from "~/screens/Portal/Checklist/Common/ChecklistStarted";
import { ModalProvider } from "~/components/Modal";
import AdditionalDriverDetails from "~/screens/Portal/Checklist/Motor/AdditionalDriverDetails";
import ChecklistRedirect from "~/screens/Portal/Checklist/Common/ChecklistRedirect";
import AuthorizedRoute from "~/components/AuthorizedRoute";
import MotorProvider from "~/contexts/MotorProvider";
import OnboardProvider from "~/contexts/OnboardProvider";
import UserProvider from "~/contexts/UserProvider";
import { InsuranceType } from "../../types";
import SupportProvider from "~/contexts/AlmiSupportProvider";

const PORTAL_CHECKLIST_STEPS = [
  {
    path: "/checklist/motor/:policyId/redirect",
  },
  {
    path: "/checklist/motor/:policyId/started",
  },
  {
    path: "/checklist/motor/:policyId/drivers",
  },
  {
    path: "/checklist/motor/:policyId/health-conditions",
  },
  {
    path: "/checklist/motor/:policyId/prosecutions",
  },
  {
    path: "/checklist/motor/:policyId/refused-insurance",
  },
  {
    path: "/checklist/motor/:policyId/employer",
  },
  {
    path: "/checklist/motor/:policyId/previous-provider",
  },
  {
    path: "/checklist/motor/:policyId/vehicle-financed",
  },
  {
    path: "/checklist/motor/:policyId/documents",
  },
  {
    path: "/checklist/motor/:policyId/declaration",
  },
  {
    path: "/checklist/motor/:policyId/submitted",
    hideBackButton: true,
  },
  {
    path: "/portal",
  },
];

const INSURANCE_TYPE: InsuranceType = "motor";

const PortalChecklistMotor = () => {
  return (
    <MotorProvider>
      <OnboardProvider>
        <UserProvider>
          <ModalProvider>
            <AssistantProvider>
              <SupportProvider>
                <HeaderProvider>
                  <HelmetProvider>
                    <ChecklistProvider>
                      <Form>
                        <MultiStepForm steps={PORTAL_CHECKLIST_STEPS}>
                          <AuthorizedRoute passOnProps>
                            <ChecklistRedirect />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <ChecklistStarted insuranceType={INSURANCE_TYPE} />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <AdditionalDriverDetails />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <HealthConditions />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <ProsecutionsPastFiveYears />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <RefusedInsurance />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <EmployerName />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <PreviousProvider />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <VehicleFinanced />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <FinalDocumentUpload />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <Declaration insuranceType={INSURANCE_TYPE} />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <ChecklistSubmitted
                              insuranceType={INSURANCE_TYPE}
                            />
                          </AuthorizedRoute>
                        </MultiStepForm>
                      </Form>

                      <Footer />
                    </ChecklistProvider>
                  </HelmetProvider>
                </HeaderProvider>
              </SupportProvider>
            </AssistantProvider>
          </ModalProvider>
        </UserProvider>
      </OnboardProvider>
    </MotorProvider>
  );
};

export default PortalChecklistMotor;
