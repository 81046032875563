import React from "react";
import { useHistory } from "react-router-dom";
import { RadioGroup, FormValue, Radio } from "informed";

import styles from "./index.module.scss";

import { useMotorContext } from "~/contexts/MotorProvider";

import { H3, H5, TextSmall } from "~/components/Typography";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";
import TileCard from "~/components/TileCard";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

type Props = {
  nextPath?: string;
};

export default function VehicleBusinessPurpose(props: Props) {
  const { nextPath } = props;
  const history = useHistory();
  const motorCtx = useMotorContext();
  const heapCtx = useHeapContext();

  const handleOnNext = async (value: FormValue) => {
    await motorCtx.policyInfo.setItem("isForBusiness", value as string);

    heapCtx.track(HeapEventName.MOTOR_PERSONAL_USE, {
      "Personal Use": value as string,
    });

    if (value === "yes") {
      history.push(`${"/contact-guardian"}?message=vehicle-purpose`);
      return;
    }

    if (nextPath) {
      pushWithParams(history, nextPath);
    }
  };

  const savedValue = motorCtx.policyInfo.data.isForBusiness;

  return (
    <fieldset>
      <legend>
        <VehicleSearchDisplaySmall
          prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
        />
        <H3 className={styles.Question}>How will this vehicle be used?</H3>
      </legend>
      <div className={styles.ButtonWrapper}>
        <RadioGroup field="isForBusiness" keepState>
          <TileCard
            title={
              <>
                <H5>Personal</H5>
                <TextSmall className={styles.Description}>
                  including your daily commute
                </TextSmall>
              </>
            }
            inputId="Personal"
            checked={savedValue === "no"}
            inputElement={
              <Radio
                id="Personal"
                field="insuranceType"
                value="no"
                onClick={() => handleOnNext("no")}
                className={styles.HideRadio}
              />
            }
          />
          <TileCard
            title={
              <>
                <H5>Commercial</H5>
                <TextSmall className={styles.Description}>
                  deliveries, hire car, etc.
                </TextSmall>
              </>
            }
            inputId="Commercial"
            checked={savedValue === "yes"}
            inputElement={
              <Radio
                id="Commercial"
                field="insuranceType"
                value="yes"
                onClick={() => handleOnNext("yes")}
                className={styles.HideRadio}
              />
            }
          />
        </RadioGroup>
      </div>
    </fieldset>
  );
}
