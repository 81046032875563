import moment from "moment";
import { MotorContextType } from "~/contexts/MotorProvider";

export const getYearsWithoutClaims = (motorInfo: MotorContextType) => {
  switch (motorInfo.policyInfo.data.claimsHistory) {
    case "<=2":
      return Math.min(
        ...motorInfo.savingsInfo.data.previousClaims.map((claim) => {
          const yearsSinceClaim = moment().diff(claim.date, "years");
          return yearsSinceClaim;
        })
      );
    case "0":
      return motorInfo.policyInfo.data.yearsWithoutClaims || 0;
    default:
      return 0;
  }
};
