import React, { memo } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import Assistant from "~/components/Assistant";
import styles from "./index.module.scss";

import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";

function Footer() {
  const location = useLocation();
  const currentAssistant = useAssistantContext();
  const isPortalRoute = location.pathname.match("/portal");

  if (isPortalRoute) {
    return null;
  }

  // Determine whether the footer contains content
  const isEmpty = !currentAssistant.isOpen;

  return (
    <div className={styles.FooterWrapper}>
      <div
        className={classnames(styles.FooterVeil, {
          [styles.VeilVisible]: !isEmpty,
        })}
      ></div>
      <div className={styles.FooterContent}>
        <div className={styles.FooterContentLeft}>
          <Assistant />
        </div>
      </div>
    </div>
  );
}

export default memo(Footer);
