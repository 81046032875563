export const isCameraPermitted = async () => {
  // Not supported on Safari
  if (navigator.permissions === undefined) throw new Error("unsupported");
  try {
    return (await navigator.permissions.query({ name: "camera" })).state;
  } catch (err) {
    // "camera" query not supported on Firefox
    if (err.name === "TypeError") throw new Error("unsupported");
    throw err;
  }
};
