import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup, useFormState } from "informed";
import { useMotorContext } from "~/contexts/MotorProvider";

import RadioSelector from "~/components/RadioSelector";
import { H3 } from "~/components/Typography";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";

import styles from "./style.module.scss";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

function JobShiftWork({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const formState = useFormState();
  const heapCtx = useHeapContext();

  const {
    values: { isShiftWorker },
  } = formState;

  const handleOnChange = async (value: FormValue) => {
    try {
      if (nextPath) {
        await motorCtx.policyInfo.setItem("isShiftWorker", value);

        heapCtx.track(HeapEventName.MOTOR_SHIFT_WORK, {
          "Shift Work": value as string,
        });

        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <fieldset>
      <legend>
        <VehicleSearchDisplaySmall
          prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
        />
        <H3 component="h1">Does your job involve shift work?</H3>
      </legend>
      <div className={styles.JobShiftWorkContent}>
        <RadioGroup field="isShiftWorker" keepState>
          <RadioSelector
            key="yes"
            field="isShiftWorker"
            value="yes"
            checked={isShiftWorker === "yes"}
            onChange={(value) => handleOnChange(value)}
          >
            Yes
          </RadioSelector>
          <RadioSelector
            key="no"
            field="isShiftWorker"
            value="no"
            checked={isShiftWorker === "no"}
            onChange={(value) => handleOnChange(value)}
          >
            No
          </RadioSelector>
        </RadioGroup>
      </div>
    </fieldset>
  );
}
export default memo(JobShiftWork);
