import React, { useState } from "react";
import { matchPath, useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import ProgressHeader from "~/components/ProgressHeader";
import Header from "~/components/Header";
import AlertModal from "~/components/AlertModal";
import { CountryCode } from "../../../types";
import { useIsPaymentCheckout } from "~/helpers/useIsPaymentCheckout";
import classNames from "classnames";
import { useProfileName } from "~/helpers/useProfileName";

type Props = {
  children: React.ReactNode;
  withProgress?: boolean;
  hideBackButton?: boolean;
  onPrevClick?: () => void;
};

type PathMatchHeaderType = {
  params: { countryCode: CountryCode; policyId: string };
} | null;

const getCloseUrl = (pathMatch: PathMatchHeaderType) => {
  if (!pathMatch) {
    return "https://almi.bb";
  }

  if (pathMatch.params.countryCode?.toUpperCase() === "BRB") {
    return "https://almi.bb";
  } else if (pathMatch.params.policyId) {
    return "/portal";
  }

  return "https://almi.bb";
};

const OnboardingLayout = ({
  children,
  withProgress = true,
  hideBackButton,
  onPrevClick,
}: Props) => {
  const isPaymentCheckout = useIsPaymentCheckout();
  const profileName = useProfileName();
  const history = useHistory();

  const pathMatch: PathMatchHeaderType = matchPath(history.location.pathname, {
    path: ["/checklist/:insuranceType/:policyId/*", "/:countryCode/*"],
    exact: true,
    strict: false,
  });

  const closeUrl = getCloseUrl(pathMatch);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={styles.Layout}>
      <AlertModal
        externalLink={closeUrl}
        isOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
      />

      <div className={styles.Header}>
        {withProgress ? (
          <ProgressHeader
            onClose={() => setModalOpen(true)}
            hideBackButton={hideBackButton}
            onPrev={onPrevClick}
          />
        ) : (
          <Header
            onClose={() => setModalOpen(true)}
            hideBackButton={hideBackButton}
            onPrev={onPrevClick}
            userInitial={profileName}
          />
        )}
      </div>

      <div
        className={classNames(styles.Content, {
          [styles.IframeLayout]: isPaymentCheckout,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default OnboardingLayout;
