import React from "react";
import classnames from "classnames";
import { TextSmall } from "~/components/Typography";
import Icon from "~/components/Icon";
import { Quote } from "~/contexts/MotorProvider/PolicyInfo";
import { formatCurrency } from "~/helpers/currency";
import styles from "./index.module.scss";

const DEDUCTIBLE = 1000;

export default function CoveredList({ quote }: { quote: Quote }) {
  const type = quote.typeOfCover;
  return (
    <>
      <TextSmall className={styles.ListItem}>
        <Icon name="CheckRound" backgroundColor="transparent" fill="#31AD63" />
        {`${formatCurrency(
          quote.coverage.find((item) => item.peril === "third-party-property")
            ?.coveredValue ?? -1,
          true,
          true
        )} Third Party Coverage`}
      </TextSmall>
      <TextSmall
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: type === "T",
        })}
      >
        <Icon
          name={type === "T" ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={type === "T" ? "#91A29C" : "#31AD63"}
        />
        Comprehensive coverage
      </TextSmall>
      <TextSmall className={styles.ListItem}>
        <Icon name="CheckRound" backgroundColor="transparent" fill="#31AD63" />
        24/7 Emergency Assistance
      </TextSmall>
      <TextSmall
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: type === "T",
        })}
      >
        <Icon
          name={type === "T" ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={type === "T" ? "#91A29C" : "#31AD63"}
        />
        {formatCurrency(DEDUCTIBLE, true, true)} deductible
      </TextSmall>
      <TextSmall
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: type === "T",
        })}
      >
        <Icon
          name={type === "T" ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={type === "T" ? "#91A29C" : "#31AD63"}
        />
        Windscreen protection
      </TextSmall>
      <TextSmall
        className={classnames(styles.ListItem, {
          [styles.ItemDisabled]: type === "T",
        })}
      >
        <Icon
          name={type === "T" || type === "L" ? "Close" : "CheckRound"}
          backgroundColor="transparent"
          fill={type === "T" || type === "L" ? "#91A29C" : "#31AD63"}
        />
        Courtesy Vehicle
      </TextSmall>
    </>
  );
}
