import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import styles from "./index.module.scss";

import { H3 } from "~/components/Typography";

import OnboardingLayout from "~/components/Layouts/OnboardingLayout";
import TileCardVertical from "~/components/TileCardVertical";

import HomeImage from "~/assets/images/home-illustration.png";
import MotorImage from "~/assets/images/car-illustration.png";

import GridWrapper from "~/components/GridWrapper";
import { Form, Radio, RadioGroup } from "informed";
import { CountryCode } from "../../types";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import pushWithParams from "~/helpers/pushWithParams";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { ENABLE_HOME_FLOW } from "~/helpers/constants";

export default function SelectInsuranceType() {
  const history = useHistory();
  const params: { countryCode: string } = useParams();
  const countryCode = params.countryCode.toUpperCase() as CountryCode;
  const motorCtx = useMotorContext();
  const onboardCtx = useOnboardContext();
  const homeCtx = useHomeContext();

  const handleOnChange = (value: "motor" | "home") => {
    if (value) {
      onboardCtx.setState({ insuranceType: value });
      pushWithParams(history, `/${countryCode}/${value}/quick-note`);
    }
  };

  useEffect(() => {
    motorCtx.vehicleInfo.removeStore();
    motorCtx.savingsInfo.removeStore();
    motorCtx.policyInfo.removeStore();
    onboardCtx.removeStore();
    homeCtx.removeStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form>
      <OnboardingLayout onPrevClick={() => history.push("/")}>
        <GridWrapper>
          <fieldset>
            <legend>
              <H3 component="h1">What kind of insurance do you need?</H3>
            </legend>
            <div className={styles.Cards}>
              <RadioGroup field="insuranceType" keepState>
                <TileCardVertical
                  title="Motor"
                  className={classNames(styles.TitleCardWrapper, {
                    [styles.Checked]: onboardCtx.insuranceType === "motor",
                  })}
                  image={
                    <img
                      src={MotorImage}
                      alt="Illustration of a car"
                      className={classNames(styles.MotorImage)}
                      aria-hidden
                    />
                  }
                  inputId="Motor"
                  checked={onboardCtx.insuranceType === "motor"}
                >
                  <Radio
                    id="Motor"
                    field="insuranceType"
                    value="motor"
                    onClick={() => handleOnChange("motor")}
                  />
                </TileCardVertical>
                <TileCardVertical
                  title="Home"
                  className={
                    !ENABLE_HOME_FLOW
                      ? styles.TitleCardWrapperHome
                      : classNames(styles.TitleCardWrapper, {
                          [styles.Checked]: onboardCtx.insuranceType === "home",
                        })
                  }
                  description={!ENABLE_HOME_FLOW ? "Coming soon" : undefined}
                  image={
                    <img
                      src={HomeImage}
                      alt="Illustration of a house"
                      className={styles.HomeImage}
                      aria-hidden
                    />
                  }
                  inputId="Home"
                  checked={onboardCtx.insuranceType === "home"}
                  disabled={!ENABLE_HOME_FLOW}
                >
                  {ENABLE_HOME_FLOW && (
                    <Radio
                      id="Home"
                      field="insuranceType"
                      value="home"
                      onClick={() => handleOnChange("home")}
                    />
                  )}
                </TileCardVertical>
              </RadioGroup>
            </div>
          </fieldset>
        </GridWrapper>
      </OnboardingLayout>
    </Form>
  );
}
