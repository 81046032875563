import React from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup } from "informed";

import styles from "./index.module.scss";

import { NO_COVERAGE_URL } from "~/routes/index.constant";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHomeContext } from "~/contexts/HomeProvider";

import { H3, TextSmall } from "~/components/Typography";
import RadioSelector from "~/components/RadioSelector";

export default function PropertyDensity({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const fieldName = "isHighDensity";
  const { value } = useCustomFieldState(fieldName);
  useUpdateAssistant({
    text: (
      <TextSmall>
        Home in these areas are at higher risk of fires that spread to multiple
        homes.
      </TextSmall>
    ),
  });

  const handleOnNext = (value: FormValue) => {
    if (value === "yes") {
      homeCtx.setState({ isHighDensity: true });
      history.push(`${NO_COVERAGE_URL}?message=home-property-density`);
      return;
    }
    if (nextPath) {
      homeCtx.setState({ isHighDensity: false });
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1">Is your home in a high-density area?</H3>
      </legend>
      <div className={styles.isHighDensityContent}>
        <RadioGroup field={fieldName} keepState>
          <RadioSelector
            key="yes"
            field={fieldName}
            value="yes"
            checked={homeCtx?.isHighDensity || value === "yes"}
            onChange={handleOnNext}
          >
            Yes
          </RadioSelector>
          <RadioSelector
            key="No"
            field={fieldName}
            value="no"
            checked={!homeCtx?.isHighDensity || value === "no"}
            onChange={handleOnNext}
          >
            No
          </RadioSelector>
        </RadioGroup>
      </div>
    </fieldset>
  );
}
