import React, { ReactNode, useContext, useState } from "react";

type Props = {
  children?: ReactNode;
};

type HeaderData = {
  currentStep: number;
  totalOfSteps: number;
  title?: string;
};

type IHeaderContext = {
  setState: (newState: HeaderData) => void;
} & HeaderData;

const initialState: IHeaderContext = {
  currentStep: -1,
  totalOfSteps: 0,
  title: "",
  setState: () => {
    throw new Error("Header context has not yet been initialized.");
  },
};

export const HeaderContext = React.createContext(initialState);
export const useHeaderContext = () => useContext(HeaderContext);

export default function HeaderProvider({ children }: Props) {
  const [state, setState] = useState(initialState);

  const handleNewState = (newState?: HeaderData) => {
    if (newState) setState({ ...state, ...newState });
  };

  return (
    <HeaderContext.Provider
      value={{
        ...state,
        setState: handleNewState,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}
