import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import TextInput from "~/components/TextInput";
import SubmitButton from "~/components/SubmitButton";
import { H3, TextSmall } from "~/components/Typography";

import { MotorContext } from "~/contexts/MotorProvider";
import { carYear } from "~/helpers/validators";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import pushWithParams from "~/helpers/pushWithParams";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

export default function VehicleYear({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const motorCtx = useContext(MotorContext);
  const heapCtx = useHeapContext();

  const fieldName = "year";
  const { value, error } = useCustomFieldState(fieldName);

  useUpdateAssistant({
    text: (
      <TextSmall>
        This is listed on your vehicle registration document and owner&apos;s
        manual.
      </TextSmall>
    ),
  });

  const MIN_CAR_YEAR_VALUE = 1985;

  const handleOnNext = async () => {
    try {
      if (
        !error &&
        value.toString() &&
        parseInt(value.toString(), 0) < MIN_CAR_YEAR_VALUE
      ) {
        //redirect the user to the No Coverage screen to contact Guardian for a policy
        // if year is between 1886 - MIN_CAR_YEAR_VALUE
        await motorCtx.vehicleInfo.removeItem("year");
        history.push(`${"/contact-guardian"}?message=vehicle-year`);
      } else {
        if (nextPath && !error) {
          await motorCtx.vehicleInfo.setItem("year", Number(value));

          heapCtx.track(HeapEventName.MOTOR_YEAR, {
            Year: Number(value),
          });

          pushWithParams(history, nextPath);
        }
      }
    } catch {
      console.warn(error);
    }
  };

  const disabledButton = !value || !!error;
  const label = `What is your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()}'s model year?`;
  return (
    <>
      <H3 component="h1">{label}</H3>
      <TextInput
        className={styles.TextInputWrapper}
        label={label}
        hideLabelFromView
        field={fieldName}
        type="year"
        placeholder="YYYY"
        initialValue={motorCtx.vehicleInfo.data.year?.toString()}
        keepState
        validate={carYear}
        validateOnBlur
        validateOnMount
        validateOnChange
        inputMode="numeric"
        autoFocus
      />
      <div className={styles.ButtonWrapper}>
        <SubmitButton
          onClick={handleOnNext}
          disabled={disabledButton}
          className={styles.ButtonNext}
        >
          Next
        </SubmitButton>
      </div>
    </>
  );
}
