/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect, useCallback } from "react";
import IdleTimer from "react-idle-timer";
import { useAuth0Context } from "~/contexts/Auth0Context";
import Modal from "~/components/Modal";
import GridWrapper from "~/components/GridWrapper";
import { Text, H4 } from "~/components/Typography";
import Button from "~/components/Button";
import NotFoundImage from "~/assets/images/404.png";
import { saveItem, getItem, removeItem } from "~/helpers/storage";
import styles from "./index.module.scss";
import getAppOrigin from "~/helpers/getAppOrigin";
type PropsType = {
  timeout?: number;
  children: React.ReactElement | React.ReactElement[];
};
const DEFAULT_TIMEOUT = 1800000; // 30mins
const WARNING_TIMEOUT = 60000; // 1 min
const POLLING_FREQUENCY = 1000; // 1 sec
const Session = ({ children, timeout = DEFAULT_TIMEOUT }: PropsType) => {
  const { isAuthenticated, logout } = useAuth0Context();
  const [isOpen, setIsOpen] = useState(false);
  const [intervalStart, setIntervalStart] = useState(0);
  const [intervalTime, setIntervalTime] = useState(0);
  const idleTimerRef = useRef<IdleTimer | null>(null);
  const sessionTimeoutRef = useRef<any>(null);
  const intervalRef = useRef<any>(null);
  const monitorRef = useRef<any>(null);
  const logOut = useCallback(() => {
    idleTimerRef.current = null;
    removeItem("lastActive");
    clearTimeout(sessionTimeoutRef.current);
    clearInterval(intervalRef.current);
    clearInterval(monitorRef.current);
    logout(`${getAppOrigin()}/session-expired`);
  }, [logout]);
  const handleOnActive = () => {
    clearInterval(intervalRef.current);
    clearTimeout(sessionTimeoutRef.current);
  };
  const hasExpired = () => {
    const lastActive = getItem("lastActive");
    const currentTime = new Date().getTime();
    return (
      lastActive &&
      parseInt(lastActive, 10) + DEFAULT_TIMEOUT + WARNING_TIMEOUT < currentTime
    );
  };
  const handleAction = () => {
    if (hasExpired()) {
      logOut();
    }
    if (idleTimerRef && idleTimerRef.current) {
      saveItem("lastActive", idleTimerRef.current.getLastActiveTime());
    }
  };
  const handleOnIdle = () => {
    setIsOpen(true);
    setIntervalStart(new Date().getTime() + WARNING_TIMEOUT);
    setIntervalTime(new Date().getTime());
    intervalRef.current = setInterval(() => {
      setIntervalTime(new Date().getTime());
    }, POLLING_FREQUENCY);
    sessionTimeoutRef.current = setTimeout(logOut, WARNING_TIMEOUT);
  };
  const handleClose = () => {
    saveItem("lastActive", new Date().getTime());
    setIsOpen(false);
    setIntervalTime(0);
    clearInterval(intervalRef.current);
    clearTimeout(sessionTimeoutRef.current);
  };
  useEffect(() => {
    if (isAuthenticated()) {
      monitorRef.current = setInterval(() => {
        if (hasExpired()) {
          logOut();
        }
      }, POLLING_FREQUENCY);
    }
    return () => {
      clearInterval(monitorRef.current);
    };
  }, [isAuthenticated, logOut]);
  if (!isAuthenticated()) {
    return <>{children}</>;
  }
  const getTime = (millis: number) => {
    const min = Math.floor(millis / 60000);
    const sec = Math.round((millis % 60000) / 1000);
    return `${min}:${sec < 10 ? "0" : ""}${sec.toFixed(0)}`;
  };
  return (
    <IdleTimer
      ref={idleTimerRef}
      timeout={timeout}
      onActive={handleOnActive}
      onAction={handleAction}
      onIdle={handleOnIdle}
    >
      {children}
      <Modal
        isOpen={isOpen}
        renderHeader={false}
        title="Session Timeout"
        shouldOverlayCloseOnClick
        onClose={handleClose}
        noOffset
      >
        <GridWrapper>
          <div className={styles.ModalContent}>
            <img
              className={styles.ModalImage}
              src={NotFoundImage}
              alt="Timeout"
            />
            <H4 className={styles.ModalHeading}>Are you still there?</H4>
            <Text
              className={styles.ModalText}
            >{`For added security, you will be logged out in ${getTime(
              intervalStart - intervalTime
            )} due to inactivity. Press continue to stay logged in.`}</Text>
            <Button className={styles.ModalButton} onClick={handleClose}>
              Continue
            </Button>
          </div>
        </GridWrapper>
      </Modal>
    </IdleTimer>
  );
};
export default Session;
