import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import { getSvgWrapperDefaultValues } from "~/components/Icon/index.utils";

const logoWidth = 42;
const logoHeight = 41;
const AssistantHelp = ({
  className = "assistant-help-icon",
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
  >
    <circle cx="14.6341" cy="25.6341" r="14.6341" fill="#23453A" />
    <circle
      cx="29.8039"
      cy="12.1961"
      r="10.1961"
      fill="#FDEDBF"
      stroke="#FCF9F3"
      strokeWidth="2.41176"
    />
    <path
      d="M27.5417 9.64297C27.7152 9.14987 28.0576 8.73408 28.5082 8.46922C28.9589 8.20437 29.4887 8.10756 30.0039 8.19593C30.5191 8.2843 30.9864 8.55215 31.323 8.95204C31.6597 9.35193 31.8439 9.85805 31.8431 10.3808C31.8431 11.8564 29.6297 12.5942 29.6297 12.5942"
      stroke="#23453A"
      strokeWidth="1.60784"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.6888 15.5459H29.6969"
      stroke="#23453A"
      strokeWidth="1.60784"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7746 21.6127C11.7746 23.0557 12.9443 24.2254 14.3873 24.2254C15.8302 24.2254 17 23.0557 17 21.6127C17 20.1698 15.8303 19 14.3873 19C12.9443 19 11.7746 20.1698 11.7746 21.6127Z"
      fill="#F6B600"
    />
    <path
      d="M8 31.4541L15.332 31.4541C18.4659 31.4541 21 28.8122 21 25.545L13.668 25.545C10.5341 25.545 8 28.1957 8 31.4541Z"
      fill="#F6B600"
    />
  </svg>
);

export default AssistantHelp;
