import React from "react";
import { useHistory } from "react-router-dom";
import { format, add } from "date-fns";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import {
  Bold,
  H3,
  Text,
  TextExtraSmall,
  TextSmall,
} from "~/components/Typography";
import SubmitButton from "~/components/SubmitButton";
import PolicySummaryCard from "~/components/PolicySummaryCard";
import { formatCurrency } from "~/helpers/currency";

import styles from "./index.module.scss";

import { useMotorContext } from "~/contexts/MotorProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import MotorPlanDetail from "~/components/PlanDetail/MotorPlanDetail";
import Checkbox from "~/components/Checkbox";
import { NUMBER_STRING, POLICY_STATUS } from "~/helpers/constants";
import { getTypeOfCoverage } from "~/helpers/getCoverageData";
import pushWithParams from "~/helpers/pushWithParams";
import { localDateFromIso } from "~/helpers/dates";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import LoadingWrapper from "~/components/LoadingWrapper";
import useHasRequiredData from "~/helpers/useHasRequiredData";
import { InsuranceType, PolicyStatus } from "../../../types";
import HomePlanDetail from "~/components/PlanDetail/HomePlanDetail";
import { useUpdateSupport } from "~/helpers/useUpdateSupport";

const termsFieldName = "paymentAgreement";
const numberOfRecurrences = 12;
export const numberOfInstallmentsUpfront = 1;

const GET_POLICY = (insuranceType: string) => gql`
query($getGeneralPolicyInput: String!) {
  getGeneralPolicy(input: $getGeneralPolicyInput) {
    ${
      insuranceType === "motor"
        ? `motorProposal {
          vehicle {
            value
          }
        }`
        : `homeProposal {
          property {
            value
          }
          contents {
            name
            value
            identificationNumber
          }
          allRiskContents {
            name
            value
            identificationNumber
          }
          contentsValue
          allRiskCoverage
          isAllRiskWorldwide
        }`
    }
    coverageStart
    status
    isPaymentRecurring
    quote {
      typeOfCover
      basePremiumValue
      netPremiumValue
      taxValue
      totalValue
      coverage {
        peril
        coveredValue
        excessValue
        isVariableExcess
      }
      valueByNumberOfInstallments {
        twelve
      }
      expirationDate
    }
  }
}
`;

export default function Payment({
  nextPath,
  insuranceType,
  countryName,
}: {
  nextPath?: string;
  insuranceType: string;
  countryName: string;
}) {
  const history = useHistory();

  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const onboardCtx = useOnboardContext();
  const heapCtx = useHeapContext();

  const policyId =
    insuranceType === ("motor" as InsuranceType)
      ? motorCtx.policyInfo?.data?.id
      : homeCtx.policyId;

  const { data: policyData, loading: policyLoading } = useQuery(
    GET_POLICY(insuranceType),
    {
      variables: {
        getGeneralPolicyInput: policyId,
      },
      skip: !policyId,
      fetchPolicy: "no-cache",
    }
  );

  const [openModal, setOpenModal] = React.useState(false);

  useUpdateSupport({ isOpen: true });

  const onClickNext = () => {
    heapCtx.track(HeapEventName.ONBOARDING_REVIEW_COMPLETE, {
      "Policy ID": policyId,
    });

    if (nextPath) {
      pushWithParams(history, nextPath);
    }
  };

  useHasRequiredData({
    requiredData: [policyId],
    policyRequiredStatus: POLICY_STATUS.pendingPayment as PolicyStatus,
    policyStatus: policyData?.getGeneralPolicy?.status,
    redirectUrl: "/portal",
    skip: policyLoading || !policyData,
  });

  if (policyLoading || !policyData) {
    return <LoadingWrapper loading />;
  }

  const totalValue = policyData.getGeneralPolicy.quote.totalValue as number;

  const isRecurring = policyData.getGeneralPolicy.isPaymentRecurring;

  const valueToPay = isRecurring
    ? policyData.getGeneralPolicy.quote.valueByNumberOfInstallments?.[
        NUMBER_STRING[numberOfRecurrences]
      ]
    : totalValue;

  const firstRecurringInstallment =
    totalValue -
    valueToPay * (numberOfRecurrences - numberOfInstallmentsUpfront);

  const coverageStart = localDateFromIso(
    policyData.getGeneralPolicy.coverageStart as string
  );

  const typeOfCover = policyData.getGeneralPolicy.quote.typeOfCover as string;

  const { title: summaryTitle, subTitle: summarySubtitle } = getTypeOfCoverage(
    typeOfCover
  );

  const onClicked = () => {
    setOpenModal(true);
    window.history.pushState(null, "", location.href);
  };

  window.onpopstate = () => {
    if (openModal) {
      setOpenModal(false);
    }
  };

  return (
    <>
      <div className={styles.Payment}>
        <div className={styles.FirstBlock}>
          <Text>Before continuing with your payment...</Text>
          <H3 className={styles.Heading} component="h1">
            Let’s review some final details.
          </H3>
        </div>
        <div className={styles.SecondBlock}>
          <div className={styles.PolicySummaryCard}>
            <PolicySummaryCard
              title={summaryTitle}
              subTitle={summarySubtitle}
              installmentCount={isRecurring ? numberOfRecurrences : 1}
              installmentValue={valueToPay}
              variant="highlight"
              showTopHeader={false}
              onClickDetails={onClicked}
              showBorder={false}
              labelReviewDetail="Review Plan"
              showPrefix
            />
          </div>
        </div>
        <div className={styles.ThirdBlock}>
          <TextSmall className={styles.AutomaticallyCharged}>
            {isRecurring && (
              <>
                The payment schedule for the{" "}
                <Bold>{formatCurrency(totalValue, false, true)}</Bold> policy
                plan is starting{" "}
                <Bold>{format(coverageStart, "MMM dd, yyyy")}</Bold>. Two
                payments will be collected as follows. Firstly, the last
                month&lsquo;s will be collected up front today. Secondly, you
                will see the first month&lsquo;s payment as a hold on your card
                until the policy start date. The remaining payments will be
                collected over the following 10 months.
              </>
            )}
            {!isRecurring && (
              <>
                The payment for the{" "}
                <Bold>{formatCurrency(valueToPay, false, true)}</Bold> will
                automatically be charged to your debit or credit card.
              </>
            )}
          </TextSmall>
          <div className={styles.PaymentStartDate}>
            <div className={styles.PayNowRow}>
              <Text className={styles.PayNow} fontWeight="bold">
                Pay now
              </Text>
            </div>
            <div className={styles.PaymentDetailed}>
              <TextExtraSmall className={styles.CoverageStartDate}>
                {format(new Date(), "MMMM dd, yyyy")}
              </TextExtraSmall>
              <TextExtraSmall className={styles.NextPaymentAmount}>
                {formatCurrency(
                  isRecurring ? firstRecurringInstallment : valueToPay,
                  false,
                  true
                )}
              </TextExtraSmall>
            </div>
          </div>

          {isRecurring && (
            <div className={styles.PaymentStartDate}>
              <div className={styles.PaymentDetailed}>
                <Text className={styles.NextPaymentDateTop} fontWeight="bold">
                  On hold, to be paid on
                </Text>
              </div>
              <div className={styles.PaymentDetailed}>
                <TextExtraSmall className={styles.NextPaymentDate}>
                  {format(add(coverageStart, { months: 0 }), "MMMM dd, yyyy")}
                </TextExtraSmall>
                <TextExtraSmall className={styles.NextPaymentAmount}>
                  {formatCurrency(valueToPay, false, true)}
                </TextExtraSmall>
              </div>
            </div>
          )}

          {isRecurring && (
            <div className={styles.DetailedContainer}>
              <div className={styles.PaymentDetailed}>
                <Text className={styles.NextPaymentDateTop} fontWeight="bold">
                  Next payments
                </Text>
              </div>

              {Array(
                !!numberOfRecurrences &&
                  numberOfRecurrences - numberOfInstallmentsUpfront - 1
              )
                .fill(false)
                .map((_, idx) => (
                  <div key={idx} className={styles.PaymentDetailed}>
                    <TextExtraSmall className={styles.NextPaymentDate}>
                      {format(
                        add(coverageStart, { months: idx + 1 }),
                        "MMMM dd, yyyy"
                      )}
                    </TextExtraSmall>
                    <TextExtraSmall className={styles.NextPaymentAmount}>
                      {formatCurrency(valueToPay, false, true)}
                    </TextExtraSmall>
                  </div>
                ))}
            </div>
          )}
          <div className={styles.PaymentEndDate}>
            <Text className={styles.CoverageEnds} fontWeight="bold">
              Coverage ends
            </Text>
            <TextExtraSmall className={styles.CoverageEndsDate}>
              {format(
                add(coverageStart, { years: 1, days: -1 }),
                "MMMM dd, yyyy"
              )}
            </TextExtraSmall>
          </div>
        </div>
        <div className={styles.FourthBlock}>
          <div className={styles.Agreement}>
            <Checkbox
              id={termsFieldName}
              field={termsFieldName}
              label={"I agree with the following information"}
              className={styles.Checkbox}
              initialValue={onboardCtx.paymentAgreement}
              keepState
              labelClassName={styles.CheckboxLabel}
              onChange={() =>
                onboardCtx.setState({
                  paymentAgreement: !onboardCtx.paymentAgreement,
                })
              }
            />
            <TextExtraSmall className={styles.AgreementDeclaration}>
              I agree that the balance due on the above Policy must be paid by
              the stated date(s) and that Guardian General Insurance Ltd (GGIL)
              is free to cancel my policy failing this. In addition, any balance
              due for more than thirty (30) days may be subject to a credit fee
              calculated at $100. Also, in the event of a claim, GGIL reserves
              the right to request the remittance of all balances outstanding
              prior to settlement.
            </TextExtraSmall>
            <SubmitButton
              onClick={onClickNext}
              className={styles.ContinueBtn}
              disabled={!onboardCtx.paymentAgreement}
            >
              Continue
            </SubmitButton>
          </div>
        </div>
      </div>
      {insuranceType === "motor" && (
        <MotorPlanDetail
          isOpen={openModal}
          quote={policyData.getGeneralPolicy.quote}
          installmentCount={isRecurring ? numberOfRecurrences : 1}
          installmentValue={
            isRecurring
              ? policyData.getGeneralPolicy.quote?.valueByNumberOfInstallments
                  .twelve
              : policyData.getGeneralPolicy.quote?.totalValue
          }
          vehicleValue={policyData.getGeneralPolicy.motorProposal.vehicle.value}
          onCloseModal={() => setOpenModal(false)}
          showContinue={false}
        />
      )}
      {insuranceType === "home" && (
        <HomePlanDetail
          isOpen={openModal}
          quote={policyData.getGeneralPolicy.quote}
          allRisk={policyData.getGeneralPolicy.homeProposal.allRiskCoverage}
          allRiskType={
            policyData.getGeneralPolicy.homeProposal.isAllRiskWorldwide
              ? "worldwide"
              : "local"
          }
          allRiskValue={
            policyData.getGeneralPolicy.homeProposal.allRiskContents
              .map((insuredItem: { value: number }) => insuredItem.value)
              .reduce(
                (total: number, itemValue: number) => total + itemValue,
                0
              ) ?? 0
          }
          installments={isRecurring ? numberOfRecurrences : 1}
          additionalContents={
            !!policyData.getGeneralPolicy.homeProposal.contents
          }
          countryName={countryName}
          isRecurring={isRecurring}
          replacementValue={
            policyData.getGeneralPolicy.homeProposal.property.value
          }
          contentsValue={policyData.getGeneralPolicy.homeProposal.contentsValue}
          onCloseModal={() => setOpenModal(false)}
          showContinue={false}
        />
      )}
    </>
  );
}
