import { Quote, QuoteCoveredItem } from "~/contexts/MotorProvider/PolicyInfo";
import {
  getTypeOfCoverage,
  KeyContent,
  CONTENT_CARDS,
  parseCoveredItems,
} from "~/helpers/getCoverageData";
import { formatCurrency } from "~/helpers/currency";

type IParseDetailParams = {
  quote: Quote;
  keyContent: KeyContent;
  title: string;
  subTitle: string;
};

export type IParsedDetails = {
  title: string;
  subTitle: string;
  summary: string;
  totalValue: string;
  notCovered:
    | Array<{
        text: string;
        youPay: string;
        coveredAmount: string;
      }>
    | Array<{
        text: string;
      }>
    | Array<{
        text: string;
      }>;
  thirdLiability: string | undefined;
  sumInsured: string | undefined;
  covered: [];
};

const uiPerilSequence = [
  "third-party-property",
  "third-party-casualty",
  "third-party-casualties",
  "own-vehicle",
];

export function parseDetail(
  { quote, keyContent, title, subTitle }: IParseDetailParams,
  showPrefix: boolean
) {
  // Making the right sequence
  const covered = uiPerilSequence
    .map((peril) => quote.coverage.find((c) => c.peril === peril))
    .filter((e) => !!e) as QuoteCoveredItem[];

  return {
    title,
    subTitle,
    summary: CONTENT_CARDS[keyContent].summary,
    totalValue: formatCurrency(quote.totalValue, true, showPrefix),
    notCovered: CONTENT_CARDS[keyContent].notCovered,
    thirdLiability: formatCurrency(
      quote.coverage.find((item) => item.peril === "third-party-property")
        ?.coveredValue ?? -1,
      true,
      showPrefix
    ),
    sumInsured: formatCurrency(
      quote.coverage.find((item) => item.peril === "own-vehicle")
        ?.excessValue ?? -1,
      true,
      showPrefix
    ),
    covered: covered.map((item) =>
      parseCoveredItems(item, keyContent, showPrefix)
    ),
  };
}

export function parseQuoteDetails(quote: Quote, showPrefix = false) {
  const coverageDetails = getTypeOfCoverage(quote.typeOfCover);
  if (coverageDetails)
    return parseDetail({ quote, ...coverageDetails }, showPrefix);
  return {};
}
