import React from "react";
import moment from "moment";
import { Calendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";

import { enGB } from "date-fns/locale";
import "./styles.scss";
import { isSameDay } from "date-fns";

type PropsType = {
  date?: Date;
  setDate?: (date: Date | undefined) => void | Promise<void>;
  minimumDate?: Date;
  maximumDate?: Date;
};

const modifiersClassNames = {
  highlight: "-highlight",
};
const DatePicker = ({ date, setDate, ...rest }: PropsType) => {
  const [internalDate, setInternalDate] = React.useState<Date | undefined>(
    date ? moment(date).toDate() : undefined
  );

  const modifiers = {
    highlight: (d: Date) => {
      return internalDate ? isSameDay(d, internalDate) : false;
    },
  };

  return (
    <Calendar
      aria-hidden
      month={internalDate}
      modifiers={modifiers}
      modifiersClassNames={modifiersClassNames}
      onDayClick={(date) => {
        if (date === null) {
          setInternalDate(undefined);
          if (setDate) {
            setDate(undefined);
          }
        } else {
          setInternalDate(date);
          if (setDate) {
            setDate(date);
          }
        }
      }}
      locale={enGB}
      {...rest}
    />
  );
};

export default DatePicker;
