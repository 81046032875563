/* eslint-disable no-magic-numbers */
import React, { useEffect } from "react";
import { useHistory, matchPath } from "react-router-dom";
import { Text, H3 } from "~/components/Typography";
import SubmitButton from "~/components/SubmitButton";
import styles from "./index.module.scss";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import { OnboardingPathMatchType } from "../../../types";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMotorContext } from "~/contexts/MotorProvider";
import AlreadyHaveAccount from "~/components/AlreadyHaveAccount";

const AccountNote = ({ nextPath }: { nextPath?: string }) => {
  const history = useHistory();
  const assistantCtx = useAssistantContext();
  const heapCtx = useHeapContext();
  const motorCtx = useMotorContext();

  const pathMatch: OnboardingPathMatchType | null = matchPath(
    history.location.pathname,
    {
      path: "/:countryCode/:insuranceType/:step",
      exact: true,
      strict: false,
    }
  );

  useEffect(() => {
    if (assistantCtx.isOpen) {
      assistantCtx.updateAssistant({
        isOpen: false,
      });
    }
  }, [assistantCtx]);

  const handleClick = () => {
    heapCtx.track(HeapEventName.ONBOARDING_CREATE_ACCOUNT, {
      "Policy ID": motorCtx.policyInfo.data.id, //TODO check homeCtx too
    });

    if (nextPath) {
      pushWithParams(history, nextPath);
    }
  };

  const path = pathMatch
    ? `/${pathMatch.params.countryCode}/${pathMatch.params.insuranceType}/documents`
    : undefined;

  return (
    <>
      <Text>Great!</Text>
      <H3 className={styles.Subtitle} component="h1">
        Just a few steps to go until we can finalize your coverage.
      </H3>

      <H3 className={styles.Subtitle} component="h2">
        Let&apos;s create your account.
      </H3>
      <div className={styles.ButtonWrapper}>
        <SubmitButton onClick={handleClick} autoFocus>
          Let&apos;s do it!
        </SubmitButton>

        <AlreadyHaveAccount
          wrapperStyle={styles.LoginWrapper}
          textStyle={styles.LoginText}
          auth0Redirect={path}
        />
      </div>
    </>
  );
};

export default AccountNote;
