import React from "react";
import { Radio, RadioGroup } from "informed";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { IHomeDataInput, useHomeContext } from "~/contexts/HomeProvider";

import FCC from "~/assets/images/fcc.png";
import CC from "~/assets/images/cc.png";
import MC from "~/assets/images/mc.png";
import WC from "~/assets/images/wc.png";

import { H3, TextSmall } from "~/components/Typography";
import TileCard from "~/components/TileCard";

import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { NO_COVERAGE_URL } from "~/routes/index.constant";

const TYPE_BUILDING = [
  {
    title: "Full Concrete Construction",
    text: "Your home has concrete walls with a concrete roof.",
    img: FCC,
    type: "superior",
  },
  {
    title: "Concrete Construction",
    text:
      "Your home has concrete walls with a permaclad, shingled, or hard roof.",
    img: CC,
    type: "class-a",
  },
  {
    title: "Mixed Construction",
    text: "Your home has concrete and wood walls with a hard roof.",
    img: MC,
    type: "class-b",
  },
  {
    title: "Wooden Construction",
    text: "Your home is made 100% of wood with a hard roof.",
    img: WC,
    type: "class-c",
  },
] as Array<{
  title: string;
  text: string;
  img: string;
  type: IHomeDataInput["constructionType"];
}>;

export default function ConstructionType({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const fieldName = "constructionType";
  const { value } = useCustomFieldState(fieldName);

  const handleOnNext = (value: IHomeDataInput["constructionType"]) => {
    if (value === "class-b" || value === "class-c") {
      homeCtx.setState({ constructionType: value });
      history.push(`${NO_COVERAGE_URL}?message=home-property-construction`);
    } else if (nextPath) {
      homeCtx.setState({ constructionType: value });
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1" className={styles.heading}>
          Ok, help us get to know your home a bit better.
        </H3>
        <TextSmall>Which materials were used to build your home?</TextSmall>
      </legend>
      <div className={styles.ConstructionTypeContent}>
        <RadioGroup field={fieldName} keepState>
          {TYPE_BUILDING.map(({ text, title, img, type }, index) => (
            <TileCard
              key={title}
              title={title}
              text={text}
              className={styles.Tile}
              inputId={`item-${index}`}
              checked={value === title}
              inputElement={
                <Radio
                  id={`item-${index}`}
                  field={`${type}-${index}`}
                  value={type}
                  className={styles.HideRadio}
                  checked={value === type}
                  name={fieldName}
                  onClick={() => handleOnNext(type)}
                />
              }
              left={
                <img
                  src={img}
                  alt={`Icon ${title}`}
                  aria-hidden
                  className={styles.TitleIcon}
                />
              }
            />
          ))}
        </RadioGroup>
      </div>
    </fieldset>
  );
}
