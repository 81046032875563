import React from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup, useFormState } from "informed";
import { useMotorContext } from "~/contexts/MotorProvider";

import RadioSelector from "~/components/RadioSelector";
import { H3, TextSmall } from "~/components/Typography";

import styles from "./index.module.scss";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import pushWithParams from "~/helpers/pushWithParams";
import VehicleSearchDisplay from "~/components/VehicleSearchDisplay";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

export default function LeftRightHand({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const formState = useFormState();
  const heapCtx = useHeapContext();

  const {
    values: { isLeftSide },
  } = formState;

  const handleOnChange = async (value: FormValue) => {
    try {
      if (nextPath) {
        await motorCtx.vehicleInfo.setItem("isLeftSide", value);

        heapCtx.track(HeapEventName.MOTOR_LEFT_HAND_DRIVE, {
          "Left-hand Drive": value.toString(),
        });

        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useUpdateAssistant({
    text: <TextSmall>Right-hand is the most common in Barbados.</TextSmall>,
  });

  return (
    <>
      <fieldset>
        <legend>
          <H3 component="h1">{`Is your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} left-hand or right-hand drive?`}</H3>
        </legend>
        <VehicleSearchDisplay display={["year", "make", "model"]} />
        <div className={styles.LeftRightHandContent}>
          <RadioGroup field="isLeftSide" keepState>
            <RadioSelector
              key="left"
              field="isLeftSide"
              value="yes"
              checked={isLeftSide === "yes"}
              onChange={(value) => handleOnChange(value)}
            >
              Left
            </RadioSelector>
            <RadioSelector
              key="right"
              field="isLeftSide"
              value="no"
              checked={isLeftSide === "no"}
              onChange={(value) => handleOnChange(value)}
            >
              Right
            </RadioSelector>
          </RadioGroup>
        </div>
      </fieldset>
    </>
  );
}
