import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import { H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./style.module.scss";

type Props = {
  nextPath?: string;
};

export default function RefusedInsurance({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Driver's History",
    });
  });

  const handleNextClick = async (hasBeenRefused: boolean) => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          refusedInsurance: hasBeenRefused,
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <Text className={styles.HideOnMobile}>Thanks!</Text>

          <H3>
            Have you, or anyone driving, been refused insurance or have had
            special conditions imposed?
          </H3>

          <RadioBinary
            handleClick={handleNextClick}
            field="refusedInsurance"
          ></RadioBinary>
        </>
      )}
    </>
  );
}
