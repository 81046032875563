import React from "react";

import { SvgProps } from "~/components/Icon/index.types";
import {
  getSvgWrapperDefaultValues,
  DEFAULT_FILL_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from "~/components/Icon/index.utils";

const logoWidth = 30;
const logoHeight = 30;

const Close = ({
  className = "close-icon",
  fill = DEFAULT_FILL_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  width = logoWidth,
  height = logoHeight,
}: SvgProps) => (
  <svg
    {...getSvgWrapperDefaultValues({ className, width, height })}
    viewBox={`0 0 ${logoWidth} ${logoHeight}`}
    aria-label="close"
    role="img"
  >
    <circle cx="15" cy="15" r="15" fill={backgroundColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8738 11.757C20.2643 11.3665 20.2643 10.7333 19.8738 10.3428C19.4832 9.95226 18.8501 9.95226 18.4596 10.3428L15 13.8023L11.5405 10.3428C11.15 9.95226 10.5168 9.95226 10.1263 10.3428C9.73574 10.7333 9.73574 11.3665 10.1263 11.757L13.5858 15.2165L10.1263 18.6761C9.73574 19.0666 9.73574 19.6998 10.1263 20.0903C10.5168 20.4808 11.15 20.4808 11.5405 20.0903L15 16.6307L18.4596 20.0903C18.8501 20.4808 19.4832 20.4808 19.8738 20.0903C20.2643 19.6998 20.2643 19.0666 19.8738 18.6761L16.4142 15.2165L19.8738 11.757Z"
      fill={fill}
    />
  </svg>
);

export default Close;
