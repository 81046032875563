import React, { useCallback, useLayoutEffect, useState } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

import { H3, TextSmall } from "~/components/Typography";
import Icon from "~/components/Icon";

import { MotorIllustration } from "./index.motorIllustration";
import { HomeIllustration } from "./index.homeIllustration";

type Props = {
  isDone?: boolean;
  isLoading: boolean;
  onFinished?: () => void;
  insuranceType?: string;
};

const textList = [
  {
    text: "Reviewing data...",
    visible: false,
  },
  {
    text: "Building package options...",
    visible: false,
  },
  {
    text: "We think you're really gonna like this...",
    visible: false,
  },
];

// time in milliseconds
const ANIMATION_INTERVAL = 1000;

// time in seconds
const SHOULD_WAIT = 8;
let ANIMATION_SHOULD_WAIT = SHOULD_WAIT;

export default function MotorQuoteLoading({
  isDone = false,
  isLoading,
  onFinished,
  insuranceType = "",
}: Props) {
  const [backAnimation, setBackAnimation] = useState(false);
  const [processListText, setProcessListText] = useState(textList);

  const isFinished = isDone && ANIMATION_SHOULD_WAIT <= 0;

  const runAnimation = useCallback(() => {
    const nonAnimated = processListText.find(
      (elem) => elem.visible === backAnimation
    );
    if (!nonAnimated) {
      setBackAnimation(!backAnimation);
    } else {
      const nonAnimatedIndex = processListText.findIndex(
        (elem) => elem.text === nonAnimated.text
      );

      const newArray = processListText.map((elem, index) => {
        if (index === nonAnimatedIndex)
          return { ...elem, visible: !backAnimation };
        return elem;
      });
      setProcessListText(newArray);
    }
  }, [backAnimation, processListText]);

  const handleNextPath = useCallback(() => {
    setTimeout(() => {
      if (typeof onFinished === "function") onFinished();
    }, ANIMATION_INTERVAL);
  }, [onFinished]);

  useLayoutEffect(() => {
    if (!isLoading) return;
    document.body.classList.add("no-overflow");
    const timeOut = setTimeout(() => {
      if (isFinished) {
        document.body.classList.remove("no-overflow");
        handleNextPath();
        clearTimeout(timeOut);
        ANIMATION_SHOULD_WAIT = SHOULD_WAIT;
        return;
      }
      ANIMATION_SHOULD_WAIT = ANIMATION_SHOULD_WAIT - 1;
      runAnimation();
    }, ANIMATION_INTERVAL);
    return () => clearTimeout(timeOut);
  }, [isFinished, runAnimation, handleNextPath, isLoading]);

  if (!isDone && !isLoading) return null;
  if (isDone && !isLoading) return <div className={styles.PostAnimation} />;

  return (
    <div
      className={classnames(styles.MotorQuoteLoadingWrapper, {
        [styles.IsDone]: isFinished,
      })}
      role="group"
      aria-label="we are building your plan"
    >
      <div
        className={classnames(styles.Content, {
          [styles.IsDone]: isFinished,
        })}
      >
        <div className={styles.AlmiIconWrapper}>
          <Icon name="AlmiIcon" fill="#F6B600" width={30} height={30} />
        </div>
        <div className={styles.IllustrationsWrapper}>
          {insuranceType === "motor" ? (
            <MotorIllustration className={styles.Illustration} />
          ) : (
            <HomeIllustration className={styles.Illustration} />
          )}
        </div>
        <div className={styles.TextBox}>
          <H3 className={styles.Title} component="h1">
            We’re building your plan!
          </H3>
          <ul className={styles.TextList}>
            {processListText.map(({ text, visible }, index) => (
              <li
                key={index}
                className={classnames(styles.TextListItemWrapper, {
                  [styles.Visible]: visible,
                })}
              >
                <Icon
                  name="CircleCheck"
                  backgroundColor="transparent"
                  fill="#31AD63"
                  className={styles.CheckIcon}
                />
                <TextSmall className={styles.TextListItem}>{text}</TextSmall>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
