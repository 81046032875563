import React from "react";
import { format } from "date-fns";
import classnames from "classnames";
import BellImg from "../../assets/images/bell.png";
import { Bold, Text, TextSmall } from "../Typography";
import { PolicyObject, PortalNotificationDataType } from "../../types";
import { formatCurrency } from "~/helpers/currency";
import styles from "./index.module.scss";
import Button from "../Button";
import { PAYMENT_STATUS, POLICY_STATUS } from "~/helpers/constants";
import { useHistory } from "react-router-dom";
import { localDateFromIso } from "~/helpers/dates";
import { getPortalChecklistRoute } from "~/helpers/flow";

type PropType = {
  policy: {
    externalId: string;
    policyObject: string;
  };
  notificationData: PortalNotificationDataType;
};

const PortalNotificationCard = ({ policy, notificationData }: PropType) => {
  const { insuredObject, amount, dueDate, status } = notificationData;

  const history = useHistory();

  const getBgdColor = () => {
    switch (status) {
      case PAYMENT_STATUS.approved:
        return "yellow";
      case POLICY_STATUS.pendingSecondaryDocuments:
      case PAYMENT_STATUS.registered:
      case PAYMENT_STATUS.failedRecurrence:
        return "gold";
      case PAYMENT_STATUS.cancelled:
      case PAYMENT_STATUS.cancelledPolicy:
      case PAYMENT_STATUS.paymentCancelledPolicyPendingNewPayment:
      case PAYMENT_STATUS.paymentCancelledPolicySoonToBeCancelled:
        return "salmon";
      default:
        return "yellow";
    }
  };
  const getTitle = () => {
    switch (status) {
      case PAYMENT_STATUS.approved:
        return (
          <>
            Upcoming payment scheduled for policy{" "}
            <span>
              <Bold>{insuredObject}</Bold>
            </span>
            .
          </>
        );
      case POLICY_STATUS.pendingPayment:
      case PAYMENT_STATUS.registered:
      case PAYMENT_STATUS.failedRecurrence:
        return (
          <>
            Payment required for policy{" "}
            <span>
              <Bold>{insuredObject}</Bold>.
            </span>
          </>
        );
      case PAYMENT_STATUS.cancelled:
      case PAYMENT_STATUS.cancelledPolicy:
      case PAYMENT_STATUS.paymentCancelledPolicyPendingNewPayment:
      case PAYMENT_STATUS.paymentCancelledPolicySoonToBeCancelled:
        return (
          <>
            Notice for Policy <Bold>{insuredObject}</Bold>.
          </>
        );
      case POLICY_STATUS.pendingSecondaryDocuments:
        return (
          <>
            Information required for Policy <Bold>{insuredObject}</Bold>.
          </>
        );
      default:
        return <></>;
    }
  };
  const getDescription = () => {
    switch (status) {
      case PAYMENT_STATUS.registered:
        return (
          <>
            Complete your policy payment before{" "}
            <Bold>
              {dueDate
                ? format(localDateFromIso(dueDate), "MMM d, yyyy")
                : "N/A"}
            </Bold>{" "}
            to activate this policy.
          </>
        );
      case PAYMENT_STATUS.approved:
        return (
          <>
            <Bold>{formatCurrency(amount as number, false)}</Bold>{" "}
            <span>
              due on
              <Bold>
                {" "}
                {dueDate ? format(localDateFromIso(dueDate), "MMM d") : "N/A"}
              </Bold>
            </span>
          </>
        );
      case PAYMENT_STATUS.failedRecurrence:
        return (
          <>
            <Bold>We’re unable to process your payment.</Bold> Make sure your
            payment info is up-to-date.
          </>
        );
      case PAYMENT_STATUS.paymentCancelledPolicyPendingNewPayment:
      case PAYMENT_STATUS.paymentCancelledPolicySoonToBeCancelled:
        return (
          <>
            Your motor insurance is due to be cancelled on{" "}
            <Bold>
              {dueDate
                ? format(localDateFromIso(dueDate), "MMM d, yyyy")
                : "N/A"}
            </Bold>
            .
          </>
        );
      case PAYMENT_STATUS.cancelled:
      case PAYMENT_STATUS.cancelledPolicy:
        return (
          <>
            <Bold>This policy has been cancelled.</Bold> Contact us if you have
            any questions.
          </>
        );
      case POLICY_STATUS.pendingSecondaryDocuments:
        return (
          <>
            Complete your onboarding by{" "}
            <Bold>
              {dueDate
                ? format(localDateFromIso(dueDate), "MMM d, yyyy")
                : "N/A"}
            </Bold>{" "}
            to activate this policy.
          </>
        );
      default:
        return <></>;
    }
  };
  const getBtnLabel = () => {
    switch (status) {
      case PAYMENT_STATUS.failedRecurrence:
      case PAYMENT_STATUS.paymentCancelledPolicyPendingNewPayment:
        return "See Policy Details";
      case PAYMENT_STATUS.cancelled:
      case PAYMENT_STATUS.cancelledPolicy:
      case PAYMENT_STATUS.paymentCancelledPolicySoonToBeCancelled:
        return "Contact Almi";
      case PAYMENT_STATUS.registered:
      case POLICY_STATUS.pendingSecondaryDocuments:
        return "Complete policy onboarding";
      default:
        return "";
    }
  };

  const getBtnOnClick = () => {
    switch (status) {
      case PAYMENT_STATUS.failedRecurrence:
      case PAYMENT_STATUS.registered:
      case PAYMENT_STATUS.paymentCancelledPolicyPendingNewPayment:
        return () =>
          history.push(`/portal/policy/${policy.externalId}/update-payment`);
      case PAYMENT_STATUS.paymentCancelledPolicySoonToBeCancelled:
        // TODO: use route screen for contact
        return () => history.push(`/portal`);
      case POLICY_STATUS.pendingSecondaryDocuments:
        return () =>
          history.push(
            getPortalChecklistRoute(
              policy.policyObject as PolicyObject,
              policy.externalId,
              "redirect"
            )
          );
      default:
        return () => {};
    }
  };

  if (
    status !== PAYMENT_STATUS.approved &&
    status !== PAYMENT_STATUS.failedRecurrence &&
    status !== PAYMENT_STATUS.cancelled &&
    status !== PAYMENT_STATUS.cancelledPolicy &&
    status !== PAYMENT_STATUS.paymentCancelledPolicyPendingNewPayment &&
    status !== PAYMENT_STATUS.paymentCancelledPolicySoonToBeCancelled &&
    status !== PAYMENT_STATUS.registered &&
    status !== POLICY_STATUS.pendingSecondaryDocuments
  ) {
    return null;
  }

  return (
    <div
      className={classnames(styles.Container, styles[getBgdColor()])}
      role="group"
      aria-label="notification"
    >
      <div className={styles.ImageContainer}>
        <img src={BellImg} alt="bell" className={styles.Image} aria-hidden />
      </div>
      <div className={styles.TextContainer}>
        <TextSmall className={styles.PaymentFor}>{getTitle()}</TextSmall>
        <Text className={styles.Description}>{getDescription()}</Text>
      </div>
      {status !== PAYMENT_STATUS.approved && (
        <Button
          variant="senary"
          size="xsmall"
          className={styles.Button}
          onClick={getBtnOnClick()}
        >
          {getBtnLabel()}
        </Button>
      )}
    </div>
  );
};

export default PortalNotificationCard;
