import { FormValue } from "informed";
import { InputType } from "./index.types";

export function formatFree(input: FormValue) {
  return Array.from({ length: input.toString().length }).map(() => /./);
}

export function formatDate(input: FormValue, inputElement: HTMLInputElement) {
  // The value of `input` may have been modified by this
  // point, so we'll do comparisons with the dom value
  const cursorNotAtEnd =
    inputElement.selectionStart !== null &&
    inputElement.selectionStart < inputElement.value.length;

  // If the caret is not at the end of the text, don't do any masking.
  // This is to ensure deleting and typing works naturally, without
  // characters jumping between separators etc.
  if (cursorNotAtEnd) {
    // Note: the expression /[\d/]/ was tried here but typing a dissallowed
    // character causes the caret to jump to the end of the text, so it's
    // better to allow free input
    return formatFree(input);
  }
  return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
}

export function formatYear() {
  const year = new Date().getFullYear();
  return Array.from({ length: year.toString().length }).map(() => /\d/);
}

export function formatNumber(size = 1) {
  return Array.from({ length: size }).map(() => /\d/);
}

type IFormatFn = {
  type?: InputType;
  size?: number;
  inputId: string;
};

export function formatFn({ type, size, inputId }: IFormatFn) {
  const inputElement = document.getElementById(inputId) as HTMLInputElement;
  if (inputElement && type === "number") {
    inputElement.setAttribute("type", "number");
  }
  if (inputElement && type === "phone") {
    inputElement.setAttribute("type", "tel");
  }

  return (input: FormValue) => {
    if (typeof input === "string" && type === "date" && inputElement) {
      return formatDate(input, inputElement);
    }
    if (typeof input === "string" && type === "year") {
      return formatYear();
    }
    if (typeof input === "string" && type === "number") {
      return formatNumber(size);
    }
    return formatFree(input);
  };
}

export function maskedCurrency(value: string | number) {
  return value
    ?.toString()
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function maskedPhone(value: string | number) {
  const two = 2;
  const stripped = value?.toString().replace(/[^\d]/g, "");
  const matched = stripped.match(/(\d{0,3})(\d{0,12})/);
  let masked = "";
  if (matched) {
    if (!matched[two]) {
      masked = matched[1];
    } else {
      masked = `${matched[1]}-${matched[two]}`;
    }
  }
  return masked;
}
