const getStatusColor = (status: string) => {
  switch (status) {
    case "open":
    case "active":
    case "review": {
      return "green";
    }

    case "expired": {
      return "red";
    }

    case "closed":
    case "cancelled": {
      return "grey";
    }

    default: {
      return "orange";
    }
  }
};

export default getStatusColor;
